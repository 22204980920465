import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { IArea, IWarehouse } from '../../models';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { AreaService } from '../../Shared/Services/area.service';
import { WarehouseService } from '../../Shared/Services/warehouse.service';
import { AssignareaComponent } from './assignarea/assignarea.component';
import { WarehouseDeleteComponent } from './warehouse-delete/warehouse-delete.component';

@Component({
  selector: 'ngx-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {

  warehouseForm: FormGroup;
  submitted = false;
  warehouseId = 0;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  warehouseList = [];
  copyWarehouseList = [];
  CityList = [];
  pageRole: any;
  saveBtnHideForRole = true;

  @ViewChild('warehouseitem', { static: false }) accordion;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  constructor(private fb: FormBuilder,
    private warehouseService: WarehouseService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private areaService: AreaService,
    
    ) {
    this.warehouseForm = this.fb.group({
      wName: ['', [Validators.required]],
      contactPerson: ['', [Validators.required]],
      contactNumber: ['', [Validators.required]],
      location: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city:[0, [Validators.required]],
      isActive: [false]

    });
  }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);

    this.pageRole = role.page.find(m => m.page === 'readymatestockin');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }
    this.getWarehouseList();
    this.getCityList()
  }

  get f() {
    return this.warehouseForm.controls;
  }

  getWarehouseList() {
    // const url = `${environment.API_URL}/api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
    const url = `${environment.API_URL}/api/Warehouse/GetAdminWarehouseList?Pagesize=0&PageNo=0`;
    this.warehouseService
      .getWarehouseList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.warehouseList = response.data.warehouses;
          this.copyWarehouseList = response.data.warehouses;
          this.config.totalItems = this.warehouseList.length;
        }
      });
  }

  getCityList() {
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<IArea[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.CityList = response.data;
        }
      });
  }

  // for set value to w.r.s to feild
  editWarehouse(warehouse) {
    this.accordion.open();
    this.warehouseId = warehouse.id;
    this.warehouseForm.controls.wName.setValue(warehouse.warehouseName);
    this.warehouseForm.controls.location.setValue(warehouse.location);
    this.warehouseForm.controls.address.setValue(warehouse.address);
    this.warehouseForm.controls.contactPerson.setValue(warehouse.contactPerson);
    this.warehouseForm.controls.contactNumber.setValue(warehouse.contactno);
    this.warehouseForm.controls.city.setValue(warehouse.cityId);
    this.warehouseForm.controls.isActive.setValue(warehouse.isActive);

  }

  saveWarehouse() {
    this.submitted = true;
    if (this.warehouseForm.valid) {
      const data: IWarehouse = {
        Id: this.warehouseId,
        WarehouseName: this.warehouseForm.value.wName,
        Location: this.warehouseForm.value.location,
        Address: this.warehouseForm.value.address,
        ContactPerson: this.warehouseForm.value.contactPerson,
        Contactno: this.warehouseForm.value.contactNumber,
        cityId: Number(this.warehouseForm.value.city),
        isActive:this.warehouseForm.value.isActive
      };

      let api = '';
      if (this.warehouseId === 0) {
        api = `${environment.API_URL}/api/Warehouse/AddWarehouse`;
      } else {
        api = `${environment.API_URL}/api/Warehouse/UpdateWarehouse`;
      }
      this.warehouseService.modifyWarehouse(api, data).subscribe(
        (response) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.warehouseId === 0) {
              this.showToast('top-right', 'success', 'Warehouse Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Warehouse updated Successfully');
            }
            this.warehouseId = 0;
            this.clearForm();
            this.submitted = false;
            this.accordion.close();
            this.getWarehouseList();
          } else if(response.status === 400){
            this.showToast('top-right', 'danger',response.statusMessage);

          }
          else{
            
          }
        });
    }
  }

  clearForm() {
    this.warehouseForm.reset();
    this.submitted = false;
    this.warehouseId = 0;
    this.warehouseForm.controls.isActive.setValue(false);

  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.warehouseList = this.copyWarehouseList.filter(item => {
        return String(item.warehouseName)
          .toLowerCase()
          .startsWith(str.toLowerCase().trim());
      });
    } else {
      this.warehouseList = this.copyWarehouseList;
    }
  }

  openAssignAreaDialog(wData) {
    this.dialogService
      .open(AssignareaComponent, {
        context: {
          title: '',
          data: wData
        }
      })
      .onClose.subscribe(() => {
      });
  }


  openWarehouseDelete(wData) {
    this.dialogService
      .open(WarehouseDeleteComponent, {
        context: {
          title: '',
          data: wData
        }
      })
      .onClose.subscribe(() => {
        this.getWarehouseList();
      });
  }

  isNumberKey(evt) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  showToast(position, status, message) {
    this.toastrService.show(status || 'Success', `${message}`, {
      position,
      status
    });
  }

}
