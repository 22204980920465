import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { CityService } from '../../Shared/Services/city.service';
import { NbDialogService } from '@nebular/theme';
import { RecipePopUpComponent } from './recipe-pop-up/recipe-pop-up.component';
import { IOrderDetails } from '../../models/order.model';

@Component({
  selector: 'ngx-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss']
})
export class RecipeComponent implements OnInit {

  recpiesList: any[];
  copyRecpies: any[];

  pageSize = 10;
  pageNumber = 1;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private cityService: CityService,
    private dialogService: NbDialogService,


  ) { }

  ngOnInit() {
    this.getRecipeList();
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
    this.getRecipeList();
  }
  // searching
  searchingFilter(str: string) {
    str = str.trim();
    if (str !== ' ')  {
      const url = `${environment.API_URL}/api/Product/MyRecipesforAdminSearch?Serchstr=${str}&PageSize=0&PageNumber=0`;
      this.cityService.getCityList(url).subscribe
        ((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.recpiesList = response.data.recipes;
            this.copyRecpies = response.data.recipes;
          } else {
            this.getRecipeList();          }
        });

    }
  }

  // get city list
  getRecipeList() {
    const url = `${environment.API_URL}/api/Product/MyRecipesforAdmin?PageSize=${this.pageSize}&PageNumber=${this.config.currentPage}`;
    this.cityService.getCityList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.recpiesList = response.data.recipes;
          this.copyRecpies = response.data.recipes;
          this.config.totalItems = response.data.count;
        }
      });
  }

  openDetailsRecipe(product: IOrderDetails) {
    this.dialogService.open(RecipePopUpComponent, {
      context: {
        title: '',
        data: product,
      },
    });
  }

}
