import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IGrain, ICity } from '../../models';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { WarehouseService } from '../../Shared/Services/warehouse.service';
@Component({
	selector: 'ngx-warehouse-stock-detail',
	templateUrl: './warehouse-stock-detail.component.html',
	styleUrls: ['./warehouse-stock-detail.component.scss']
})
export class WarehouseStockDetailComponent implements OnInit {
	submitted = false;
	cityList = [];
	stockListGP: IGrain[];
	warehouseList = [];
	whId = 0;
	inTransitList: ICity[];
	pageRole: any;
	roleId: number;
	saveBtnHideForRole = true;

	constructor(
		private whService: WarehouseService
	) { }
	ngOnInit() {
		const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		this.pageRole = role.page;
		if (this.pageRole.isWrite === false) {
			this.saveBtnHideForRole = true;
		}
		this.getCityList();
	}
	// Get city list
	getCityList() {
		const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
		this.whService
			.getWarehouseList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.cityList = response.data;
				} else if (response.status === HttpStatusCode.NO_CONTENT) {
					this.cityList = [];
				}
			});
		this.warehouseList = [];
		
	}
	// Get Warehouse list 
	getWarehouseList(cityID) {
		// 	const url = `${environment.API_URL}/api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
		const url = `${environment.API_URL}/api/Warehouse/GetWarehouseByCityIdList?cityId=${cityID}`;
		this.whService.getWarehouseList(url).subscribe(
			(response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.warehouseList = response.data.warehouses;
				} else {
					this.warehouseList = [];
				}
			}
		);
		this.stockListGP = [];
		this.inTransitList = [];
	}
	onWHChange(WarehouseId) {
		this.whId = WarehouseId
		this.getStockList();
		this.getTransitStock();

	}
	// get Grain warehouse Stock list
	getStockList() {
		const url = `${environment.API_URL}/api/Stock/Warehouse/StockList?WHId=${this.whId}`;
		this.stockListGP = [];
		this.whService
			.getWarehouseList(url)
			.subscribe((response: ResponseModel<IGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.stockListGP = response.data;
					// this.getTransitStock(event);
				} if (response.status === 204) {
					this.stockListGP = [];

				}
			});
	}
	// get In Transit warehouse stock list
	getTransitStock() {
		this.inTransitList = [];
		const url = `${environment.API_URL}/api/Stock/IntransistWHStockList?WHId=${this.whId}`;
		this.whService
			.getWarehouseList(url)
			.subscribe((response: ResponseModel<ICity[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.inTransitList = response.data;
				} if (response.status === 204) {
					this.inTransitList = [];
				}
			});
	}
	getclass(ele) {
		const min = (ele.capacityInKg * ele.minPercentage) / 100;
		const max = ele.capacityInKg;
		return ele.stock <= min ? 'min-limit' : ele.stock > max ? 'max-limit' : '';
	}
	downloadInvoice() {
		// const url = `${environment.API_URL}/api/Report/GPartnerStock?GPartnerId=${this.whId}`;
		const url = `${environment.API_URL}/api/Report/WarehouseStockReport?warehouseId=${this.whId}`;
		this.whService
			.getWarehouseList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					window.open(response.data, '_blank');
				}
			});
	}
}
