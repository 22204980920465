import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { GpartnerService } from '../../../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-reset-device-dp',
  templateUrl: './reset-device-dp.component.html',
  styleUrls: ['./reset-device-dp.component.scss']
})
export class ResetDeviceDpComponent implements OnInit {
  title: string;
  dbid: any;
  hideSasveBtn = false;

  constructor(
    private dialogRef: NbDialogRef<any>,
    private gpartnerService: GpartnerService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }

  resetDeive() {
    this.hideSasveBtn = true;
    const url = `${environment.API_URL}/api/DeliveryBoy/ResetDevice?DeliveryboyId=${this.dbid}`;
    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.resetDeiveNotification();
          this.hideSasveBtn = false;
          this.showToast('top-right', 'success');
          this.close();
        }
      }
    );
  }
  resetDeiveNotification() {
    this.hideSasveBtn = true;
    const url = `${environment.API_URL}/api/DeliveryBoy/ResetDeviceNotification?DeliverboyId=${this.dbid}`;
    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          // this.hideSasveBtn = false;
          // this.showToast('top-right', 'success');
          // this.close();
        }
      }
    );
  }

  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `update Successfully`,
      { position, status });
  }


}
