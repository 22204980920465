import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { CommentService } from '../../../Shared/Services/comment.service';

@Component({
  selector: 'ngx-delete-comments',
  templateUrl: './delete-comments.component.html',
  styleUrls: ['./delete-comments.component.scss']
})
export class DeleteCommentsComponent implements OnInit {
  title: '';
  data: any;

  submitted = false;

  commentForm: FormGroup;
  isDisabledSaveBtn = false;


  constructor(
    private commentservice: CommentService,
    private fb: FormBuilder,
    private dialogRef: NbDialogRef<any>,
    private commentService: CommentService,
    private toastrService: NbToastrService
  ) {

  }
  ngOnInit() {
    if (this.data.IsAdminApprove) {
      this.commentForm = this.fb.group({
        reply: [''],
        isApprove: [true]
      });
    } else {
      this.commentForm = this.fb.group({
        reply: [''],
        isApprove: ['']
      });
    }
  }
  get f() {
    return this.commentForm.controls;
  }
  close() {
    this.dialogRef.close();
  }
  saveComment() {
    this.isDisabledSaveBtn = true;
    this.submitted = true;
    const data = {
      id: this.data.id,
      blogId: this.data.blogId,
      isAdminApprove: this.commentForm.value.isApprove,
      comment: this.commentForm.value.reply ? this.commentForm.value.reply : '',
      parentId: this.data.id
    };
    let apiurl = '';
    apiurl = `${environment.API_URL}/api/Comment/UpdateComment`;
    this.commentService.updateComment(apiurl, data).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.commentservice.commentapprove.next(this.data.IsAdminApprove);
          this.showToast('top-right', 'success', 'updated Successfully');
          this.isDisabledSaveBtn = false;
          this.commentForm.reset();
          this.dialogRef.close();
          this.submitted = false;
        } else {
          this.showToast('top-right', 'danger', response.statusMessage);
          this.isDisabledSaveBtn = false;
          this.submitted = false;

        }
      },
        () => {
          this.isDisabledSaveBtn = false;
          this.submitted = false;
        });
  }
  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}
