import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { DonationService } from '../../Shared/Services/donation.service';

@Component({
  selector: 'ngx-canteen',
  templateUrl: './canteen.component.html',
  styleUrls: ['./canteen.component.scss']
})
export class CanteenComponent implements OnInit {
  allCanteenList = [];
  copyAllCanteenList = [];
  totalCount: number;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private donationService: DonationService

  ) { }

  ngOnInit() {
    this.getAllCanteenList();
  }

  // get all canteen list for admin
  getAllCanteenList() {
    const url = `${environment.API_URL}/api/Donation/AllCanteen`;
    this.donationService
      .getList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.allCanteenList = response.data.recipients;
          this.copyAllCanteenList = response.data.recipients;
          this.config.totalItems = response.data.count;
          this.totalCount = response.data.count;
        }
      });
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }
  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.allCanteenList = this.copyAllCanteenList.filter(item => {
        const name = String(item.name).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr));
      });
    } else {
      this.allCanteenList = this.copyAllCanteenList;
    }
  }

}
