import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { environment } from '../../../../environments/environment';
import { AreaService } from '../../../Shared/Services/area.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GpartnerService } from '../../../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-gp-area-assing',
  templateUrl: './gp-area-assing.component.html',
  styleUrls: ['./gp-area-assing.component.scss']
})
export class GpAreaAssingComponent implements OnInit {
  title: string;
  gpid: any;
  cityAreaFilterForm: FormGroup;
  areaArrey = [];

  asssingAreaStatus;
  disableAfterSave = false;
  submitted = false;

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;
  cityList = [];
  areaListFormCity: any[] = [];
  areaListFormGpId: any[] = [];


  areaList = [];

  constructor(

    private dialogRef: NbDialogRef<any>,
    private areaService: AreaService,
    private toastrService: NbToastrService,
    private fb: FormBuilder,
    private gpService: GpartnerService

  ) {
    this.cityAreaFilterForm = this.fb.group({
      city: ['', [Validators.required]],
      area: ['', [Validators.required]],

    });
  }

  ngOnInit() {
    this.getAreaList();
    this.getCityList();
    this.getAreaListFormGPId();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    // this.pageRole = role.page;
    this.pageRole = role.page.find(m => m.page === 'Delivery boy');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }
  }

  get f() {
    return this.cityAreaFilterForm.controls;
  }

  validation() {
    this.submitted = true;
  }
  close() {
    this.dialogRef.close();
  }

  getAreaList() {
    const url = `${environment.API_URL}/api/GPartner/GetGpartnerArea?Id=${this.gpid}`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaList = response.data.assiggedArea;
          this.areaArrey = [];
          this.areaList.forEach((item) => {
            if (item.isAvailable === true) {
              this.areaArrey.push(+item.id);
            }
          });
        }
      }
      );
  }
  toggle(checked: boolean, area) {
    area.isAvailable = checked;
  }
  saveArea() {
    this.disableAfterSave = true;
    const tempArray = [];
    this.areaListFormCity.forEach((item) => {
      if (item.isAvailable) {
        const obj = {
          areaId: item.id,
          areaName: item.areaNameEng,
          assigned: true
        };
        tempArray.push(obj);
      }
    });
    const data = {
      assiggedArea: tempArray,
      gpartnerId: this.gpid
    };
    const url = `${environment.API_URL}/api/GPartner/UpdateGpartnerArea`;
    this.areaService.modifyArea(url, data).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Area Assining Successfully');
          this.disableAfterSave = false;
        } else {
          this.showToast('top-right', 'danger', 'Not Assing Area');
          this.disableAfterSave = false;

        }
      }
      );
  }

  // get city list
  getCityList() {
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.cityList = response.data;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.cityList = [];
        }
      });
  }
  // get area from city list
  getAreaListByCity(event) {
    const url = `${environment.API_URL
      }/api/Area/GetAreaListByCityId?CityId=${+event}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaListFormCity = response.data;
          this.areaMapping();
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.areaListFormCity = [];
        }
      });
  }
  // get area list from GPartner Id
  getAreaListFormGPId() {
    const url = `${environment.API_URL}/api/GPartner/GetGpartnerArea?Id=${this.gpid}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaListFormGpId = response.data.assiggedArea;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.areaListFormGpId = [];
        }
      });
  }
  // Area mapping from GPId and City id
  areaMapping() {
    this.areaListFormCity.forEach((item) => {
      this.areaListFormGpId.forEach((element) => {
        if ((item.id === element.areaId)) {
          item.isAvailable = element.assigned;
        }
      });
    });
  }
  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}
