import { Component, OnInit } from '@angular/core';
import { NbMenuItem, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { DocService } from '../../Shared/Services/doc.service';
import { IDoc } from '../../models/doc.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'ngx-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.scss']
})
export class DocComponent implements OnInit {

  docForm: FormGroup;
  isSaveDisabled = false;


  title1: string;
  data: any;
  file: File;
  docList: any[];

  submitted = false;
  pageRole: any;
  roleId: number;
  saveBtnHide = false;


  UserId = 0;
  imagePreview: string;

  pagetitle = 'Document Upoload';


  usertypeId = '3';
  userId = '0';
  name = '';



  constructor(

    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private docService: DocService,
    private activatedroute: ActivatedRoute

  ) {

    this.docForm = this.fb.group({
      docType: ['', [Validators.required]],
      img: ['', [Validators.required]],

    });
  }

  ngOnInit() {

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page;

    if (this.pageRole.isWrite === false) {
      this.saveBtnHide = true;
    }



    // get query string value

    this.usertypeId = this.activatedroute.snapshot.paramMap.get('typeid');
    this.userId = this.activatedroute.snapshot.paramMap.get('userid'); // areaId
    this.name = this.activatedroute.snapshot.paramMap.get('name'); // areaId



    if (this.usertypeId === '3') {
      this.pagetitle = 'Business Partner document upload : ' + this.name;
    }
    if (this.usertypeId === '1') {
      this.pagetitle = 'Employee document upload : ' + this.name;
    }
    if (this.usertypeId === '4') {
      this.pagetitle = 'Delivery Partner document upload : ' + this.name;
    }

    this.getDoctumentList(this.usertypeId, this.userId);
  }

  get f() {
    return this.docForm.controls;
  }

  valid() {
    this.submitted = true;
  }


  uploadFile(event) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.uploadFile(this.file);

      this.imagePreview = (fileReader.result).toString();
    },
      fileReader.readAsDataURL(this.file);
  }


  fileInfo: string;
  onFileSelect(input: HTMLInputElement): void {

    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;
      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }
      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
    this.file = input.files[0];
    this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
  }


  items: NbMenuItem[] = [
    {
      title: 'Menu link with parameters',
      expanded: true,
      children: [
        {
          title: 'Goes into angular `routerLink`',
          link: '', // goes into angular `routerLink`
        },
      ]
    }
  ];


  getDoctumentList(selectedActorId, selectedUserId) {
    const url = `${environment.API_URL}/api/Document/GetDocuments?actor=${selectedActorId}&UserId=${selectedUserId}`;
    this.docService.getDocList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.docList = response.data;
        }
      });
  }


  addDocument() {
    this.submitted = true;

    if (this.docForm.valid) {
      this.isSaveDisabled = true;

      const formData = new FormData();

      formData.set('UserId', this.userId);
      formData.set('actor', this.usertypeId);
      formData.set('DocType', this.docForm.value.docType);
      formData.set('File', this.file);
      formData.set('Path', this.imagePreview);
      let apiurl = '';
      apiurl = `${environment.API_URL}/api/Document/AddDocument`;
      this.docService.modifyDoc(apiurl, formData).subscribe(
        (response: ResponseModel<IDoc>) => {
          if (response.status === HttpStatusCode.OK) {
            this.showToast('top-right', 'success', 'Image  Added Successfully');
            this.getDoctumentList(this.usertypeId, this.userId);
            this.docForm.reset();
            this.fileInfo = '';
            this.file = null;
            this.submitted = false;
            this.isSaveDisabled = false;


          } else {
            {
              this.showToast('top-right', 'danger', response.statusMessage);
              this.isSaveDisabled = false;

            }
          }
        });
    }
  }

  removeImage(doc) {
    const formData = {
      'userId': +this.userId,
      'actor': +this.usertypeId,
      'docType': doc.docType
    };

    let apiurl = '';
    apiurl = `${environment.API_URL}/api/Document/RemoveDocument`;
    this.docService.deleteDoc(apiurl, formData).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Removed Successfully');
          this.getDoctumentList(this.usertypeId, this.userId);
          this.docList = [];

        } else {
          {
            this.showToast('top-right', 'danger', response.statusMessage);
          }

        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
