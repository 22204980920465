import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-cod-details',
  templateUrl: './cod-details.component.html',
  styleUrls: ['./cod-details.component.scss']
})
export class CodDetailsComponent implements OnInit {
  title: string;
  data: any;
  constructor(
    private dialogRef: NbDialogRef<any>,

  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
