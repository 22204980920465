import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product/product.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { GpartnerComponent } from './gpartner/gpartner.component';
import { DeliveryBoyComponent } from './delivery-boy/delivery-boy.component';
import { ProductRoutingModule } from './product-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	NbMenuModule,
	NbInputModule,
	NbCardModule,
	NbButtonModule,
	NbActionsModule,
	NbUserModule,
	NbCheckboxModule,
	NbRadioModule,
	NbDatepickerModule,
	NbSelectModule,
	NbIconModule,
	NbAccordionModule,
	NbDialogModule,
	NbTabsetModule,
	NbCalendarModule,
} from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';
import { ProductService } from '../Shared/Services/product.service';
import { ProductDeleteDialogComponent } from './product-form/product-delete-dialog/product-delete-dialog.component';
import { GpartnerDeleteDialogComponent } from './gpartner/gpartner-delete-dialog/gpartner-delete-dialog.component';
import {
	DeleviryBoyDeleteDialogComponent
} from './delivery-boy/deleviry-boy-delete-dialog/deleviry-boy-delete-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SaveProductComponent } from './product-form/save-product/save-product.component';
import { AppliedComponent } from './applied/applied.component';
import { FkUserComponent } from './fk-user/fk-user.component';
import { DeleteDialogComponent } from './fk-user/delete-dialog/delete-dialog.component';
import { BannerComponent } from './banner/banner.component';
import { BannerService } from '../Shared/Services/banner.service';
import { BannerDeleteComponent } from './banner/banner-delete/banner-delete.component';
import { AreaListDialogComponent } from './delivery-boy/area-list-dialog/area-list-dialog.component';
import { SeniercitienComponent } from './fk-user/seniercitien/seniercitien.component';
import { GpAreaAssingComponent } from './gpartner/gp-area-assing/gp-area-assing.component';
import { ResetDeviceComponent } from './gpartner/reset-device/reset-device.component';
import { ResetDeviceDpComponent } from './delivery-boy/reset-device-dp/reset-device-dp.component';
import { MetaDataComponent } from './product-form/meta-data/meta-data.component';
import { ViewDetilasComponent } from './fk-user/view-detilas/view-detilas.component';

@NgModule({
	declarations: [
		ProductComponent,
		BannerComponent,
		ProductFormComponent,
		GpartnerComponent,
		DeliveryBoyComponent,
		ProductDeleteDialogComponent,
		GpartnerDeleteDialogComponent,
		DeleviryBoyDeleteDialogComponent,
		SaveProductComponent,
		AppliedComponent,
		FkUserComponent,
		DeleteDialogComponent,
		BannerComponent,
		BannerDeleteComponent,
		AreaListDialogComponent,
		SeniercitienComponent,
		GpAreaAssingComponent,
		ResetDeviceComponent,
		ResetDeviceDpComponent,
		MetaDataComponent,
		ViewDetilasComponent,
	],
	imports: [
		ProductRoutingModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NbMenuModule,
		NbTabsetModule,
		ThemeModule,
		NbInputModule,
		NbCardModule,
		NbButtonModule,
		NbActionsModule,
		NbUserModule,
		NbCheckboxModule,
		NbRadioModule,
		NbDatepickerModule,
		NbSelectModule,
		NbIconModule,
		NbAccordionModule,
		NbCalendarModule,
		NbDialogModule,
		NgxPaginationModule,
		NbAccordionModule,
	],
	providers: [ProductService, BannerService],
	entryComponents: [
		MetaDataComponent,
		ProductDeleteDialogComponent,
		SeniercitienComponent,
		DeleteDialogComponent,
		GpartnerDeleteDialogComponent,
		DeleviryBoyDeleteDialogComponent,
		SaveProductComponent,
		BannerDeleteComponent,
		AreaListDialogComponent,
		GpAreaAssingComponent,
		ResetDeviceComponent,
		ResetDeviceDpComponent,
		ViewDetilasComponent
	],
})
export class ProductModule { }
