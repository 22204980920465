import { Component, OnInit } from '@angular/core';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { CodDialogComponent } from './cod-dialog/cod-dialog.component';
import { GetSetService } from '../../Shared/Services/get-set.service';
import { CodDetailsComponent } from './cod-details/cod-details.component';

@Component({
  selector: 'ngx-cod',
  templateUrl: './cod.component.html',
  styleUrls: ['./cod.component.scss']
})
export class CodComponent implements OnInit {

  codUserList: any[];
  unPaidUserList: any[];
  copyUserList: any[];
  copyDeActiveUserList: any[] = [];

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;
  flagForValue: any;
  showpaymentoptions = false;



  user = 0;
  userId = 0;
  activeDeactiveBtn = false;

  pageSize = 10;
  pageNumber = 1;
  isSave = false;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };


  constructor(
    private toastrService: NbToastrService,
    private gpService: GpartnerService,
    private dialogService: NbDialogService,
    private getSetService: GetSetService,


  ) { }

  ngOnInit() {
    this.getSetService.selectedFlagValue.subscribe(flag => {
      this.isSave = flag;
      if (this.isSave === true) {
        this.getPaidFkUser();
      }
    });
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'FlourPicker User');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }



  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
    this.getPaidFkUser();
  }
  changeTag(event) {
    this.flagForValue = event.tabId;
    this.getPaidFkUser();
  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.codUserList = this.copyUserList.filter(item => {
        const name = String(item.deliveryBoy).toLocaleLowerCase();
        const orderNo = String(item.orderNo).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (orderNo.includes(searchStr)) || (name.includes(searchStr));
      });
    } else {
      this.codUserList = this.copyUserList;
    }
  }
  getPaidFkUser() {
    this.codUserList = [];
    let url = '';
    if (this.flagForValue === '0') {
      url = `${environment.API_URL}/api/DeliveryBoyOrder/CODOrders?IsCredited=false&PageSize=0&pageno=0`;
    }
    if (this.flagForValue === '1') {
      url = `${environment.API_URL}/api/DeliveryBoyOrder/CODOrders?IsCredited=true&PageSize=0&pageno=0`;
    }
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.codUserList = response.data.cods;
          this.copyUserList = response.data.cods;
          this.config.totalItems = response.data.count;
        }
      });
  }

  openCodDialogBox(user: any, flag) {
    this.dialogService.open(CodDialogComponent, {
      context: {
        title: '',
        data: user,
        flag: flag
      },
    }).onClose.subscribe(() => {
      // this.getPaidFkUser();
    });
  }

  openDialogViewDetails(user: any) {
    this.dialogService.open(CodDetailsComponent, {
      context: {
        title: '',
        data: user,
      },
    }).onClose.subscribe(() => {

    });
  }


  // To active user
  activeUser(userId) {
    this.activeDeactiveBtn = true;
    const url = `${environment.API_URL}/api/User/ActiveUser?UserId=${userId}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.getPaidFkUser();
          this.activeDeactiveBtn = false;
          this.showToast('top-right', 'success', 'User Activeted Successfully');
        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }

}
