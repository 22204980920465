import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { environment } from '../../../environments/environment';
import { PromocodeService } from '../../Shared/Services/promocode.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit {
  @ViewChild('promocodeItem', { static: false }) accordion;
  id = 0;
  pageRole: any;
  promocodeForm: FormGroup;
  submitted = false;
  promoCodeList: any;
  copyPromoCodeList: any;
  saveBtnHideForRole = true ;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(public fb: FormBuilder,
    private promoCodeService: PromocodeService,
    private toastrService: NbToastrService,

  ) {
    this.promocodeForm = this.fb.group({
      code: ['', [Validators.required, Validators.maxLength(6),Validators.pattern('^[a-zA-Z0-9]+$')]],
      amount: [0, [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      isActive: [false],
      remark: ['', [Validators.required]]
    });

  }

  ngOnInit() {
    this.getPromoCodeList();

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Promo Code');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }

  }

  getPromoCodeList() {
    const url = `${environment.API_URL}/api/PromoCode/GetPromoCodeList?pageSize=0&pageNumber=0`;
    this.promoCodeService
      .getPromoCodeList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.promoCodeList = response.data;
          this.copyPromoCodeList = response.data;
          this.config.totalItems = this.promoCodeList.length;
        }
      });
  }
  addCode() {
    this.submitted = true;

    const data = {
      id: this.id,
      promoCode: this.promocodeForm.value.code,
      walletAmt: +this.promocodeForm.value.amount,
      remark: this.promocodeForm.value.remark,
      stratDate: this.getDate(this.promocodeForm.value.startDate),
      endDate:this.getDate(this.promocodeForm.value.endDate),
      isActive: this.promocodeForm.value.isActive,
      isDelete: false,

    };
    let url = null;
    if (this.promocodeForm.valid) {
      if (this.id === 0) {
        url = `${environment.API_URL}/api/PromoCode/AddPromCode`;
      } else {
        url = `${environment.API_URL}/api/PromoCode/UpdatePromoCode`;
      }
      this.promoCodeService.modifyPromoCode(url, data).subscribe
        ((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.id === 0) {
              this.showToast('top-right', 'success', 'Added Sucessfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Sucessfully');
            }
            this.submitted = false;

          } else {
            this.showToast('top-right', 'danger', response.statusMessage);
            this.submitted = false;
          }
          this.clearForm();
          this.accordion.close();
          this.getPromoCodeList()   ;
        });
    }
  }

  editCode(code) {
    this.accordion.open();
    this.id = code.id;
    this.promocodeForm.controls.code.setValue(code.promoCode);
    this.promocodeForm.controls.amount.setValue(code.walletAmt);
    this.promocodeForm.controls.remark.setValue(code.remark);
    this.promocodeForm.controls.startDate.setValue(new Date(code.stratDate));
    this.promocodeForm.controls.endDate.setValue(new Date(code.endDate));
    this.promocodeForm.controls.isActive.setValue(code.isActive);
  }

	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

	getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr =
			year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}

  filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.promoCodeList = this.copyPromoCodeList.filter(item => {
				const name = String(item.promoCode).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (name.includes(searchStr));
			});
		} else {
			this.promoCodeList = this.copyPromoCodeList;
		}
	}
  get f() {
    return this.promocodeForm.controls;
  }

  showToast(position, status, message) {
    this.toastrService.show(status || 'Success', `${message}`, {
      position,
      status
    });
  }

  clearForm() {
    this.id = 0;
    this.submitted = false;
    this.promocodeForm.reset();
  }

}
