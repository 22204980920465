import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { DonationService } from '../../Shared/Services/donation.service';
import { OrderStatusPopupComponent } from './order-status-popup/order-status-popup.component';

@Component({
  selector: 'ngx-donor-order',
  templateUrl: './donor-order.component.html',
  styleUrls: ['./donor-order.component.scss']
})
export class DonorOrderComponent implements OnInit {

  allDonateOrders: any;
  copyallDonateOrders: any;
  totalCount: number;
  pageSize = 0;
  pageNumber = 0;
  config = {
    itemsPerPage: 10,
    currentPage: 0,
    totalItems: 0,
  };

  constructor(
    private donationService: DonationService,
    private dialogService: NbDialogService

  ) { }

  ngOnInit() {
    this.getDonateOrderList();
  }

  // get all canteen list for admin
  getDonateOrderList() {
    const url = `${environment.API_URL}/api/Donation/AdminOrders?PageSize=${this.pageSize}&Pageno=${this.config.currentPage}`;
    this.donationService
      .getList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.allDonateOrders = response.data.orders;
          this.copyallDonateOrders = response.data.orders;
          this.config.totalItems = response.data.count;
          this.totalCount = response.data.count;

        }
      });
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
   // this. getDonateOrderList();
  }
  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.allDonateOrders = this.copyallDonateOrders.filter(item => {
        const name = String(item.fullName).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        const mobileNo = String(item.mobileNo).toLocaleLowerCase();
				const canteen = String(item.canteen).toLocaleLowerCase();
				const amount = String(item.amount).toLocaleLowerCase();
				// tslint:disable-next-line:max-line-length
				return (mobileNo.includes(searchStr) || (canteen.includes(searchStr) || name.includes(searchStr) || amount.includes(searchStr)));
      });
    } else {
      this.allDonateOrders = this.copyallDonateOrders;
    }
  }

  OpenStatusUpdate(order: any) {
    this.dialogService.open(OrderStatusPopupComponent, {
      context: {
                data: order,
      },
    }).onClose.subscribe(() => {
      // alert('close');
      this. getDonateOrderList();
    });
  }
}
