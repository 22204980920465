import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { DonationService } from '../../Shared/Services/donation.service';

@Component({
  selector: 'ngx-donor',
  templateUrl: './donor.component.html',
  styleUrls: ['./donor.component.scss']
})
export class DonorComponent implements OnInit {

  allDonarList = [];
  copyAllDonarList = [];
  totalCount: number;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private donationService: DonationService
  ) { }

  ngOnInit() {
    this.getDonorList();
  }

  // get all canteen list for admin
  getDonorList() {
    const url = `${environment.API_URL}/api/Donation/Doners`;
    this.donationService
      .getList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.allDonarList = response.data.getDonors;
          this.copyAllDonarList = response.data.getDonors;
          this.config.totalItems = response.data.count;
          this.totalCount = response.data.count;

        }
      });
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.allDonarList = this.copyAllDonarList.filter(item => {
        const name = String(item.fullname).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr));
      });
    } else {
      this.allDonarList = this.copyAllDonarList;
    }
  }

}
