import { Component, OnInit } from '@angular/core';
import { ReportPopupComponent } from './report-popup/report-popup.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { CityService } from '../../Shared/Services/city.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';

@Component({
  selector: 'ngx-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  showloder1 = false;
  showloder2 = false;
  showloder3 = false;
  showloder4 = false;
  showloder5 = false;
  showloder6 = false;
  showloder7 = false;
  showloder8 = false;
  showloder9 = false;
  showloder10 = false;
  showloder11 = false;
  showloder12 = false;
  showloder13 = false;
  showloder14 = false;
  showloder15 = false;
  showloder16 = false;
  showloder17 = false;
  showloder19 = false;
  showloder20 = false;
  showloder21 = false;

  hide1 = true;
  hide2 = true;
  hide3 = true;
  hide4 = true;
  hide5 = true;
  hide6 = true;
  hide7 = true;
  hide8 = true;
  hide9 = true;
  hide10 = true;
  hide11 = true;
  hide12 = true;
  hide13 = true;
  hide14 = true;
  hide15 = true;
  hide16 = true;
  hide17 = true;
  hide19 = true;
  hide20 = true;
  hide21 = true;


  pageRole: any;
  roleId: number;
  allReportHide = false;
  UserReports = false;
  BusinessPartnerReport = false;
  BusinessPartnerStockReport = false;
  BusinessPartnerConsumeStockReport = false;
  BusinesspartnerrateboardReport = false;
  CurrentOrderForDeliveryBoy = false;
  AreaStock = false;
  OrderReports = false;
  allBussinessPartnerStockReport = false;
  customerNthOrder = true;
  UserWallet = false;
  SourceOfPayment = false;
  OrderNumberwithCharges = false;
  PassOrder = false;
  CompletedOrders = false;
  DeliveryBoyKm = false;
  GPDBOrderCount = true;
  saleReport = false;
  MultigrainandRecipeOrderReport = false;
  InventoryReport = false;
  PurchaseReport = false;
  grindingreport = false;
  customerwiseOrder = false;
  dbcodcash = false;
  CustomerwithZeroOrdersRepport = false;
  CurrentOrderforGrindingPartnerReport = false;
  priceListReport = false;
  stockInReport = false;
  businessNoReport= true;
  dailyReadymadeOrderReport = true;
  dailyOrderReport = true;
  warehouseStockReport = true;
  areaStoreStockReport = true;

  constructor(
    private dialogService: NbDialogService,
    private cityService: CityService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'All reports');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.allReportHide = true;
    }

    this.pageRole = role.page.find(m => m.page === 'User Reports');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.UserReports = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Business Partner');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.BusinessPartnerReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Business Partner StockReport');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.BusinessPartnerStockReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Business Partner Consume Stock');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.BusinessPartnerConsumeStockReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Business partner rate board');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.BusinesspartnerrateboardReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Current order for delivery boy');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.CurrentOrderForDeliveryBoy = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Area stock');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.AreaStock = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Order Reports');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.OrderReports = true;
    }

    this.pageRole = role.page.find(m => m.page === 'User Wallet');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.UserWallet = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Source of payment');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.SourceOfPayment = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Order Number with Charges');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.OrderNumberwithCharges = true;
    }
    this.pageRole = role.page.find(m => m.page === 'Pass order by day');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.PassOrder = true;
    }
    this.pageRole = role.page.find(m => m.page === '​Completed Order');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.CompletedOrders = true;
    }
    this.pageRole = role.page.find(m => m.page === 'Delivery Boy Km travel');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.DeliveryBoyKm = true;
    }

    this.pageRole = role.page.find(m => m.page === 'SaleReport');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.saleReport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'MultigrainandRecipeOrderReport');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.MultigrainandRecipeOrderReport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'InventoryReport');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.InventoryReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'CustomerwithZeroOrders');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.CustomerwithZeroOrdersRepport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'CurrentOrderforGrindingPartner');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.CurrentOrderforGrindingPartnerReport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'pricelist');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.priceListReport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'PurchaseReport');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.PurchaseReport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'grindingreport');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.grindingreport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'customerwithorder');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.customerwiseOrder = true;
    }
    this.pageRole = role.page.find(m => m.page === 'codcashdb');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.dbcodcash = true;
    }

    this.pageRole = role.page.find(m => m.page === 'All Bussiness partner stock report');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.allBussinessPartnerStockReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'customerwithorder');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.customerNthOrder = true;

    }
    this.pageRole = role.page.find(m => m.page === 'Stock in');
    if (this.pageRole.isWrite || this.pageRole.isRead) {
      this.stockInReport = true;
    }




  }
  openDialogForBusinessPartnerPaymnetByDateTillDateCustomeDate() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 13,
        }
      },
    });
  }

  openDialogForCodCashfromdbByDateTillDateCustomeDate() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 14,
        }
      },
    });
  }
  openDialogForPurchaseReportByDateTillDateCustomeDate() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 12,
        }
      },
    });
  }
  openDialogForStockInReportByDateTillDateCustomeDate() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 11,
        }
      },
    });
  }
  openDialogForBpStockCusomeReport() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 10,
        }
      },
    });
  }
  openDialogForDayWiseReport() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 9,
        }
      },
    });
  }

  openDialog(status) {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: status,
        }
      },
    });
  }

  openOrderwiseCustomerDia() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 15,
        }
      },
    });
  }

  openWalleDialog() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 1,
        }
      },
    });
  }
  openSorcePayment() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 2,
        }
      },
    });
  }

  openOrderWirhCharges() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 3,
        }
      },
    });
  }

  oepnMultigrainDiaForRecipe() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 5,
        }
      },
    });
  }


  openCompleteOrederDia() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 6,
        }
      },
    });
  }


  openGPOrderCountDia() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 16,
        }
      },
    });
  }

  openBusinessNoDia(){
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 17,
        }
      },
    });
  }

  // openDailyReadymadeDia(){
  //   this.dialogService.open(ReportPopupComponent, {
  //     context: {
  //       title: '',
  //       data: {
  //         status: 19,
  //       }
  //     },
  //   });
  // }
  // openDailyOrdersDia(){
  //   this.dialogService.open(ReportPopupComponent, {
  //     context: {
  //       title: '',
  //       data: {
  //         status: 20,
  //       }
  //     },
  //   });
  // }
  openKm() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 7,
        }
      },
    });
  }

  openDialogForSaleReport() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 8,
        }
      },
    });
  }
 // Get  ​Daily Readymade Orders Report

  getReadymadeOrdersReport() {
    this.showloder15 = true;
    this.hide15 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GetDailyReadyMadeOrderDetails`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder15 = false;
          this.hide15 = true;

        } else {
          this.showloder15 = false;
          this.hide15 = true;
          this.showToast('top-right', 'danger', 'Reports data not found!');

        }
      });
  }
  // Get Daily Orders Report
  getDailyOrdersReport() {
    this.showloder16 = true;
    this.hide16 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GetDailyOrderDetails`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder16 = false;
          this.hide16 = true;

        } else {
          this.showloder16 = false;
          this.hide16 = true;
          this.showToast('top-right', 'danger', 'Reports data not found!');

        }
      });
  }
  // GET WAREHOUSE STOCK REPORT
  getWarehouseStockReport(){
    this.showloder20 = true;
    this.hide20 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GetWarehouseStockReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder20 = false;
          this.hide20 = true;

        } else {
          this.showloder20 = false;
          this.hide20 = true;
          this.showToast('top-right', 'danger', 'Reports data not found!');

        }
      });
  }

   // GET AREASTORE STOCK REPORT
   getAreastoreStockReport(){
    this.showloder21 = true;
    this.hide21 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GetCupboardStockReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder21 = false;
          this.hide21 = true;

        } else {
          this.showloder21 = false;
          this.hide21 = true;
          this.showToast('top-right', 'danger', 'Reports data not found!');

        }
      });
  }

  getUserReport() {
    this.showloder1 = true;
    this.hide1 = false;
    const url = `${environment.API_URL}/api/ExcelReport/userReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder1 = false;
          this.hide1 = true;

        } else {
          this.showloder1 = false;
          this.hide1 = true;

        }
      });
  }

  getBPReport() {
    this.showloder2 = true;
    this.hide2 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GPartnerReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder2 = false;
          this.hide2 = true;
        } else {
          this.showloder2 = false;
          this.hide2 = true;
        }
      });
  }

  getBPStoctReport() {
    this.showloder3 = true;
    this.hide3 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GPartnerStockReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder3 = false;
          this.hide3 = true;
        } else {
          this.showloder3 = false;
          this.hide3 = true;
        }
      });
  }

  getBPConsumeReport() {
    this.showloder4 = true;
    this.hide4 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GPartnerStockConsumeReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder4 = false;
          this.hide4 = true;
        } else {
          this.showloder4 = false;
          this.hide4 = true;
        }
      });
  }

  getAreaStockReport() {
    this.showloder9 = true;
    this.hide9 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GetExcelAreaStockReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder9 = false;
          this.hide9 = true;
        } else {
          this.showloder9 = false;
          this.hide9 = true;
        }
      });
  }

  // Customer with Zero Orders
  getCustomerwithZeroOrders() {
    this.showloder10 = true;
    this.hide10 = false;
    const url = `${environment.API_URL}/api/ExcelReport/CustomerReportwithZeroOrders`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder10 = false;
          this.hide10 = true;
        } else {
          this.showloder10 = false;
          this.hide10 = true;
          this.showToast('top-right', 'success', 'Records Not Found!');

        }
      });
  }

  // get BP rate board report
  getBPRateBoardReport() {
    this.showloder6 = true;
    this.hide6 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GetGPRateBoardReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder6 = false;
          this.hide6 = true;
        } else {
          this.showloder6 = false;
          this.hide6 = true;
        }
      });
  }

  // get DB delivery order report
  getCurrentOrderForDeliveryBoy() {
    this.showloder7 = true;
    this.hide7 = false;
    const url = `${environment.API_URL}​/api/ExcelReport/GetCurrentOrderforDeliveryBoyReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder7 = false;
          this.hide7 = true;
        } else {
          this.showloder7 = false;
          this.hide7 = true;
          this.showToast('top-right', 'success', 'No Current order report for DB');

        }
      });
  }
  // get BP grinding order report
  getGrindingReportCurrentOrder() {
    this.showloder8 = true;
    this.hide8 = false;
    const url = `${environment.API_URL}​​/api/ExcelReport/GetCurrentOrderforGrindingPartnerReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder8 = false;
          this.hide8 = true;
        } else {
          this.showloder8 = false;
          this.hide8 = true;
          this.showToast('top-right', 'success', 'No Current order report');

        }
      });
  }

  // get BP all stock report
  getAllBPStockReport() {
    this.showloder5 = true;
    this.hide5 = false;
    const url = `${environment.API_URL}​​/api/ExcelReport/AllBussinessPartnerStockReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder17 = false;
          this.hide5 = true;
        } else {
          this.showloder17 = false;
          this.hide5 = true;
          this.showToast('top-right', 'success', 'No Current order report');

        }
      });
  }

  // get Inventory report
  getInventoryReport() {
    this.showloder12 = true;
    this.hide12 = false;
    const url = `${environment.API_URL}​​/api/ExcelReport/Inventory`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder12 = false;
          this.hide12 = true;
        } else {
          this.showloder12 = false;
          this.hide12 = true;
          this.showToast('top-right', 'success', 'No Current order report');

        }
      });
  }

  // get pass order by user  report
  getPriceListReport() {
    this.showloder14 = true;
    this.hide14 = false;
    const url = `${environment.API_URL}​​/api/ExcelReport/PriceList`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder14 = false;
          this.hide14 = true;
        } else {
          this.showloder14 = false;
          this.hide14 = true;
          this.showToast('top-right', 'success', 'No Current order report');

        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(status || 'Success', `${message}`, {
      position,
      status
    });
  }

}
