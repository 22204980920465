import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbMenuModule, NbInputModule, NbCardModule,
  NbButtonModule, NbActionsModule, NbUserModule, NbCheckboxModule,
  NbRadioModule, NbDatepickerModule, NbSelectModule, NbIconModule,
  NbLayoutModule, NbAccordionModule, NbTooltipModule, NbCalendarModule, NbSpinnerModule, NbTabsetModule,
} from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlogsRoutingModule } from './donations-routing.module';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { DonorComponent } from './donor/donor.component';
import { DonorOrderComponent } from './donor-order/donor-order.component';
import { DonationsComponent } from './donations.component';
import { CanteenComponent } from './canteen/canteen.component';
import { OrderStatusPopupComponent } from './donor-order/order-status-popup/order-status-popup.component';

@NgModule({
  declarations: [
    DonorComponent,
    DonationsComponent,
    DonorOrderComponent,
    CanteenComponent,
    OrderStatusPopupComponent],
  imports: [
    AngularEditorModule,
    NbTabsetModule,
    NbSpinnerModule,
    CommonModule,
    BlogsRoutingModule,
    FormsModule,
    NbAccordionModule,
    ReactiveFormsModule,
    NbMenuModule,
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbCalendarModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    Ng2SmartTableModule,
    NbMenuModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NgxPaginationModule,
    NbTooltipModule,
    NbLayoutModule,
    NbSpinnerModule,


  ],
  providers: [],
  entryComponents: [
    OrderStatusPopupComponent
  ]

})
export class DonationsModule { }
