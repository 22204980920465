import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { PromocodeService } from '../../Shared/Services/promocode.service';

@Component({
  selector: 'ngx-coupon-code',
  templateUrl: './coupon-code.component.html',
  styleUrls: ['./coupon-code.component.scss']
})
export class CouponCodeComponent implements OnInit {
  @ViewChild('couponcodeItem', { static: false }) accordion;
  id = 0;
  pageRole: any;
  couponCodeForm: FormGroup;
  submitted = false;
  couponCodeList: any;
  copyCouponCodeList: any;
  saveBtnHideForRole = true ;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(public fb: FormBuilder,
    private promoCodeService: PromocodeService,
    private toastrService: NbToastrService,
    private datepipe: DatePipe,


  ) {
    this.couponCodeForm = this.fb.group({
      code: ['', [Validators.required, Validators.maxLength(6),Validators.pattern('^[a-zA-Z0-9]+$')]],
      amount: [0, [Validators.required]],
      percentage:[100,[Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      isActive: [false],
      remark: ['', [Validators.required]]
    },
    {
      validators: [
        this.chkDate('endDate', 'startDate'),
        this.checkDate('startDate'),
      ],
    }
    );
  }

  ngOnInit() {
    this.getPromoCodeList();

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Promo Code');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }

  }

  getPromoCodeList() {
    const url = `${environment.API_URL}/api/CouponCode/GetCouponCodeList?pageSize=0&pageNumber=0`;
    this.promoCodeService
      .getPromoCodeList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.couponCodeList = response.data;
          this.copyCouponCodeList = response.data;
          this.config.totalItems = this.copyCouponCodeList.length;
        }
      });
  }
  addCode() {
    this.submitted = true;

    const data = {
      id: this.id,
      couponCode: this.couponCodeForm.value.code,
      amount: +this.couponCodeForm.value.amount,
      percentage:+this.couponCodeForm.value.percentage,
      remark: this.couponCodeForm.value.remark,
      startDate: this.getDate(this.couponCodeForm.value.startDate),
      endDate:this.getDate(this.couponCodeForm.value.endDate),
      isActive: this.couponCodeForm.value.isActive,
      isDelete: false,

    };
    let url = null;
    if (this.couponCodeForm.valid) {
      if (this.id === 0) {
        url = `${environment.API_URL}/api/CouponCode/AddCouponCode`;
      } else {
        url = `${environment.API_URL}/api/CouponCode/UpdateCouponCode`;
      }
      this.promoCodeService.modifyPromoCode(url, data).subscribe
        ((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.id === 0) {
              this.showToast('top-right', 'success', 'Added Sucessfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Sucessfully');
            }
            this.submitted = false;

          } else {
            this.showToast('top-right', 'danger', response.statusMessage);
            this.submitted = false;
          }
          this.clearForm();
          this.accordion.close();
          this.getPromoCodeList()   ;
        });
    }
  }

  editCode(code) {
    this.accordion.open();
    this.id = code.id;
    this.couponCodeForm.controls.code.setValue(code.couponCode);
    this.couponCodeForm.controls.amount.setValue(code.amount);
    this.couponCodeForm.controls.percentage.setValue(code.percentage);
    this.couponCodeForm.controls.remark.setValue(code.remark);
    this.couponCodeForm.controls.startDate.setValue(new Date(code.startDate));
    this.couponCodeForm.controls.endDate.setValue(new Date(code.endDate));
    this.couponCodeForm.controls.isActive.setValue(code.isActive);
  }

	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

	getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr =
			year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}

  filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.couponCodeList = this.copyCouponCodeList.filter(item => {
				const name = String(item.couponCode).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (name.includes(searchStr));
			});
		} else {
			this.couponCodeList = this.copyCouponCodeList;
		}
	}
  get f() {
    return this.couponCodeForm.controls;
  }

  showToast(position, status, message) {
    this.toastrService.show(status || 'Success', `${message}`, {
      position,
      status
    });
  }

  clearForm() {
    this.id = 0;
    this.submitted = false;
    this.couponCodeForm.reset();
    this.couponCodeForm.controls.percentage.setValue(100);
  }
  /**
   * This function check that ens date should not be less. than start date.
   * @param controlName
   * @param dateControlName
   */
   chkDate(controlName: string, dateControlName: string) {
    return (formGroup: FormGroup) => {
      const endDate = formGroup.controls[controlName];
      const startDate = formGroup.controls[dateControlName];

      if (endDate.errors && !endDate.errors.validDate) {
        return;
      }
      if (endDate.value < startDate.value) {
        endDate.setErrors({ validDate: true });
      } else {
        endDate.setErrors(null);
      }
    };
  }
  checkDate(startDate: string) {
    return (formGroup: FormGroup) => {
      const sDate = formGroup.controls[startDate];
      const today = new Date(Date.now());
      const latest_date = this.datepipe.transform(today, 'yyyy-MM-dd');

      if (sDate.errors && !sDate.errors.validsDate) {
        return;
      }
      if (sDate.value < latest_date) {
        sDate.setErrors({ validsDate: true });
      } else {
        sDate.setErrors(null);
      }
    };
  }
}
