import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IGPartner, IEmployee } from '../../models';
import { NbToastrService, NbDialogService, NbDateService } from '@nebular/theme';
import { GpartnerDeleteDialogComponent } from './gpartner-delete-dialog/gpartner-delete-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { GpAreaAssingComponent } from './gp-area-assing/gp-area-assing.component';
import { ResetDeviceComponent } from './reset-device/reset-device.component';

@Component({
	selector: 'ngx-gpartner',
	templateUrl: './gpartner.component.html',
	styleUrls: ['./gpartner.component.scss']
})
export class GpartnerComponent implements OnInit {
	GrainPartnerRegistationForm: FormGroup;
	@ViewChild('gpItem', { static: false }) accordion;

	submitted = false;
	disabledBtn = false;

	accountNoValidation = '^[0-9]*$';
	mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
	pincodePattern = '^((\\+91-?)|0)?[0-9]{6}$';
	char = '[a-zA-Z][a-zA-Z]+';
	mRGCValidation = '[0-9]+(\.[0-9]{1,2})?$';


	gPartnerList: IGPartner[] = [];
	copyGPartnerList: IGPartner[] = [];
	employeeList: IEmployee[] = [];
	GPartnerId = 0;
	GpartnerCodeno = 'BP-0';


	cityList = [];
	areaList = [];
	isOwerOperator: any = 'false';
	shedTypeList = [
		{ id: 1, value: 'RCC' },
		{ id: 2, value: 'Metalic Patra' },
	];
	config = {
		itemsPerPage: 10,
		currentPage: 1,
		totalItems: 0
	};
	file: any;
	imgPreview = '';

	showEmployeeDiv = true;

	min: Date;
	max: Date;

	pageRole: any;
	roleId: number;
	saveBtnHideForRole = true;
    hideResetDeviceColum = true;

	constructor(
		protected dateService: NbDateService<Date>,
		private fb: FormBuilder,
		private activatedroute: ActivatedRoute,
		private gpService: GpartnerService,
		private dialogService: NbDialogService,
		private toastrService: NbToastrService
	) {

		this.min = this.dateService.addYear(this.dateService.today(), -10);
		this.max = this.dateService.today();

		this.GrainPartnerRegistationForm = this.fb.group({
			id: [''],
			lat: ['', [Validators.required]],
			long: ['', [Validators.required]],
			fName: ['', [Validators.required]],
			gpLocationcode: [''],
			lName: ['', [Validators.required]],
			isOwnerOperator: [false],
			address: ['', [Validators.required]],
			pincode: ['', [Validators.required]],
			contact1: ['', [Validators.required]],
			contact2: [''],
			area: ['', [Validators.required]],
			city: ['', [Validators.required]],
			eshtablishedYear: ['', [Validators.required]],
			mill: ['', [Validators.required]],
			empName: ['', [Validators.required]],
			empLastName: ['', [Validators.required]],
			empContact1: ['', [Validators.required]],
			ownerEmail: ['', [Validators.required, Validators.email]],
			empContact2: [''],
			empEmail: [''],
			empDob: [''],
			noOFMachine: ['', [Validators.required]],
			shopArea: ['', [Validators.required]],
			shedType: ['', [Validators.required]],
			chewTobacco: [false],
			anyDisease: [false],
			active: [false],
			fssai: ['', [Validators.required]],
			ifsc: ['', [Validators.required]],
			shopact: ['', [Validators.required]],
			bankName: ['', [Validators.required]],
			bankAcc: ['', [Validators.required]],
			branch: ['', [Validators.required]],
			verifiedBy: ['', [Validators.required]],
			description: [''],
			RGC: ['', [Validators.required]],
			MRGC: ['', [Validators.required]],

		});
	}

	ngOnInit() {
		const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		this.pageRole = role.page.find(m => m.page === 'BusinessPartnerUser');
		if (this.pageRole.isWrite === false || this.pageRole.isRead === false) {
			this.saveBtnHideForRole = false;
			this.hideResetDeviceColum = true;
		}
		this.getGPartnerList();
		this.getCityList();
		this.getEmployeeList();
		this.GPartnerId = +this.activatedroute.snapshot.paramMap.get('id');
	}
	clearForm() {
		this.GrainPartnerRegistationForm.reset();
		this.GPartnerId = 0;
		this.GpartnerCodeno = 'BP-0'
		// this.updateGPCode();
		this.submitted = false;
		this.disabledBtn = false;
	}

	updateGPCode() {
		if (this.GPartnerId === 0) {
			if (this.gPartnerList) {
				if (this.gPartnerList.length > 0) {

					this.GpartnerCodeno = 'BP-0' + (this.gPartnerList.length + 1).toString();
				} else if (this.gPartnerList == null || this.gPartnerList.length === 0) {
					this.GpartnerCodeno = 'BP-01';
				}
			}
		}
	}


	get f() {
		return this.GrainPartnerRegistationForm.controls;
	}

	validation() {
		this.submitted = true;
	}

	toggle() {
		this.accordion.toggle();
	}

	fileInfo: string;
	onFileSelect(input: HTMLInputElement): void {
		function formatBytes(bytes: number): string {
			const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const factor = 1024;
			let index = 0;
			while (bytes >= factor) {
				bytes /= factor;
				index++;
			}
			return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
		}
		const file = input.files[0];
		this.uploadFile(file);
		this.fileInfo = `${file.name} (${formatBytes(file.size)})`;
	}

	uploadFile(files) {
		this.file = files;
		const fileReader = new FileReader();
		(fileReader.onload = () => {
			this.imgPreview = fileReader.result.toString();
		}),
			fileReader.readAsDataURL(this.file);
	}

	getCityList() {
		const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
		this.gpService
			.getGPartnerList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.cityList = response.data;
				}
			});
	}

	getAreaListByCity(event) {
		const url = `${environment.API_URL
			}/api/Area/GetAreaListByCityId?CityId=${+event}`;
		this.gpService
			.getGPartnerList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.areaList = response.data;
				} else if (response.status === HttpStatusCode.NO_CONTENT) {
					this.areaList = [];
				}
			});
	}

	getEmployeeList() {
		const url = `${environment.API_URL}/api/AdminUser/AdminUserList`;
		this.gpService
			.getGPartnerList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.employeeList = response.data;
				}
			});
	}

	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

	filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.gPartnerList = this.copyGPartnerList.filter(item => {
				const code = String(item.gpcode).toLocaleLowerCase();
				const fName = String(item.firstName).toLocaleLowerCase();
				const lName = String(item.lastName).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (code.includes(searchStr) || (fName.includes(searchStr) || lName.includes(searchStr)));
			});
		} else {
			this.gPartnerList = this.copyGPartnerList;
		}
	}

	getGPartnerList() {
		const url = `${environment.API_URL}/api/GPartner/GetGPartnerList?pageSize=0&pageNumber=0`;
		this.gpService
			.getGPartnerList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.gPartnerList = response.data;
					this.copyGPartnerList = response.data;
					this.config.totalItems = this.gPartnerList.length;
					// this.updateGPCode();
				}
			});
	}

	getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr =
			year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}

	openDeleteDialog(gpartnerinfo: IGPartner) {
		this.dialogService
			.open(GpartnerDeleteDialogComponent, {
				context: {
					title: '',
					data: gpartnerinfo
				}
			})
			.onClose.subscribe(() => {
				this.getGPartnerList();
			});
	}

	saveGPartner() {
		this.submitted = true;
		if (this.GrainPartnerRegistationForm.valid) {
			this.disabledBtn = true;

			const data: IGPartner = {
				id: this.GPartnerId,
				// id:this.GrainPartnerRegistationForm.value.GPartnerId,
				firstName: this.GrainPartnerRegistationForm.value.fName,
				lastName: this.GrainPartnerRegistationForm.value.lName,
				establishedYear: this.getDate(
					this.GrainPartnerRegistationForm.value.eshtablishedYear
				),
				ownerEmail: this.GrainPartnerRegistationForm.value.ownerEmail,
				millName: this.GrainPartnerRegistationForm.value.mill,
				address: this.GrainPartnerRegistationForm.value.address,
				contact1: this.GrainPartnerRegistationForm.value.contact1,
				contact2: this.GrainPartnerRegistationForm.value.contact2,


				gpLocationCode: '',


				isOwnerOperator: this.GrainPartnerRegistationForm.value.isOwnerOperator,

				pincode: this.GrainPartnerRegistationForm.value.pincode,
				employeeFirstName: this.GrainPartnerRegistationForm.value.empName,
				employeeLastName: this.GrainPartnerRegistationForm.value.empLastName,
				employeeContact1: this.GrainPartnerRegistationForm.value.empContact1,
				employeeContact2: this.GrainPartnerRegistationForm.value.empContact2,
				// employeeEmail: this.GrainPartnerRegistationForm.value.empEmail,
				employeeEmail: '',
				employeeBirthDate: '2020-05-18',

				// employeeBirthDate: this.getDate(
				// 	this.GrainPartnerRegistationForm.value.empDob
				// ),
				noOfMachine: +this.GrainPartnerRegistationForm.value.noOFMachine,
				areaOfShop: this.GrainPartnerRegistationForm.value.shopArea.toString(),
				shedType: +this.GrainPartnerRegistationForm.value.shedType,
				doesOperatorChewTobaco: this.GrainPartnerRegistationForm.value
					.chewTobacco,
				doesOperatorHaveAnyDesease: this.GrainPartnerRegistationForm.value
					.anyDisease,
				// ownerBirthDate: this.getDate(
				// 	this.GrainPartnerRegistationForm.value.empDob
				// ),

				ownerBirthDate: '2020-05-18',
				productTypeId: 0,
				gpcode: this.GpartnerCodeno,
				lat_Lang: ((this.GrainPartnerRegistationForm.value.lat) + ',' + (this.GrainPartnerRegistationForm.value.long)),


				isVerified: true,
				isActive: this.GrainPartnerRegistationForm.value.active,
				areaId: +this.GrainPartnerRegistationForm.value.area,
				cityId: +this.GrainPartnerRegistationForm.value.city,
				shopActNo: this.GrainPartnerRegistationForm.value.shopact,
				fssaiNo: this.GrainPartnerRegistationForm.value.fssai,
				ifsc: this.GrainPartnerRegistationForm.value.ifsc,
				bankName: this.GrainPartnerRegistationForm.value.bankName,
				accNo: this.GrainPartnerRegistationForm.value.bankAcc,
				branchName: this.GrainPartnerRegistationForm.value.branch,
				discription: this.GrainPartnerRegistationForm.value.description,
				verifiedBy: +this.GrainPartnerRegistationForm.value.verifiedBy,
				verifiedByName: '',
				verificationFormImageBase64:
					this.imgPreview !== null ? this.imgPreview.split(',')[1] : null,
				verificationPath: '',
				recipeGrindingCharges: +this.GrainPartnerRegistationForm.value.RGC,
				multiGrainGrindingCharges: +this.GrainPartnerRegistationForm.value.MRGC,

			};

			// return;

			let url = '';
			if (this.GPartnerId === 0) {
				url = `${environment.API_URL}/api/GPartner/AddGPartner`;
			} else {
				url = `${environment.API_URL}/api/GPartner/UpdateGPartner`;
			}
			this.gpService
				.modifyGPartner(url, data)
				.subscribe((response: ResponseModel<any>) => {
					if (response.status === HttpStatusCode.OK) {
						if (this.GPartnerId === 0) {
							this.showToast('top-right', 'success', 'Added Successfully');


						} else {
							this.showToast('top-right', 'success', 'Updated Successfully');

						}
						this.disabledBtn = false;
						this.GPartnerId = 0;
						this.submitted = false;
						this.fileInfo = '';
						this.GrainPartnerRegistationForm.reset();
						this.getGPartnerList();
						this.accordion.close();

					} else {
						this.disabledBtn = false;
						if (response.status === 400) {
							this.showToast('top-right', 'danger', response.statusMessage);
						}
					}
				});
		}
	}

	editGPartner(gPartnerInfo: IGPartner) {
		this.accordion.open();
		this.GPartnerId = gPartnerInfo.id;
		this.GrainPartnerRegistationForm.controls.id.setValue(gPartnerInfo.gpcode);

		this.GpartnerCodeno = gPartnerInfo.gpcode;
		this.GrainPartnerRegistationForm.controls.fName.setValue(
			gPartnerInfo.firstName
		);
		this.GrainPartnerRegistationForm.controls.lName.setValue(
			gPartnerInfo.lastName
		);



		this.GrainPartnerRegistationForm.controls.gpLocationcode.setValue(
			gPartnerInfo.gpLocationCode
		);

		this.GrainPartnerRegistationForm.controls.address.setValue(
			gPartnerInfo.address
		);
		this.GrainPartnerRegistationForm.controls.pincode.setValue(
			gPartnerInfo.pincode
		);
		this.GrainPartnerRegistationForm.controls.contact1.setValue(
			gPartnerInfo.contact1
		);
		this.GrainPartnerRegistationForm.controls.contact2.setValue(
			gPartnerInfo.contact2
		);
		this.GrainPartnerRegistationForm.controls.city.setValue(
			gPartnerInfo.cityId
		);
		this.getAreaListByCity(gPartnerInfo.cityId);
		this.GrainPartnerRegistationForm.controls.area.setValue(
			gPartnerInfo.areaId
		);
		this.GrainPartnerRegistationForm.controls.eshtablishedYear.setValue(
			new Date(gPartnerInfo.establishedYear)
		);
		this.GrainPartnerRegistationForm.controls.empName.setValue(
			gPartnerInfo.employeeFirstName
		);
		this.GrainPartnerRegistationForm.controls.empLastName.setValue(
			gPartnerInfo.employeeLastName
		);
		this.GrainPartnerRegistationForm.controls.empContact1.setValue(
			gPartnerInfo.employeeContact1
		);
		this.GrainPartnerRegistationForm.controls.empContact2.setValue(
			gPartnerInfo.employeeContact2
		);
		this.GrainPartnerRegistationForm.controls.empEmail.setValue(
			gPartnerInfo.employeeEmail
		);
		this.GrainPartnerRegistationForm.controls.empDob.setValue(
			new Date(gPartnerInfo.employeeBirthDate)
		);
		this.GrainPartnerRegistationForm.controls.noOFMachine.setValue(
			gPartnerInfo.noOfMachine
		);
		this.GrainPartnerRegistationForm.controls.shopArea.setValue(
			gPartnerInfo.areaOfShop
		);
		this.GrainPartnerRegistationForm.controls.shedType.setValue(
			gPartnerInfo.shedType
		);
		this.GrainPartnerRegistationForm.controls.chewTobacco.setValue(
			gPartnerInfo.doesOperatorChewTobaco
		);
		this.GrainPartnerRegistationForm.controls.anyDisease.setValue(
			gPartnerInfo.doesOperatorHaveAnyDesease
		);
		this.GrainPartnerRegistationForm.controls.active.setValue(
			gPartnerInfo.isActive
		);

		this.GrainPartnerRegistationForm.controls.fssai.setValue(
			gPartnerInfo.fssaiNo
		);
		this.GrainPartnerRegistationForm.controls.shopact.setValue(
			gPartnerInfo.shopActNo
		);
		this.GrainPartnerRegistationForm.controls.ifsc.setValue(gPartnerInfo.ifsc);
		this.GrainPartnerRegistationForm.controls.bankName.setValue(
			gPartnerInfo.bankName
		);
		this.GrainPartnerRegistationForm.controls.bankAcc.setValue(
			gPartnerInfo.accNo
		);
		this.GrainPartnerRegistationForm.controls.branch.setValue(
			gPartnerInfo.branchName
		);
		this.GrainPartnerRegistationForm.controls.verifiedBy.setValue(
			gPartnerInfo.verifiedBy
		);

		this.GrainPartnerRegistationForm.controls.mill.setValue(
			gPartnerInfo.millName
		);

		this.GrainPartnerRegistationForm.controls.description.setValue(
			gPartnerInfo.discription
		);
		this.GrainPartnerRegistationForm.controls.ownerEmail.setValue(gPartnerInfo.ownerEmail);

		this.GrainPartnerRegistationForm.controls.RGC.setValue(gPartnerInfo.recipeGrindingCharges);
		this.GrainPartnerRegistationForm.controls.MRGC.setValue(gPartnerInfo.multiGrainGrindingCharges);


		this.fileInfo = '';

		const latandLang = gPartnerInfo.lat_Lang.split(',');
		this.GrainPartnerRegistationForm.controls.lat.setValue(
			latandLang[0]
		);
		this.GrainPartnerRegistationForm.controls.long.setValue(
			latandLang[1]
		);
		this.GrainPartnerRegistationForm.controls.isOwnerOperator.setValue(gPartnerInfo.isOwnerOperator);


	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status
		});
	}

	toggleDiv() {

		this.showEmployeeDiv = this.GrainPartnerRegistationForm.value.isOwnerOperator;

		if (this.showEmployeeDiv) {
			this.GrainPartnerRegistationForm.controls.empName.setValidators([Validators.required]);
			this.GrainPartnerRegistationForm.controls.empName.updateValueAndValidity();

			this.GrainPartnerRegistationForm.controls.empContact1.setValidators([Validators.required]);
			this.GrainPartnerRegistationForm.controls.empContact1.updateValueAndValidity();

			this.GrainPartnerRegistationForm.controls.empLastName.setValidators([Validators.required]);
			this.GrainPartnerRegistationForm.controls.empLastName.updateValueAndValidity();
		} else {

			this.GrainPartnerRegistationForm.controls.empName.setValidators(null);
			this.GrainPartnerRegistationForm.controls.empName.setErrors(null);

			this.GrainPartnerRegistationForm.controls.empContact1.setValidators(null);
			this.GrainPartnerRegistationForm.controls.empContact1.setErrors(null);

			this.GrainPartnerRegistationForm.controls.empLastName.setValidators(null);
			this.GrainPartnerRegistationForm.controls.empLastName.setErrors(null);
		}
	}
	// open pop for order assing area
	openPopUpForAssingArea(selectedGbId) {
		this.dialogService.open(GpAreaAssingComponent, {
			context: {
				title: '',
				gpid: selectedGbId,
			},
		}).onClose.subscribe(() => {
			this.getGPartnerList();
		});
	}
	// open pop for reset device
	openPopUpForResetDevice(selectedGbId) {
		this.dialogService.open(ResetDeviceComponent, {
			context: {
				title: '',
				gpid: selectedGbId,
			},
		}).onClose.subscribe(() => {
		});
	}
}
