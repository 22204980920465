import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { EmployeeService } from '../../Shared/Services/employee.service';
import { ReviewDialogComponent } from './review-dialog/review-dialog.component';

@Component({
  selector: 'ngx-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  ReviewList: any[];
  CopyReviewList: any[];
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private employeeService: EmployeeService,
    private dialogService: NbDialogService

  ) { }

  ngOnInit() {
    this.getReviewList() ;
  }

  openReplyDialog(review){
    this.dialogService.open(ReviewDialogComponent, {
      context: {
        title: '',
        data: review,
      }
    })
      .onClose.subscribe(() => {
        this.ngOnInit(); 
        // this.newComplain();
        // this.inProesss();
        // this.reSolve();
        // this.close();
      });
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.ReviewList = this.CopyReviewList.filter(item => {
        const fName = String(item.productName).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (fName.includes(searchStr) );
      });
    } else {
      this.ReviewList = this.CopyReviewList;
    }
  }

  getReviewList() {
    const url = `${environment.API_URL}/api/RatingReview/ProductReviewForAdmin?PageNumber=0&PageSize=0`;
    this.employeeService.getEmployeeList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.ReviewList = response.data;
          this.CopyReviewList = response.data;
          this.config.totalItems = this.ReviewList.length;
        }
      });
  }
}
