import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { IWarehouse } from '../../models';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { ReadymadeService } from '../../Shared/Services/readymade.service';

@Component({
  selector: 'ngx-areastore-stock',
  templateUrl: './areastore-stock.component.html',
  styleUrls: ['./areastore-stock.component.scss']
})
export class AreastoreStockComponent implements OnInit {
  warwhouseStockForm: FormGroup;
  cityList = [];
  warehouseList = [];
  areastoreList = [];
  stockList = [];
  stockListAS: IWarehouse[];
  copyStockListAS = []
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private readyMadeStockService: ReadymadeService
  ) { }

  ngOnInit() {
    this.getCityList();
  }
  onWHChange(event) {
    this.getAreaStoreList(event.target.value);
    this.stockListAS= [];
  }
	getclass(ele) {
		const min = ele.minStock;
    (ele.capacityInKg * ele.minPercentage) / 100;
		const max = ele.capacityInQty;
		return ele.stock < min ? 'min-limit' : ele.stock > max ? 'max-limit' : '';
	}

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.stockListAS = this.copyStockListAS.filter(item => {
        const name = String(item.product).toLocaleLowerCase();
        // const grain = String(item.grain).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr));
      });
    } else {
      this.stockListAS = this.copyStockListAS;
    }
    this.config.totalItems = this.stockListAS.length;
     this.config.currentPage = 1;
  }

  // Get city list
	getCityList() {
		const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
		this.readyMadeStockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.cityList = response.data;
				} else if (response.status === HttpStatusCode.NO_CONTENT) {
					this.cityList = [];
				}
			});
			this.warehouseList = [];
			this.areastoreList = [];
	}
		// Get Warehouse list 
	getWarehouseList(cityID) {
		const url = `${environment.API_URL}/api/Warehouse/GetWarehouseByCityIdList?cityId=${cityID}`;
		this.readyMadeStockService.getStockList(url).subscribe(
		  (response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
			  this.warehouseList = response.data.warehouses;
			} else {
			  this.warehouseList = [];
			}
		  }
		);
		this.areastoreList = [];
    this.stockListAS = [];
    this.copyStockListAS =[];
    this.config.totalItems = 0;

	  }

  // getWarehouseList() {
  //   const url = `${environment.API_URL}/api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
  //   this.readyMadeStockService.getStockList(url).subscribe(
  //     (response: ResponseModel<any>) => {
  //       if (response.status === HttpStatusCode.OK) {
  //         this.warehouseList = response.data.warehouses;
  //         this.copyWarehouseList = response.data.warehouses;
  //       } else {
  //         this.warehouseList = [];
  //       }
  //     }
  //   );
  // }
  // Get Area-Store list
  getAreaStoreList(WhId) {
    const url = `${environment.API_URL}/api/ReadymadeCupboard/GetCupboardByWarehouseIdList?warehoseId=${WhId}`;
    this.readyMadeStockService.getStockList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areastoreList = response.data.cupboard;
        } else {
          this.areastoreList = [];
        }
      }
    );
    this.stockListAS = [];
    this.copyStockListAS =[];
    this.config.totalItems = 0;
  }
  getStockList(event) {
    const url = `${environment.API_URL}/api/ReadyMateStock/ReadymateCupboardStock?cupboardId=${+event.target.value}`;
    //const url = `${environment.API_URL}/api/ReadyMateStock/ReadymateStock?warehouseId=${+event.target.value}`;
    this.readyMadeStockService
      .getStockList(url)
      .subscribe((response: ResponseModel<IWarehouse[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.stockListAS = response.data;
          this.copyStockListAS = response.data;
          this.config.totalItems = this.copyStockListAS.length;
          this.config.currentPage = 1;

        } if (response.status === 204) {
          this.stockListAS = null;

        }
      });
  }
	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

}