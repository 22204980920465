import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  commentapprove = new Subject();

  constructor(
    private http: HttpClient
  ) { }

  getBlogList(url): Observable<any> {
    return this.http.get(url).pipe(map((response: Response) => response as any));
  }

  updateComment(url, commentInfo): Observable<any> {
    return this.http.post(url, commentInfo).pipe(map((response: Response) => response as any));
  }

  delete(url, data): Observable<any> {
    return this.http.post(url, data).pipe(map((response: Response) => response as any));
  }

}
