import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { CityService } from '../../../Shared/Services/city.service';
// import { GpartnerService } from '../../../Shared/Services/gpartner.service';
import { GetSetService } from '../../../Shared/Services/get-set.service';

@Component({
  selector: 'ngx-cod-dialog',
  templateUrl: './cod-dialog.component.html',
  styleUrls: ['./cod-dialog.component.scss']
})
export class CodDialogComponent implements OnInit {
  codForm: FormGroup;
  title: string;
  data: any;
  flag: any;
  submitted = false;
  isShowSaveBtn = true;

  paymentModeTypeList = [

    { id: 4, value: '4', name: 'Cash' },
    { id: 5, value: '5', name: 'UPI' },
    { id: 8, value: '8', name: 'No cash' },
  ];
  constructor(
    private fb: FormBuilder,
    private dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService,
    private cityService: CityService,
    private getSetService: GetSetService
  ) {
    this.codForm = this.fb.group({
      status: [Boolean],
      tNo: [''],
      paymentMode: [],
      remark: ['']
    });
  }

  ngOnInit() {
    this.codForm.controls.status.setValue(this.flag);
    if (this.data.paymentType === 5) {
      this.codForm.controls.tNo.setValidators([Validators.required]);
      this.codForm.controls.tNo.updateValueAndValidity();
    } else {
      this.codForm.controls.password.setValidators(null);
      this.codForm.controls.password.setErrors(null);
    }
  }

  get f() {
    return this.codForm.controls;
  }

  close() {
    this.dialogRef.close();
  }
  changeType(event) {
    if (event > 0) {
      this.isShowSaveBtn = true;
    } else {
      this.isShowSaveBtn = false;
    }
  }
  saveBtnEnabled() {
    if (this.codForm.get('status').value === true) {
      this.isShowSaveBtn = true;
    } else {
      this.isShowSaveBtn = false;
    }
  }

  getCodOrderList() {
    this.getSetService.callToFormChildComponent(true);
  }
  updateCodStatus() {
    this.submitted = true;
    if (this.codForm.valid) {
      this.isShowSaveBtn = true;
      const data = {
        orderId: this.data.id,
        isCredited: this.codForm.value.status,
        transactionNo: this.codForm.value.tNo,
        mode: +this.codForm.value.paymentMode,
        paymentRemark: this.codForm.value.remark
      };
      const url = `${environment.API_URL}​/api/DeliveryBoyOrder/UpdateCodStatus`;
      this.cityService.modifyCity(url, data).subscribe
        ((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.showToast('top-right', 'success', 'update Successfully');
            this.submitted = false;
            this.isShowSaveBtn = false;
            this.getCodOrderList();
          } else {
            this.submitted = false;
            this.isShowSaveBtn = false;
          }
        });
    }
  }
  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }

}

