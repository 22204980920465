import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockComponent } from './stock/stock.component';
import { StockRoutingModule } from './stock-routing.module';
import {
  NbMenuModule, NbInputModule, NbCardModule, NbButtonModule,
  NbActionsModule, NbUserModule, NbCheckboxModule, NbRadioModule,
  NbDatepickerModule, NbSelectModule, NbIconModule, NbAccordionModule,
  NbToastrModule, NbDialogModule, NbTabsetModule
} from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';
import { StockInComponent } from './stock-in/stock-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StockoutComponent } from './stockout/stockout.component';
import { CompanyStockComponent } from './company-stock/company-stock.component';
import { GpartnerStockComponent } from './gpartner-stock/gpartner-stock.component';
import { StockService } from '../Shared/Services/stock.service';
import { RequiredIfDirective } from '../Shared/validation/requiredif.directive';
import { StockoutDeleteDialogComponent } from './stockout/stockout-delete-dialog/stockout-delete-dialog.component';
import { StockinDeleteDialogComponent } from './stock-in/stockin-delete-dialog/stockin-delete-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { AssignareaComponent } from './warehouse/assignarea/assignarea.component';
import { ReadymadeStockinComponent } from './readymade-stockin/readymade-stockin.component';
import { ReadymadeService } from '../Shared/Services/readymade.service';
import { ReadymadeStockDeleteDialogComponent } from './readymade-stockin/readymade-stock-delete-dialog/readymade-stock-delete-dialog.component';
import { WarehouseStockComponent } from './warehouse/warehouse-stock/warehouse-stock.component';
import { WarehouseDeleteComponent } from './warehouse/warehouse-delete/warehouse-delete.component';
import { CodComponent } from './cod/cod.component';
import { CodDialogComponent } from './cod/cod-dialog/cod-dialog.component';
import { ReturnStockComponent } from './return-stock/return-stock.component';
import { CodDetailsComponent } from './cod/cod-details/cod-details.component';
import { WarehouseStockInComponent } from './warehouse-stock-in/warehouse-stock-in.component';
import { WarehouseStockOutComponent } from './warehouse-stock-out/warehouse-stock-out.component';
import { WarehouseStockDetailComponent } from './warehouse-stock-detail/warehouse-stock-detail.component';
import { WhStockCapacityComponent } from './wh-stock-capacity/wh-stock-capacity.component';
import { WhStockReturnComponent } from './wh-stock-return/wh-stock-return.component';
import { AreastoreStockCapacityComponent } from './areastore-stock-capacity/areastore-stock-capacity.component';
import { AreastoreStockComponent } from './areastore-stock/areastore-stock.component';

@NgModule({
  declarations: [StockComponent,
    StockInComponent,
    StockoutComponent, CompanyStockComponent, GpartnerStockComponent,
    StockoutDeleteDialogComponent, StockinDeleteDialogComponent, RequiredIfDirective,
    WarehouseComponent, AssignareaComponent, ReadymadeStockinComponent, WarehouseStockComponent,
    ReadymadeStockDeleteDialogComponent,
    WarehouseDeleteComponent,
    CodComponent,
    CodDialogComponent,
    ReturnStockComponent,
    CodDetailsComponent,
    WarehouseStockInComponent,
    WarehouseStockOutComponent,
    WarehouseStockDetailComponent,
    WhStockCapacityComponent,
    WhStockReturnComponent,
    AreastoreStockCapacityComponent,
    AreastoreStockComponent,
  ],
  imports: [
    CommonModule,
    StockRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbMenuModule,
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbAccordionModule,
    NbDialogModule,
    NbToastrModule,
    NbTabsetModule,
    NgxPaginationModule
  ],
  providers: [StockService, ReadymadeService],
  entryComponents: [StockoutDeleteDialogComponent,
    StockinDeleteDialogComponent, AssignareaComponent, CodDetailsComponent,
    CodDialogComponent,
    ReadymadeStockDeleteDialogComponent, WarehouseDeleteComponent]
})
export class StockModule { }
