import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
// import { ToastrService } from 'ngx-toastr';
import { IRights, IRole } from '../../models/role.model';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { RoleService } from '../../Shared/Services/role.service';

@Component({
  templateUrl: 'rolerights.component.html',
  styleUrls: ['./rolerights.component.scss'],
})
export class RolerightsComponent implements OnInit {

  roles: IRole[] = [];
  rights: IRights[] = [];

  showloader = false;

  constructor(
    // private toastr: ToastrService,
    private roleservice: RoleService
  ) {


  }

  ngOnInit() {
    this.getRole();
  }

  getRole() {
    // const url = `Role/GetActiveList`;
    const url = `${environment.API_URL}/api/Role/GetActiveRoleList`;

    this.roleservice.getRoleList(url).subscribe((response: ResponseModel<any>) => {
      console.log(response);
      if (response.status === HttpStatusCode.OK) {
        this.roles = response.data;
      }
    });
  }

  onwrightsclick(rigth: IRights) {
    console.log(rigth.isWrite);
    if (!rigth.isWrite) {
      rigth.isRead = true;
    }
  }

  getRights(roleId: number) {
    const url = `${environment.API_URL}/api/PageAccess/GetPageAcessByRoleIdAsync?roleId=${roleId}`;
    this.roleservice.getRoleList(url).subscribe((response: ResponseModel<any>) => {
      console.log(response);
      if (response.status === HttpStatusCode.OK) {
        this.rights = response.data;
        this.makeModulcheck();
      }
    });
  }

  // add area
  saveRoleRigts() {
    let apiurl = `${environment.API_URL}/api/PageAccess/UpdateAccessNew`;
    const data = this.rights;
    this.showloader = true;
    this.roleservice
      .modifyRole(apiurl, data)
      .subscribe((response: ResponseModel<any>) => {
        this.showloader = false;
        if (response.status === HttpStatusCode.OK) {
          this.showToast('success', 'Rights updated successfully');
        } else {
          this.showToast('danger', response.statusMessage);
        }

      });

  }
  showToast(status, message) {
    alert(`${message}`)
    // this.toastr.success(`${message}`, `${status}`);
  }

  selectModule(right: IRights) {

    if (!right.moduleAccess) {

      this.rights.forEach(ele => {
        if (ele.module == right.module) {
          ele.isRead = true;
          ele.isWrite = true;
        }
      });
    } else {
      this.rights.forEach(ele => {
        if (ele.module == right.module) {
          ele.isRead = false;
          ele.isWrite = false;
        }
      });
    }

  }

  makeModulcheck() {

    const modulelist = [];

    this.rights.forEach(ele => {
      if (!modulelist.includes(ele.module)) {
        modulelist.push(ele.module);
      }
    });

    this.rights.forEach(ele => {
      ele.moduleAccess = true;
    });

    modulelist.forEach(element => {

      const valueArry = [];
      this.rights.forEach(ele => {



        if (ele.module == element) {
          valueArry.push(ele.isWrite);
        }
      });

      if (valueArry.includes(false)) {
        this.rights.forEach(ele => {
          if (ele.module == element) {
            ele.moduleAccess = false;
          }
        });
      }

    });

  }


}
