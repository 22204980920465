import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { CityService } from '../../../Shared/Services/city.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ngx-report-popup',
  templateUrl: './report-popup.component.html',
  styleUrls: ['./report-popup.component.scss']
})
export class ReportPopupComponent implements OnInit {
  title: '';
  data: any;
  message: any;
  orderMessage: string;
  errorMessage: any;
  reportForm: FormGroup;
  submitted = false;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  options = [
    { value: '1', label: 'Till date', disabled: false },
    { value: '2', label: 'This month', disabled: false },
    { value: '3', label: 'Custom Date', disabled: false },

  ];
  selectedRedioBtnValue = '1';
  todayDate = new Date();
  myDate: any;
  convertedDate: any;
  constructor(
    private fb: FormBuilder,
    private dialogRef: NbDialogRef<any>,
    private cityService: CityService,
    private datePipe: DatePipe,
    private dialogService: NbDialogService,

  ) {
    this.myDate = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    const firstDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), 1);
    this.convertedDate = this.datePipe.transform(firstDay, 'yyyy-MM-dd');
    this.reportForm = this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      orderNo: ['', [Validators.required]],
      days: ['', [Validators.required]],
      isRedaymade: [false]
    });

  }

  ngOnInit() {
  }

  get f() {
    return this.reportForm.controls;
  }

  getDate(dateinfo) {
    const day = dateinfo.getDate().toString();
    const year = dateinfo.getFullYear().toString();
    const month = (dateinfo.getMonth() + 1).toString();
    const dateStr =
      year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
    return dateStr;
  }

  getOrderDetailsReport() {
    this.submitted = true;
    if (this.reportForm.controls.orderNo.valid) {
      const url = `${environment.API_URL}/api/Auth/OrderInDetail?OrderId=${this.reportForm.value.orderNo}`;
      this.cityService
        .getCityList(url)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            window.open(response.data, '_blank');
          } else if (response.status === 400) {
            this.errorMessage = 'Incorrect Order number';
          } else {
            this.message === 'Enter Valid Order No';
          }
        });
    }
  }

  downloadInvoice() {
    this.submitted = true;
    if (this.reportForm.controls.orderNo.valid) {
      const url = `${environment.API_URL}/api/Report/invoicefromOrderNo?OrderNumber=${this.reportForm.value.orderNo}`;
      this.cityService
        .getCityList(url)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            window.open(response.data, '_blank');
          } else if (response.status === 400) {
            this.errorMessage = 'Incorrect Order number';
          } else {
            this.message === 'Enter Valid Order No';
          }
        });
    }
  }


  getUserWallet() {
    this.submitted = true;
    const url = `${environment.API_URL}/api/ExcelReport/GetUserUsedWalletReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Not found wallet this slot';
        }

      });
  }

  getSorcePay() {
    this.submitted = true;
    const url = `${environment.API_URL}/api/ExcelReport/GetSourceofpaymentofwalletReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Not found wallet this slot';
        }

      });
  }

  getOrderNoWithCharge() {
    this.submitted = true;
    const url = `${environment.API_URL}/api/ExcelReport/GetOrderNumberWithChargesReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'yet no order completed..!';
        }

      });
  }

  getPassOrderByDay() {
    this.submitted = true;
    const url = `${environment.API_URL}/api/ExcelReport/GetDayPassedOrderedUserReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'No order Passed';
        }

      });
  }
  getMuliGrainRecipeOrderReport() {
    this.submitted = true;
    const url = `${environment.API_URL}/api/ExcelReport/GetMultiGrainandRecipeOrderReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'No Multigrain and Recipe order';
        }

      });
  }
  getCompleteOrder() {
    this.submitted = true;
    const url = `${environment.API_URL}​/api/ExcelReport/GetCompletedOrderReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Order Yet Not Completed';
        }

      });
  }
  getKmReport() {
    this.submitted = true;
    const url = `${environment.API_URL}​/api/ExcelReport/GetDeliveryBoyKmTravelReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'no delivery boy Pick-up Order';
        }

      });
  }
  getDaysWiseReport() {
    this.submitted = true;
    if (this.reportForm.controls.days.valid) {
      const url = `${environment.API_URL}/api/ExcelReport/GetDayPassedOrderedUserReport?Days=${this.reportForm.value.days}`;
      this.cityService
        .getCityList(url)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            window.open(response.data, '_blank');
          } else if (response.status === 400) {
            this.errorMessage = 'Incorrect  number';
          } else {
            this.message === 'Enter Valid Order No';
          }
        });
    }
  }


  getOrderwiseCustomer() {
    this.submitted = true;
    if (this.reportForm.controls.days.valid) {
      const url = `${environment.API_URL}/api/ExcelReport/OrderCountCustomer?OrderCount=${this.reportForm.value.days}`;
      this.cityService
        .getCityList(url)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            window.open(response.data, '_blank');
          } else if (response.status === 400) {
            this.errorMessage = 'Incorrect  number';
          } else {
            this.message === 'Enter Valid Order No';
          }
        });
    }
  }
  getBPConsumeReport() {
    this.submitted = true;
    const url = `${environment.API_URL}/api/ExcelReport/GPartnerStockConsumeReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } else if (response.status === 400) {
          this.errorMessage = 'Incorrect  date';
        } else {
          this.message === 'Enter valid date';
        }
      });
  }
  getReportsForStockInCustomeDateTillDateThisMonth() {
    if (this.selectedRedioBtnValue === '3') {
      this.reportForm.controls.startDate.setValidators([Validators.required]);
      this.reportForm.controls.startDate.updateValueAndValidity();
      this.reportForm.controls.endDate.setValidators([Validators.required]);
      this.reportForm.controls.endDate.updateValueAndValidity();
    } else {
      this.reportForm.controls.startDate.setValidators(null);
      this.reportForm.controls.startDate.setErrors(null);
      this.reportForm.controls.endDate.setValidators(null);
      this.reportForm.controls.endDate.setErrors(null);
    }
    this.submitted = true;
    let url = '';
    if (this.selectedRedioBtnValue === '1') {
      url = `${environment.API_URL}/api/ExcelReport/StockinReport?StartDate=2020-05-01&EndDate=${this.myDate}&IsReadymate=${this.reportForm.value.isRedaymade}`;
    } else if (this.selectedRedioBtnValue === '2') {
      url = `${environment.API_URL}/api/ExcelReport/StockinReport?StartDate=${this.convertedDate}&EndDate=${this.myDate}&IsReadymate=${this.reportForm.value.isRedaymade}`;
    } else if (this.selectedRedioBtnValue === '3') {
      url = `${environment.API_URL}/api/ExcelReport/StockinReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}&IsReadymate=${this.reportForm.value.isRedaymade}`;
    }
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } else if (response.status === 400) {
          this.errorMessage = 'Incorrect  date';
        } else {
          this.message === 'Enter valid date';
        }
      });
  }
  getReportsForBussinessPartnerPaymentDateTillDateThisMonth() {
    if (this.selectedRedioBtnValue === '3') {
      this.reportForm.controls.startDate.setValidators([Validators.required]);
      this.reportForm.controls.startDate.updateValueAndValidity();
      this.reportForm.controls.endDate.setValidators([Validators.required]);
      this.reportForm.controls.endDate.updateValueAndValidity();
    } else {
      this.reportForm.controls.startDate.setValidators(null);
      this.reportForm.controls.startDate.setErrors(null);
      this.reportForm.controls.endDate.setValidators(null);
      this.reportForm.controls.endDate.setErrors(null);
    }
    this.submitted = true;
    let url = '';
    if (this.selectedRedioBtnValue === '1') {
      url = `${environment.API_URL}/api/ExcelReport/GrindingCharges?StartDate=2020-05-01&EndDate=${this.myDate}`;
    } else if (this.selectedRedioBtnValue === '2') {
      url = `${environment.API_URL}/api/ExcelReport/GrindingCharges?StartDate=${this.convertedDate}&EndDate=${this.myDate}`;
    } else if (this.selectedRedioBtnValue === '3') {
      url = `${environment.API_URL}/api/ExcelReport/GrindingCharges?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    }
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } else if (response.status === 400) {
          this.errorMessage = 'Incorrect  date';
        } else {
          this.message === 'Enter valid date';
        }
      });
  }

  getReportsForCodCashDbDateTillDateThisMonth() {

    this.submitted = true;
    let url = '';
    url = `${environment.API_URL}/api/ExcelReport/DeliveryBoyCodCash?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } else if (response.status === 400) {
          this.errorMessage = 'Incorrect  date';
        } else {
          this.message === 'Enter valid date';
        }
      });
  }


  getReportsForPurchaseDateTillDateThisMonth() {
    if (this.selectedRedioBtnValue === '3') {
      this.reportForm.controls.startDate.setValidators([Validators.required]);
      this.reportForm.controls.startDate.updateValueAndValidity();
      this.reportForm.controls.endDate.setValidators([Validators.required]);
      this.reportForm.controls.endDate.updateValueAndValidity();
    } else {
      this.reportForm.controls.startDate.setValidators(null);
      this.reportForm.controls.startDate.setErrors(null);
      this.reportForm.controls.endDate.setValidators(null);
      this.reportForm.controls.endDate.setErrors(null);
    }
    this.submitted = true;
    let url = '';
    if (this.selectedRedioBtnValue === '1') {
      url = `${environment.API_URL}/api/ExcelReport/PurchaseReport?StartDate=2020-05-01&EndDate=${this.myDate}`;
    } else if (this.selectedRedioBtnValue === '2') {
      url = `${environment.API_URL}/api/ExcelReport/PurchaseReport?StartDate=${this.convertedDate}&EndDate=${this.myDate}`;
    } else if (this.selectedRedioBtnValue === '3') {
      url = `${environment.API_URL}/api/ExcelReport/PurchaseReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    }
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } else if (response.status === 400) {
          this.errorMessage = 'Incorrect  date';
        } else {
          this.message === 'Enter valid date';
        }
      });
  }
  getSaleReportForDateTillDateThisMonth() {
    if (this.selectedRedioBtnValue === '3') {
      this.reportForm.controls.startDate.setValidators([Validators.required]);
      this.reportForm.controls.startDate.updateValueAndValidity();
      this.reportForm.controls.endDate.setValidators([Validators.required]);
      this.reportForm.controls.endDate.updateValueAndValidity();
    } else {
      this.reportForm.controls.startDate.setValidators(null);
      this.reportForm.controls.startDate.setErrors(null);
      this.reportForm.controls.endDate.setValidators(null);
      this.reportForm.controls.endDate.setErrors(null);
    }
    this.submitted = true;
    let url = '';
    if (this.selectedRedioBtnValue === '1') {
      url = `${environment.API_URL}/api/ExcelReport/PurchaseReport?StartDate=2020-05-01&EndDate=${this.myDate}`;
    } else if (this.selectedRedioBtnValue === '2') {
      url = `${environment.API_URL}/api/ExcelReport/PurchaseReport?StartDate=${this.convertedDate}&EndDate=${this.myDate}`;
    } else if (this.selectedRedioBtnValue === '3') {
      url = `${environment.API_URL}/api/ExcelReport/PurchaseReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    }
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } else if (response.status === 400) {
          this.errorMessage = 'Incorrect  date';
        } else {
          this.message === 'Enter valid date';
        }
      });
  }

  getGPDBOrderCountReport(){
    this.submitted = true;
    const url = `${environment.API_URL}​/api/ExcelReport/GP_And_DB_OrderCount?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Report Yet Not Completed';
        }

      });
  }
  getBusinessNumbersReport(){
    this.submitted = true;
    // 
    const url = `${environment.API_URL}​/api/ExcelReport/GetTotalOrderDetailsCount?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          console.log(response.data);
          this.openBusinessNo(response.data,);
         // window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Report Yet Not Completed';
        }

      });
  }

  getReadymadeOrdeReport(){
    this.submitted = true;
    // 
    const url = `${environment.API_URL}​/api/ExcelReport/GetDailyReadyMadeOrderDetails?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Report Yet Not Completed';
        }
      });
  }
  getDailyOrdeReport(){
    this.submitted = true;
    const url = `${environment.API_URL}​/api/ExcelReport/GetDailyOrderDetails?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Report Yet Not Completed';
        }
      });
  }

  openBusinessNo(data:any) {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 18,
            info:data,
            fromDate:this.getDate(this.reportForm.value.startDate),
            toDate:this.getDate(this.reportForm.value.endDate),
        }
      },
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
