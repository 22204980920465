import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbMenuModule, NbInputModule, NbCardModule,
  NbButtonModule, NbActionsModule, NbUserModule, NbCheckboxModule,
  NbRadioModule, NbDatepickerModule, NbSelectModule, NbIconModule,
  NbLayoutModule, NbAccordionModule, NbTooltipModule, NbCalendarModule,
} from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';
import { FpdashboardComponent } from './fpdashboard/fpdashboard.component';
import { PassOrderComponent } from './pass-order/pass-order.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CurrentRunningOrderComponent } from './current-running-order/current-running-order.component';
import { CancelorderComponent } from './cancelorder/cancelorder.component';
import { TodaysorderComponent } from './todaysorder/todaysorder.component';
import { OrderInProgressComponent } from './order-in-progress/order-in-progress.component';
import { OrderCompleteComponent } from './order-complete/order-complete.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { OrderDialogComponent } from './order-dialog/order-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AccpetOrderComponent } from './accpet-order/accpet-order.component';
import { PickUpOrderComponent } from './pick-up-order/pick-up-order.component';
import { OrderComplitedByDbComponent } from './order-complited-by-db/order-complited-by-db.component';
import { PassOrderGpComponent } from './pass-order-gp/pass-order-gp.component';
import { AssingGpComponent } from './pass-order-gp/assing-gp/assing-gp.component';
import { PassOrderDbComponent } from './pass-order-db/pass-order-db.component';
import { AssingOrderDbComponent } from './pass-order-db/assing-order-db/assing-order-db.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';

@NgModule({
  declarations: [
    DashboardComponent,
    FpdashboardComponent,
    PassOrderComponent,
    CurrentRunningOrderComponent,
    CancelorderComponent,
    TodaysorderComponent,
    OrderInProgressComponent,
    OrderCompleteComponent,
    OrderDialogComponent,
    AccpetOrderComponent,
    PickUpOrderComponent,
    OrderComplitedByDbComponent,
    PassOrderGpComponent,
    AssingGpComponent,
    PassOrderDbComponent,
    AssingOrderDbComponent,
    AssingOrderDbComponent,
    ProfileInfoComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    NbAccordionModule,
    ReactiveFormsModule,
    NbMenuModule,
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbCalendarModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    Ng2SmartTableModule,
    NbMenuModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NgxPaginationModule,
    NbTooltipModule,
    NbLayoutModule

  ],
  providers: [],
  entryComponents: [
    OrderDialogComponent,
    AssingGpComponent,
    AssingOrderDbComponent,
    ProfileInfoComponent
  ]

})
export class DashboardModule { }
