import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { RoleService } from '../../Shared/Services/role.service';

@Component({
  selector: 'ngx-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})
export class ZoneComponent implements OnInit {
  @ViewChild('zoneitem', { static: false }) accordion;
  zoneForm: FormGroup;
  zoneList = [];
  submitted = false;
  Id = 0;
  pageRole: any;
  isSaveDisabled = false;
  saveBtnHide = false;
  rolesaveBtnHide = true;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private fb: FormBuilder,
    private roleService: RoleService,
    private toastrService: NbToastrService) {
      this.zoneForm = this.fb.group({
        name: ['', [Validators.required]],
        isActive: [false]
      });
  
  }

  ngOnInit() {
    
    this.getZoneList();
  }
  getZoneList() {
    const url = `${environment.API_URL}/GetZoneList?pageSize=0&pageNumber=0`;
    this.roleService.getRoleList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.zoneList = response.data;
          this.config.totalItems = this.zoneList.length;
        }
      });
  }

  saveZone() {
    this.submitted = true;
    const data = {
      id: this.Id,
      name: this.zoneForm.value.name,
      isActive: this.zoneForm.value.isActive
    };
    if (this.zoneForm.valid) {
      this.isSaveDisabled = true;
      let url = '';
      if (this.Id === 0) {
        url = `${environment.API_URL}/AddZone`;
      } else {
        url = `${environment.API_URL}/UpdateZone`;
      }
      this.roleService.modifyRole(url, data).subscribe(
        (response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.Id === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
              this.getZoneList();
              this.accordion.close();
              this.submitted = false;
              this.isSaveDisabled = false;
              this.Id = 0;
              this.clearForm();
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
              this.getZoneList();
              this.accordion.close();
              this.submitted = false;
              this.isSaveDisabled = false;
              this.Id = 0;
              this.clearForm();
            }
          } else {
            this.showToast('top-right', 'danger', response.statusMessage);
            this.isSaveDisabled = false;
            this.saveBtnHide = false;
          }
        });
    }
  }
  editZone(zone) {
    this.accordion.open();
    this.Id = zone.id;
    this.zoneForm.controls.name.setValue(zone.name);
    this.zoneForm.controls.isActive.setValue(zone.isActive);
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  clearForm() {
    this.Id = 0;
    this.zoneForm.reset();
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
