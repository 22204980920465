import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { BlogService } from '../../Shared/Services/blog.service';
import { EmployeeService } from '../../Shared/Services/employee.service';
import { DeleteCommentsComponent } from './delete-comments/delete-comments.component';

@Component({
  selector: 'ngx-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  commentList = [];
  copyCommentList = [];
  commentForm: FormGroup;
  submitted = false;
  isSaveDisabled = false;
  id: any;
  tabId: any;
  replycmtval: any;
  textareaValue: any = '';
  isShow = false;
  isdisable = false;
  commentId = 0;
  blogId: any;
  parentId: any;
  pageSize = 10;
  userName: any;
  fullDescription = false;
  isUpdate: boolean = false;
  isApprove: any;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  @ViewChild('commentItem', { static: false }) accordion;

  constructor(private blogService: BlogService,
    private employeeService: EmployeeService,
    private fb: FormBuilder,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService) {
    this.commentForm = this.fb.group({
      Comment: [''],
      blog: [''],
      Title: [''],
      reply: ['', [Validators.required]],
      isApprove: [Boolean]

    });
  }

  ngOnInit() {
  }
  // for insilization
  get f() {
    return this.commentForm.controls;
  }

  setValueToForm(comment: any) {
    this.isUpdate = true;
    this.accordion.open();
    this.id = comment.Id;
    this.blogId = comment.BlogId,
      this.parentId = comment.ParentId,
      this.commentForm.controls.Comment.setValue(comment.Comment);
    this.commentForm.controls.blog.setValue(comment.ImagePath);
    this.commentForm.controls.Title.setValue(comment.Title);
  }

  // comment
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
    this.getCommentList(true);
  }

  // search by name, comment
  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.commentList = this.copyCommentList.filter(item => {
        const name = String(item.comment).toLocaleLowerCase();
        const uname = String(item.firstName).toLocaleLowerCase();
        const lname = String(item.lastName).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr)) || (uname.includes(searchStr)) || (lname.includes(searchStr));
      });
    } else {
      this.commentList = this.copyCommentList;
    }
  }

  // validation
  validation() {
    this.submitted = true;
  }

  // update comment
  addComment() {
    this.submitted = true;
    if (this.commentForm.valid) {
      const data = {
        Id: this.commentId,
        comment: this.commentForm.value.reply,
        BlogId: this.blogId,
        ParentId: this.parentId,
        IsAdminApprove: this.isApprove

      };
      let apiurl = '';
      if (this.commentId === 0) {
        apiurl = `${environment.API_URL}api/Comment/ReplyComment`;
      }
      this.employeeService.modifyEmployee(apiurl, data).subscribe
        ((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.isdisable = false;
            if (this.commentId === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
            }
            this.commentForm.reset();
            this.submitted = false;
            this.accordion.close();
            this.getCommentList(false);
            this.commentId = 0;
            this.submitted = true;
          } else {
            if (response.status === 400) {
              this.showToast('top-right', 'danger', response.statusMessage);
              this.isdisable = false;
            }
            this.isdisable = false;
            this.commentId = 0;
            this.submitted = false;
          }
        });
    }
  }

  // clear form
  clearForm() {
    this.commentForm.reset();
    this.submitted = false;
  }


  doTextareaValueChange(ev) {
    try {
      this.textareaValue = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }

  changeTag(event) {
    if (event.tabId === 'nonApprove') {
      this.config.currentPage = 1;
      this.getCommentList(false);
    } else if (event.tabId === 'Approve') {
      this.getCommentList(true);
    } else {
      this.config.currentPage = 1;
      this.getCommentList(true);
    }
  }

  // get comment list
  getCommentList(flag) {
    this.commentList = [];
    const url = `${environment.API_URL}/api/Comment/GetCommentList?isApprove=${flag}&pageSize=${this.pageSize}&pageNumber=${this.config.currentPage}`;
    this.blogService
      .getBlogList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.commentList = response.data.commentModels;
          this.copyCommentList = response.data.commentModels;
          this.config.totalItems = response.data.count;
        }
      });
  }

  // open dialog box
  openCommentPopUp(comment) {
    this.dialogService.open(DeleteCommentsComponent, {
      context: {
        title: '',
        data: comment,
      },
    })
      .onClose.subscribe(() => {
      });

  }

  // toster
  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}
