import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IGrain } from '../../models/grain.model';
import { ProductService } from '../../Shared/Services/product.service';
import { ISubGrain } from '../../models/subGrain.model';
import { IProduct } from '../../models/product.model';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ProductDeleteDialogComponent } from './product-delete-dialog/product-delete-dialog.component';
import { SaveProductComponent } from './save-product/save-product.component';
import { MetaDataComponent } from './meta-data/meta-data.component';

@Component(
	{
		selector: 'ngx-product-form', templateUrl: './product-form.component.html',
		styleUrls: ['./product-form.component.scss'],
	})

export class ProductFormComponent implements OnInit {
	ProductFormArray: FormArray = this.fb.array([]);
	productForm: FormGroup;
	itemForm: FormGroup;
	submitted = false;
	grainList: IGrain[] = [];
	subGrainList: ISubGrain[] = [];
	allSubGrainList: ISubGrain[] = [];
	productId = 0;
	productList = [];
	copyProductList = [];
	pageSize = 0;
	pageNumber = 0;
	totalPrice = 0;
	isImageValid = false
	pageRole: any;
	roleId: number;
	saveBtnHideForRole = true;
	isShowMinQtyForSeasonaItem = false;
	isShowForDefaultGrindingType = true;


	prodctCode = 0;
	selectedType = 1;
	selectedTypeValue = 0;
	@ViewChild('productFormtest', { static: false }) accordion;

	config = {
		itemsPerPage: 10,
		currentPage: 1,
		totalItems: 0,
	};
	file: any;
	imgPreview = null;
	disabledBtn = false;
	maximumSeqNum = 0;

	TypeList = [
		{ id: 1, value: '1', name: 'Fine' },
		{ id: 2, value: '2', name: 'Medium' },
		{ id: 3, value: '3', name: 'Coarse' },
	];
	constructor(
		private fb: FormBuilder,
		private productService: ProductService,
		private dialogService: NbDialogService,
		private toastrService: NbToastrService,
	) {
		this.productForm = this.fb.group({
			engName: ['', [Validators.required]],
			hinName: ['', [Validators.required]],
			marName: ['', [Validators.required]],
			minglish: ['', [Validators.required]],
      		hinglish: ['', [Validators.required]],
			active: [false],
			tax: [0],
			price: ['', [Validators.required]],
			mrp: ['', [Validators.required]],
			totalPrice: [''],
			type: ['1', [Validators.required]],
			discription: ['', [Validators.required]],
			discriptionMr: ['', [Validators.required]],
			discriptionHn: ['', [Validators.required]],
			imagePath: [''],
			quantities: ['', [Validators.required]],
			barcode: [''],
			prodSequence: ['', [Validators.required]],
			minQty: [''],
			default: [''],
			subTittleMr: [''],
			subTittleHn: [''],
			subTittleEn: ['']
			// metaTitle:['',[Validators.minLength(50),Validators.maxLength(75)]],
			// metaDescription:['',[Validators.minLength(120),Validators.maxLength(180)]],
			// metaKeywords:[''],

		});
	}
	clearForm() {
		this.submitted = false;
		this.productId = 0;
		this.productForm.reset();
		this.ProductFormArray = this.fb.array([]);
		this.addProductForm();
		this.productForm.controls.type.setValue(1);
		this.selectedType = 1;
		this.fileInfo = '';
		this.isImageValid =false;
		this.disabledBtn = false;
		this.prodctCode = 0;
		this.productForm.controls.quantities.enable();

	}

	ngOnInit() {

		const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		this.pageRole = role.page.find(m => m.page === 'Product Form');
		if (this.pageRole.isWrite === false) {
			this.saveBtnHideForRole = false;
		}
		this.addProductForm();
		this.getGrainList();
		this.getAllSubGrainList();
		this.getProductList(this.pageSize, this.pageNumber);
		this.productService.getProductUpdateStatus.subscribe(flag => {
			if (flag) {
				this.ProductFormArray = this.fb.array([]);
				this.addProductForm();
				this.productForm.reset();
				this.submitted = false;
				this.productId = 0;
				this.getProductList(0, 0);
			}
		});
	}

	addProductForm() {
		this.ProductFormArray.push(
			this.fb.group({
				GrainId: [0, [Validators.required]],
				CategoryId: [0, [Validators.required]],
				Qty: [1000, [Validators.required]],
				PerKg: [0],
				Price: [0]
			})
		);
	}
	get f() {
		return this.productForm.controls;
	}

	saveProduct() {
		this.submitted = true;
		const data: IProduct = {
			id: this.productId,
			typeId: +this.productForm.value.type,
			engName: this.productForm.value.engName,
			hindiName: this.productForm.value.hinName,
			marathiName: this.productForm.value.marName,
			name_Hinglish:this.productForm.value.hinglish, 
			name_Manglish:this.productForm.value.minglish,
			pricePerUnit: +this.productForm.value.price,
			mrp: +this.productForm.value.mrp,
			tax: 0,
			productDetails: this.getProductItemList(this.ProductFormArray),
			imageBase64:
				this.imgPreview !== null ? this.imgPreview.split(',')[1] : '',
			discription: this.productForm.value.discription,
			discriptionHn: this.productForm.value.discriptionHn,
			discriptionMr: this.productForm.value.discriptionMr,
			quantities: this.productForm.value.quantities,
			barcode: this.productForm.value.barcode,
			sequence: +this.productForm.value.prodSequence,
			minQty: +this.productForm.value.minQty,
			active: this.productForm.value.active,
			defaultGrindingType: +this.productForm.value.default,
			subTittleMr: this.productForm.value.subTittleMr,
			subTittleHn: this.productForm.value.subTittleHn,
			subTittleEn: this.productForm.value.subTittleEn,
			// metaTittle:this.productForm.value.metaTitle,
			// metaDiscription:this.productForm.value.metaDescription,
			// keyword:this.productForm.value.metaKeywords,
		};
		if (this.productForm.valid) {
			let apiurl = '';
			this.totalPrice = 0;
			data.productDetails.forEach(product => {
				this.totalPrice = this.totalPrice + product.price;
			});
			if (data.pricePerUnit < this.totalPrice) {
				this.openSaveDialog(data);
				return;
			} else {
				if (this.productId === 0) {
					apiurl = `${environment.API_URL}/api/Product/Add`;
				} else { apiurl = `${environment.API_URL}/api/Product/Update`; }
				this.disabledBtn = true;
				this.productService.modifyProduct(apiurl, data).subscribe((response: ResponseModel<any>) => {
					this.disabledBtn = false;
					if (response.status === HttpStatusCode.OK) {
						if (this.productId === 0) {
							this.showToast('top-right', 'success', 'Added Successfully');
						} else {
							this.showToast('top-right', 'success', 'Updated Successfully');
						}
						this.ProductFormArray = this.fb.array([]);
						this.addProductForm();
						this.clearForm();
						this.submitted = false;
						this.productId = 0;
						this.file = null;
						this.fileInfo = '';
						this.getProductList(0, 0);
						this.accordion.close();
					} else {
						this.showToast('top-right', 'danger', response.statusMessage);
					}
				});
			}
		}
	}

	removeItem(index, form) {
		const price = form.value.PerKg;
		this.totalPrice -= price;
		if (this.totalPrice > 0) {
			this.productForm.controls.totalPrice.setValue(this.totalPrice);
		}
		this.ProductFormArray.controls.splice(index, 1);
		// this.ProductFormArray.controls.forEach(form => {
		// this.totalPrice -= form.value.PerKg;
		// });
	}

	openDeleteDialog(product: IProduct) {
		this.dialogService
			.open(ProductDeleteDialogComponent, {
				context: {
					title: '',
					data: product,
				}
			})
			.onClose.subscribe(() => {
				this.getProductList(0, 0);
			});
	}

	openSaveDialog(product: IProduct) {
		this.accordion.close();
		this.dialogService
			.open(SaveProductComponent, {
				context: {
					title: '',
					data: product,
				}
			})
			.onClose.subscribe(() => {
				this.getProductList(0, 0);
			});
	}


	fileInfo: string;
	onFileSelect(input: HTMLInputElement): void {
		//this.fileInfo = '';
		this.isImageValid = false
		const reader = new FileReader();
		reader.readAsDataURL(input.files[0]);
		reader.onload = () => {
		  const img = new Image();
		  img.src = reader.result as string;
		  img.onload = () => {
			const height = img.naturalHeight;
			const width = img.naturalWidth;
			console.log('Width and Height size ', width, height, input.files[0].size);
			//check file is less than 100 kb & height & width
			if (height < 350 && width < 510 && input.files[0].size <  200000) {
			  this.file = input.files[0];
			  this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
			  this.isImageValid = false
			}
			else {
			  this.isImageValid = true;
			  //alert('The file is to large. Allowed maximum size of 100 KB');
			}
		  };
		};
	
		function formatBytes(bytes: number): string {
			const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const factor = 1024;
			let index = 0;
			while (bytes >= factor) {
				bytes /= factor;
				index++;
			}
			return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
		}
		this.file = input.files[0];
		this.uploadFile(this.file);
		this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
	}


	uploadFile(files) {
		const file = files;
		const fileReader = new FileReader();
		(fileReader.onload = () => {
			this.imgPreview = fileReader.result.toString();
		}),
			fileReader.readAsDataURL(file);
	}

	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}


	filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.productList = this.copyProductList.filter(item => {
				const name = String(item.name_ENG).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (name.includes(searchStr));
			});
		} else {
			this.productList = this.copyProductList;
		}
	}

	getProductList(pageSize: number = 10, pageNumber: number = 1) {
		const url = `${environment.API_URL}/api/Product/List?TypeId=0&PageSize=${pageSize}&PageNumber=${pageNumber}`;
		this.productService
			.getProductList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.productList = response.data;
					const sequenceNumberList = this.productList.map((item) => item.sequence);
					this.maximumSeqNum = Math.max(...sequenceNumberList);
					if (this.maximumSeqNum === 0) {
						this.maximumSeqNum = 1;
					}
					this.copyProductList = response.data;
					this.config.totalItems = this.productList.length;
				}
			});
	}

	editProduct(Product) {
		this.accordion.open();
		if (Product.typeId === 8 || Product.typeId === 9 || Product.typeId === 10) {
			this.isShowMinQtyForSeasonaItem = true;
		} else {
			this.isShowMinQtyForSeasonaItem = false;
		}
		if (Product.typeId === 1 || Product.typeId === 2) {
			this.isShowForDefaultGrindingType = true;
		} else {
			this.isShowForDefaultGrindingType = false;
		}
		
		this.productForm.controls.quantities.enable();

		this.prodctCode = Product.productId;
		this.productId = Product.id;
		this.totalPrice = 0;

		this.productForm.controls.default.setValue(Product.defaultGrindingType);
		this.productForm.controls.minQty.setValue(Product.minQty);
		this.productForm.controls.engName.setValue(Product.name_ENG);
		this.productForm.controls.hinName.setValue(Product.name_HIN);
		this.productForm.controls.marName.setValue(Product.name_MAR);
		this.productForm.controls.minglish.setValue(Product.name_Manglish);
		this.productForm.controls.hinglish.setValue(Product.name_Hinglish);
		this.productForm.controls.price.setValue(Product.price);
		this.productForm.controls.mrp.setValue(Product.mrp);
		this.productForm.controls.discription.setValue(Product.description);
		this.productForm.controls.discriptionHn.setValue(Product.descriptionHn);
		this.productForm.controls.discriptionMr.setValue(Product.descriptionMr);
		this.productForm.controls.active.setValue(Product.active);
		this.productForm.controls.quantities.setValue(Product.quantities);
		this.productForm.controls.barcode.setValue(Product.barcode);
		this.productForm.controls.prodSequence.setValue(Product.sequence);
		this.selectedType = Product.typeId;
		this.productForm.controls.type.setValue(this.selectedType);
		if (Product.typeId === 8 || Product.typeId === 9 || Product.typeId === 10 || Product.typeId === 7) {
			this.productForm.controls.quantities.setValue(1);
			this.productForm.controls.quantities.disable();
		}
		if (Product.quantities === null) {
			this.productForm.controls.quantities.setValue(1);
		}



		this.ProductFormArray = this.fb.array([]);

		(Product.detail as Array<any>).forEach(element => {

			const itemForm = this.fb.group({
				GrainId: [element.grainId, [Validators.required]],
				CategoryId: [0, [Validators.required]],
				Qty: [element.quantity, [Validators.required]],
				PerKg: [element.price, [Validators.required]],
				Price: [element.price, [Validators.required]]
			});
			this.getSubGrainList(element.grainId, itemForm, element.grainCategoryId);
			this.ProductFormArray.push(itemForm);

			this.totalPrice += itemForm.value.PerKg;
		});
		this.productForm.controls.totalPrice.setValue(this.totalPrice);

		this.calContentPrice();

		if (Product.imagePath != null || Product.imagePath !== undefined || Product.imagePath !== '') {

			const imagePathSTR = Product.imagePath;

			const arraystr = imagePathSTR.split('/');

			this.fileInfo = arraystr[arraystr.length - 1];
		}
		this.productForm.controls.subTittleMr.setValue(Product.subTittleMr);
		this.productForm.controls.subTittleHn.setValue(Product.subTittleHn);
		this.productForm.controls.subTittleEn.setValue(Product.subTittleEn);
		// this.productForm.controls.metaTitle.setValue(Product.metaTittle);
		// this.productForm.controls.metaDescription.setValue(Product.metaDiscription);
		// this.productForm.controls.metaKeywords.setValue(Product.keyword);
	}

	getProductItemList(itemFormArray: FormArray) {
		const temItemArray = [];
		itemFormArray.controls.forEach((form: FormGroup) => {
			if (form.valid) {
				const obj = {
					grainCategoryId: +form.value.CategoryId,
					quantity: +form.value.Qty,
					price: +form.value.Price,
				};
				temItemArray.push(obj);
			}
		});
		return temItemArray;
	}

	getGrainList() {
		const url = `${environment.API_URL}/api/Grain/GetGrainList`;
		this.productService
			.getProductList(url)
			.subscribe((response: ResponseModel<IGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.grainList = response.data;
				}

			});
	}

	getAllSubGrainList() {
		const url = `${environment.API_URL}/api/GrainCategory/getAllActiveGrainCategory`;
		this.productService
			.getProductList(url)
			.subscribe((response: ResponseModel<ISubGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.allSubGrainList = response.data;
				}
			});
	}

	getSubGrainList(grainId, itemForm, subGrainId?: number) {

		const subgrainList = [];

		if (subGrainId === undefined) {
			subGrainId = 0;
		}

		this.allSubGrainList.forEach(subgrain => {
			if (subgrain.grainId === +grainId) {
				subgrainList.push(subgrain);

				if ((subGrainId !== 0) && (subgrain.id === subGrainId)) {
					itemForm.controls.PerKg.setValue(subgrain.price);
				}

			}
		});




		itemForm['subGrainList'] = subgrainList;
		itemForm.controls.CategoryId.setValue(subGrainId);


	}

	getSubGrainValue(subGrain, subGrainList: Array<any>, itemForm: FormGroup) {
		subGrainList.forEach(item => {
			if (+subGrain === item.id) {
				itemForm.controls.PerKg.setValue(item.price);
				const qty = +itemForm.controls.Qty.value;
				let price = 0;
				if (qty > 0) {
					price = (qty / 1000) * +itemForm.controls.PerKg.value;
				}
				itemForm.controls.Price.setValue(price.toFixed(2));
			}
		});
		this.calContentPrice();
	}

	qtychange(itemForm) {
		const qty = +itemForm.controls.Qty.value;
		let price = +itemForm.controls.Price.value;
		// const perKg = +itemForm.controls.PerKg.value;
		price = (qty / 1000) * +itemForm.controls.PerKg.value;
		itemForm.controls.Price.setValue(price.toFixed(2));
		this.calContentPrice();
	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status
		});
	}
	selectMe(selecterdValue) {
		this.productForm.controls.type.setValue(selecterdValue);
		this.selectedType = selecterdValue;
		if (selecterdValue === 1 || selecterdValue === 2) {
			this.isShowForDefaultGrindingType = true;
		} else {
			this.isShowForDefaultGrindingType = false;
		}
		if (selecterdValue === 8 || selecterdValue === 9 || selecterdValue === 10) {
			this.isShowMinQtyForSeasonaItem = true;
			this.productForm.controls.minQty.setValidators([Validators.required]);
			this.productForm.controls.minQty.updateValueAndValidity();

		} else {
			this.isShowMinQtyForSeasonaItem = false;
			this.productForm.controls.minQty.setValidators(null);
			this.productForm.controls.minQty.setErrors(null);

		}
		if (selecterdValue === 7 || selecterdValue === 8 || selecterdValue === 9 || selecterdValue === 10) {
			this.productForm.controls.quantities.setValue(1);
			this.productForm.controls.quantities.disable();

		} else {
			this.productForm.controls.quantities.reset();
			this.productForm.controls.quantities.enable();
		}
	}
	selectMeForIsDefakultGrain(selecterdValueForDefaultType) {
		this.productForm.controls.default.setValue(selecterdValueForDefaultType);
		this.selectedTypeValue = selecterdValueForDefaultType;
	}

	calContentPrice() {
		let amount = 0;
		this.ProductFormArray.controls.forEach(form => {
			amount += +form.value.Price;
		});

		this.productForm.controls.totalPrice.setValue(amount.toFixed(2));
	}
	// open pop for add meta data
	openPopUpForMetaData(selectedProduct) {
		this.dialogService.open(MetaDataComponent, {
			context: {
				title: '',
				product: selectedProduct,
			},

		}).onClose.subscribe(() => {
			this.getProductList(0, 0);
		});

	}
	zeroPrevent(event) {
		if (event.charCode === 48) {
			return false;
		}
		return true;
	}
}
