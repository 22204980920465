import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NbToastrService, NbDialogRef } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { IVendor } from '../../../models';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { VendorService } from '../../../Shared/Services/vendor.service';
@Component({
	selector: 'ngx-meta-data',
	templateUrl: './meta-data.component.html',
	styleUrls: ['./meta-data.component.scss']
})
export class MetaDataComponent implements OnInit {
	title: string;
	product: any;
	prodId = 0;
	metaDataForm: FormGroup;
	@ViewChild('metaItem', { static: false }) accordion;
	submitted = false;
	constructor(
		private fb: FormBuilder,
		private toastrService: NbToastrService,
		private vednorService: VendorService,
		private dialogRef: NbDialogRef<any>,


	) {
		this.metaDataForm = this.fb.group({
			title: ['', [Validators.required]],
			keyword: ['', [Validators.required]],
			discription: ['', [Validators.required]],
		});
	}
	ngOnInit() {
		this.setValueToForm();
	}
	clearForm() {
		this.metaDataForm.reset();
		this.submitted = false;
	}

	get f() {
		return this.metaDataForm.controls;
	}

	validation() {
		this.submitted = true;
	}
	saveMetaForm() {
		const data = {
			productId: this.product.id,
			metaTittle: this.metaDataForm.value.title,
			keyword: this.metaDataForm.value.keyword,
			metaDiscription: this.metaDataForm.value.discription,

		};
		let url = null;
		if (this.metaDataForm.valid) {
			if (this.prodId === 0) {
				url = `${environment.API_URL}/api/Product/UpdateProductSeo`;
			} else {
				url = `${environment.API_URL}/api/Product/UpdateProductSeo`;
			}
			this.vednorService.modifyVendor(url, data).subscribe
				((response: ResponseModel<IVendor>) => {
					if (response.status === HttpStatusCode.OK) {
						if (this.prodId === 0) {
							this.showToast('top-right', 'success', 'Added Sucessfully');
							this.clearForm();
						} else {
							this.showToast('top-right', 'success', 'Updated Sucessfully');
							this.clearForm();

						}

					} else {
						this.showToast('top-right', 'danger', response.statusMessage);
					}
				});
		}
	}

	// for set value to w.r.s to feild
	setValueToForm() {
		this.metaDataForm.controls.title.setValue(this.product.metaTittle);
		this.metaDataForm.controls.keyword.setValue(this.product.keyword);
		this.metaDataForm.controls.discription.setValue(this.product.metaDiscription);

	}
	close() {
		this.dialogRef.close();
	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status
		});
	}

}
