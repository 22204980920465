import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { AreaService } from '../../../Shared/Services/area.service';

@Component({
  selector: 'ngx-assignarea',
  templateUrl: './assignarea.component.html',
  styleUrls: ['./assignarea.component.scss']
})
export class AssignareaComponent implements OnInit {

  title = '';
  data: any;

  areaList = [];
  disableAfterSave = false;

  constructor(
    private areaService: AreaService,
    private dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService) { }

  ngOnInit() {
    this.getAreaList();
  }

  close() {
    this.dialogRef.close();
  }

  toggle(checked: boolean, area) {
    area.assigned = checked;
  }

  getAreaList() {
    const url = `${environment.API_URL}/api/Warehouse/GetwarehouseArea?Id=${this.data}`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaList = response.data.assiggedArea;
        }
      }
      );
  }


  saveArea() {
    this.disableAfterSave = true;
    const data = {
      assiggedArea: this.areaList,
      warehouseId: this.data
    };


    const url = `${environment.API_URL}/api/Warehouse/UpdateWarehouseArea`;
    this.areaService.modifyArea(url, data).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Area Assining Successfully');
          this.disableAfterSave = true;
        } else {
          this.showToast('top-right', 'danger', 'Not Assing Area');
          this.disableAfterSave = false;

        }
        this.close();
      }
      );
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
