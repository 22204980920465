import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { GpartnerService } from '../../../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-order-status-popup',
  templateUrl: './order-status-popup.component.html',
  styleUrls: ['./order-status-popup.component.scss']
})
export class OrderStatusPopupComponent implements OnInit {
  data: any;
  option = '1';
  constructor(private dialogRef: NbDialogRef<any>,
    private gpService: GpartnerService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }
  UpdateStatus() {
    const url = `${environment.API_URL}/api/Donation/UpdateDonateOrderStatus?DonationId=${this.data.donationId}&Orderstatus=${this.option}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Donate Order Status changed');
this.close();
        }
      });
  }
  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }
}
