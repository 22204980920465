import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { IArea, IWarehouse } from '../../models';
import { IAREASTORE } from '../../models/areaStore.model';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { AreaService } from '../../Shared/Services/area.service';
import { WarehouseService } from '../../Shared/Services/warehouse.service';
import { AssignareaComponent } from '../../stock/warehouse/assignarea/assignarea.component';
import { WarehouseDeleteComponent } from '../../stock/warehouse/warehouse-delete/warehouse-delete.component';
import { AreastoreAsignareaComponent } from './areastore-asignarea/areastore-asignarea.component';

@Component({
  selector: 'ngx-area-warehouse',
  templateUrl: './area-warehouse.component.html',
  styleUrls: ['./area-warehouse.component.scss']
})
export class AreaWarehouseComponent implements OnInit {
  areaStoreForm: FormGroup;
  submitted = false;
  areaStoreId = 0;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  areStoreList = [];
  warehouseList = [];
  copyAreStoreList = [];
  CityList = [];
  pageRole: any;
  saveBtnHideForRole = true;

  @ViewChild('areastoreitem', { static: false }) accordion;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  constructor(private fb: FormBuilder,
    private warehouseService: WarehouseService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private areaService: AreaService,
    
    ) {
    this.areaStoreForm = this.fb.group({
      storeName: ['', [Validators.required]],
      contactPerson: [''],
      contactNumber: [''],
      location: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city:[0, [Validators.required]],
      warehouse:[0,Validators.required],
      isActive: [false]

    });
  }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);

    this.pageRole = role.page.find(m => m.page === 'readymatestockin');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }
    this.getAreaStoreList();
    // this.getWarehouseList();
    this.getCityList()
  }

  get f() {
    return this.areaStoreForm.controls;
  }

  getAreaStoreList() {
    // const url = `${environment.API_URL}/api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
    const url = `${environment.API_URL}/api/ReadymadeCupboard/GetReadymadeCupboardList?Pagesize=0&PageNo=0`;
    this.warehouseService
      .getWarehouseList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areStoreList = response.data.cupboard;
          this.copyAreStoreList = response.data.cupboard;
          this.config.totalItems = this.copyAreStoreList.length;
        }
      });
  }

  
  getWarehouseList(cityID) {
    // const url = `${environment.API_URL}/api/Warehouse/GetAdminWarehouseList?Pagesize=0&PageNo=0`;
    const url = `${environment.API_URL}/api/Warehouse/GetWarehouseByCityIdList?cityId=${cityID}`;
    this.warehouseList = [];
    this.warehouseService
      .getWarehouseList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.warehouseList = response.data.warehouses;
        }
      });
  }

  getCityList() {
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<IArea[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.CityList = response.data;
        }
      });
  }

  // for set value to w.r.s to feild
  editWarehouse(warehouse) {
    this.accordion.open();
    this.areaStoreId = warehouse.id;
    this.areaStoreForm.controls.storeName.setValue(warehouse.cupboardName);
    this.areaStoreForm.controls.location.setValue(warehouse.location);
    this.areaStoreForm.controls.address.setValue(warehouse.address);
    this.areaStoreForm.controls.contactPerson.setValue(warehouse.contactPerson);
    this.areaStoreForm.controls.contactNumber.setValue(warehouse.contactNo);
    this.areaStoreForm.controls.city.setValue(warehouse.cityId);
    this.getWarehouseList(warehouse.cityId);
    this.areaStoreForm.controls.warehouse.setValue(warehouse.warehouseId);
    this.areaStoreForm.controls.isActive.setValue(warehouse.isActive);

  }

  saveWarehouse() {
    this.submitted = true;
    if (this.areaStoreForm.valid) {
      const data: IAREASTORE = {
        id: this.areaStoreId,
        cupboardName: this.areaStoreForm.value.storeName,
        warehouseId:Number(this.areaStoreForm.value.warehouse) ,
        location: this.areaStoreForm.value.location,
        address: this.areaStoreForm.value.address,
        contactPerson: this.areaStoreForm.value.contactPerson,
        contactNo: this.areaStoreForm.value.contactNumber,
        cityId: Number(this.areaStoreForm.value.city),
        isActive:this.areaStoreForm.value.isActive
      };

      let api = '';
      if (this.areaStoreId === 0) {
        api = `${environment.API_URL}/api/ReadymadeCupboard/AddReadymadeCupboard`;
      } else {
        api = `${environment.API_URL}/api/ReadymadeCupboard/UpdateReadymadeCupboard`;
      }
      this.warehouseService.modifyWarehouse(api, data).subscribe(
        (response) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.areaStoreId === 0) {
              this.showToast('top-right', 'success', 'Warehouse Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Warehouse updated Successfully');
            }
            this.areaStoreId = 0;
            this.clearForm();
            this.submitted = false;
            this.accordion.close();
            this.getAreaStoreList();
          } else if(response.status === 400){
            this.showToast('top-right', 'danger',response.statusMessage);

          }
          else{
            
          }
        });
    }
  }

  clearForm() {
    this.areaStoreForm.reset();
    this.submitted = false;
    this.areaStoreId = 0;
    this.areaStoreForm.controls.isActive.setValue(false);

  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.areStoreList = this.copyAreStoreList.filter(item => {
        return String(item.warehouseName)
          .toLowerCase()
          .startsWith(str.toLowerCase().trim());
      });
    } else {
      this.areStoreList = this.copyAreStoreList;
    }
  }

  openAssignAreaDialog(wData) {
    this.dialogService
      .open(AreastoreAsignareaComponent, {
        context: {
          title: '',
          data: wData
        }
      })
      .onClose.subscribe(() => {
      });
  }


  // openWarehouseDelete(wData) {
  //   this.dialogService
  //     .open(WarehouseDeleteComponent, {
  //       context: {
  //         title: '',
  //         data: wData
  //       }
  //     })
  //     .onClose.subscribe(() => {
  //       this.getWarehouseList();
  //     });
  // }

  isNumberKey(evt) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  showToast(position, status, message) {
    this.toastrService.show(status || 'Success', `${message}`, {
      position,
      status
    });
  }

}
