import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { GpartnerService } from '../../../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-view-detilas',
  templateUrl: './view-detilas.component.html',
  styleUrls: ['./view-detilas.component.scss']
})
export class ViewDetilasComponent implements OnInit {
  userDetails: any[] = [];
  title: '';
  data: any;
  constructor(
    private dialogRef: NbDialogRef<any>,
    private gpService: GpartnerService

  ) { }

  ngOnInit() {
    this.getUserDetailsList();
  }
  close() {
    this.dialogRef.close();
  }
  getUserDetailsList() {
    const url = `${environment.API_URL}/api/User/GetUserDetail?UserId=${this.data.id}`;
    this.gpService.getGPartnerList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.userDetails = response.data;
        } else {
          this.userDetails = [];
        }
      });

  }

}
