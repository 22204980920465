import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { IAreaSubGrain, ICityModel } from '../../../models';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { AreaService } from '../../../Shared/Services/area.service';
import { GpartnerService } from '../../../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-sub-cat-area-assing',
  templateUrl: './sub-cat-area-assing.component.html',
  styleUrls: ['./sub-cat-area-assing.component.scss']
})
export class SubCatAreaAssingComponent implements OnInit {
  title: '';
  data: any;
  subCatAreaList: IAreaSubGrain[] = [];
  areaArrey = [];
  disableAfterSave = false;
  cityList: ICityModel[] = [];
  Count = 0;
  constructor(
    private areaService: AreaService,
    private dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService,
    private gpService: GpartnerService,



  ) { }

  ngOnInit() {
    this.getSubCatAreaList();
    this.getCityList();
  }
  close() {
    this.dialogRef.close();
  }

  getSubCatAreaList() {

  }

  saveArea() {
    this.disableAfterSave = true;
    const data = {
      assiggedArea: this.subCatAreaList,
      grainCategoryId: this.data.id
    };
    const url = `${environment.API_URL}/api/Product/UpdateGrainCategoryArea`;
    this.areaService.modifyArea(url, data).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Area Assining Successfully');
          this.disableAfterSave = false;
        } else {
          this.showToast('top-right', 'danger', 'Not Assing Area');
          this.disableAfterSave = false;

        }
      }
      );
  }

  // get city list
  getCityList() {
    const url1 = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
    this.gpService
      .getGPartnerList(url1)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.cityList = response.data;
          const url = `${environment.API_URL}/api/Product/GetGrainCategoryArea?GrainCategotyId=${this.data.id}`;
          this.areaService.getAreaList(url).subscribe
            // tslint:disable-next-line:no-shadowed-variable
            ((response: ResponseModel<any>) => {
              if (response.status === HttpStatusCode.OK) {
                this.subCatAreaList = response.data.assiggedArea;
                this.getCheckedCount();
              } else if (response.status === HttpStatusCode.NO_CONTENT) {
                this.subCatAreaList = [];
              }
            }
            );
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.cityList = [];
        }
      });
  }
  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

  toggle(checked: boolean, area) {
    area.assigned = checked;
    this.getCheckedCount();
  }

  clearAllArea() {
    for (let i = 0; i < this.subCatAreaList.length; i++) {
      this.subCatAreaList[i].assigned = false;
    }
  }
  getCheckedCount() {
    this.Count = 0;
    for (let i = 0; i < this.subCatAreaList.length; i++) {
      if (this.subCatAreaList[i].assigned === true) {
        this.Count = this.Count + 1;
      }
    }
  }
}
