import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { StockService } from '../../Shared/Services/stock.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { IGrain, ISubGrain } from '../../models';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { StockoutDeleteDialogComponent } from './stockout-delete-dialog/stockout-delete-dialog.component';
import { WarehouseService } from '../../Shared/Services/warehouse.service';

@Component({
	selector: 'ngx-stockout',
	templateUrl: './stockout.component.html',
	styleUrls: ['./stockout.component.scss'],
})
export class StockoutComponent implements OnInit {
	stockOutFormArray: FormArray = this.fb.array([]);
	stockOutForm: FormGroup;
	submitted = false;
	showloder1 = false;
	empList = [];
	// vendorList = [];
	warehouseList = [];

	grainList: IGrain[] = [];
	subGrainList: any[] = [];
	stockListForDriveName: any[] = [];
	stockId = 0;
	stockList = [];
	copyStockList = [];
	areaId: number;
	isSaveBtnStatus = false;
	receivedStock = false;
	isCanceled = false;

	pageRole: any;
	roleId: number;
	saveBtnHideForRole = true;
	userId: number;
	hideShowReleaseDropDown = false;
	cityList = [];
	areaListFormCity: any[] = [];
	areaCodeWiseGPList: any[];
	venderNameList: any[] = [];
	copyVenderNameList: any[] = [];
	grupByList = [];
	venstock: any;
	stockbill = [];
	vendor = [];
	subBill = [];
	// checkArray = []
	stockOutIds = [];

	pageSize = 10;
	pageNumber = 1;
	config = {
		itemsPerPage: 10,
		currentPage: 1,
		totalItems: 0,
	};
	checked = false;
	@ViewChild('stockoutitem', { static: false }) accordion;

	constructor(
		private fb: FormBuilder,
		private stockService: StockService,
		private dialogService: NbDialogService,
		private toastrService: NbToastrService,
		private warehouseService: WarehouseService,

	) {
		this.stockOutForm = this.fb.group({
			whName: ['', [Validators.required]],
			challanNo: [''],
			date: ['', [Validators.required]],
			deliveryStatus: ['0', [Validators.required]],
			isAcceptByBP: [false],
			deliveredBy: [''],
			vNo: [''],
			dName: [''],
			// area: ['', [Validators.required]],
			city: ['', [Validators.required]]
		});
	}
	keydowns() {
		return false;
	}

	ngOnInit() {
		this.getDriverNameAndVehicleNoList();
		this.getStockListCount();
		this.addstockOutForm();
		// this.getVendorList();
		this.getCityList();
		const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);

		const userInfoString = localStorage.getItem('info');
		const info = JSON.parse(userInfoString);
		this.userId = info.roleId;
		if (this.userId === 1) {
			this.hideShowReleaseDropDown = true;
		}
		// this.pageRole = role.page;
		this.pageRole = role.page.find(m => m.page === 'Stock out');
		if (this.pageRole.isWrite === false) {
			this.saveBtnHideForRole = false;
		}
	}

	get f() {
		return this.stockOutForm.controls;
	}
	selectVendorName() {
		this.submitted = true;
	}



	// getVendorList() {
	// 	const url = `${environment.API_URL}/api/GPartner/GetGPartnerList?pageSize=0&pageNumber=0`;
	// 	this.stockService
	// 		.getStockList(url)
	// 		.subscribe((response: ResponseModel<any>) => {
	// 			if (response.status === HttpStatusCode.OK) {
	// 				this.vendorList = response.data;
	// 			}
	// 		});
	// }

	getStockItemList(itemFormArray: FormArray) {
		const temItemArray = [];
		itemFormArray.controls.forEach((form: FormGroup) => {
			if (form.valid) {
				const obj = {
					grainCategoryId: +form.value.CategoryId,
					quantity: +form.value.Qty,
					price: 0,
					stockinId: +form.value.StockinId

				};
				temItemArray.push(obj);
			}
		});
		return temItemArray;
	}
	
	// selecAll(event) {
	// 	this.stockOutIds = [];
	// 	if (event.target.checked) {
	// 		for (let i = 0; i < this.stockList.length; i++) {
	// 			//this.paymentList[i].checked = true;
	// 			this.stockOutIds.push(this.stockList[i].id);
	// 		}
	// 	} else {
	// 		// for (let i = 0; i < this.stockList.length; i++) {
	// 		// 	this.paymentList[i].checked = false;
	// 		// }
	// 		this.stockOutIds = [];
	// 	}
	// }
	makeSummary(){
		this.showloder1 = true;
				const url = `${environment.API_URL}/api/ExcelReport/GetSummaryDetailsForCompany`;
		this.stockService
		  .modifyStock(url,this.stockOutIds)
		  .subscribe((response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
			  window.open(response.data, '_blank');
			  this.showloder1 = false;
			  this.checked = false;
				} else {
			  this.showloder1 = false;
			}
		  });

	}
	
	toggle(event,  stock) {
		if (event.target.checked && !this.stockOutIds.includes(stock.id)) {
			this.stockOutIds.push(stock.id);
		} else {
			for (let i = 0; i < this.stockOutIds.length; i++) {
				if (this.stockOutIds[i] === stock.id) {
					this.stockOutIds.splice(i, 1);
					break;
				}
			}
		}
		console.log(this.stockOutIds)
	}
	

	getSubGrainstock(subGrainId, itemForm, iscall) {
		const url = `${environment.API_URL
			}/api/Stock/GetGrainCategoryStock?GrainCategoryId=${+subGrainId}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					itemForm.controls.AvailableQty.setValue(response.data);
				}
			});
		if (iscall) {
			this.getvendorListByAreaGrainCategoryId(subGrainId, itemForm);
		}
	}

	validateQty(event, itemForm) {
		const qty = +itemForm.controls.Qty.value;
		const availabeqty = +itemForm.controls.AvailableQty.value;

		if (qty > availabeqty) {
			itemForm.controls.AvailableQty.setValue(availabeqty);
			event.target.style.backgroundColor = '#ffccbb';
		} else {
			event.target.style.backgroundColor = '';
		}
	}


	addstockOutForm() {
		this.stockOutFormArray.push(
			this.fb.group({
				GrainId: [0, [Validators.required]],
				CategoryId: [0, [Validators.required]],
				Qty: [0, [Validators.required]],
				AvailableQty: [0],
				VendorId: [0, [Validators.required]],
				StockinId: [0, [Validators.required]],
			})
		);
	}

	removeItem(index) {
		this.stockOutFormArray.controls.splice(index, 1);
	}

	getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr =
			year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}
	pageChange(pageNo) {
		this.config.currentPage = pageNo;
		this.getStockListCount();
	}

	filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.stockList = this.copyStockList.filter((item) => {
				const bill = String(item.billNumber).toLocaleLowerCase();
				const fName = String(item.gPartnerName).toLocaleLowerCase();
				const gpCode = String(item.gpCode).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (
					bill.includes(searchStr) ||
					fName.includes(searchStr) ||
					gpCode.includes(searchStr)
				);
			});
		} else {
			this.stockList = this.copyStockList;
		}
	}
	getStockListCount() {
		//const url = `${environment.API_URL}/api/StockOut/GPStockoutCount`;
		const url = `${environment.API_URL}/api/StockOut/WhStockoutCount`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.config.totalItems = response.data;
					this.getStockList();
				}
			});
	}
	// getVendorStockBill() {
	// 	const url = `${environment.API_URL}/api/StockOut/VendorwiseSS`;
	// 	this.stockService
	// 		.getStockList(url)
	// 		.subscribe((response: ResponseModel<any>) => {
	// 			if (response.status === HttpStatusCode.OK) {
	// 				this.venstock = response.data;
	// 				this.stockbill = this.venstock.stockbill ;
	// 				this.vendor = this.venstock.vendor;
	// 			}
	// 		});
	// 	}
	getStockList() {
		const url = `${environment.API_URL}/api/WarehouseStockIn/GetCompanyStockOutList?PageSize=${this.pageSize}&PageNo=${this.config.currentPage}`;
		// const url = `${environment.API_URL}/api/StockOut/GetOutStockList?PageSize=${this.pageSize}&PageNo=${this.config.currentPage}`;
		this.stockService.getStockList(url).subscribe((response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
				this.stockList = response.data;
				this.copyStockList = response.data;

			}
		});
	}

	openDeleteDialog(product) {
		this.accordion.close();
		this.dialogService
			.open(StockoutDeleteDialogComponent, {
				context: {
					title: '',
					data: product,
				},
			})
			.onClose.subscribe(() => {
				this.getStockList();
			});
	}

	clearForm() {
		this.stockId = 0;
		this.stockOutForm.reset();
		this.submitted = false;
		this.stockOutFormArray = this.fb.array([]);
		this.addstockOutForm();
		this.isSaveBtnStatus = false;
	}
	enbaledVaidation(deliveredstatus) {
		if (deliveredstatus === '1') {
			this.stockOutForm = this.fb.group({
				whName: [this.stockOutForm.value.whName, [Validators.required]],
				city: [this.stockOutForm.value.city, [Validators.required]],
				date: [this.stockOutForm.value.date, [Validators.required]],
				deliveryStatus: [
					this.stockOutForm.value.deliveryStatus,
					[Validators.required],
				],
				isAcceptByBP: [false],
				deliveredBy: [this.stockOutForm.value.deliveryBy, [Validators.required]],
				challanNo: [this.stockOutForm.value.challanNo],
				dName: [this.stockOutForm.value.dName],
				vNo: [this.stockOutForm.value.vNo],
			});
		} else {
			this.stockOutForm = this.fb.group({
				whName: [this.stockOutForm.value.whName, [Validators.required]],
				city: [this.stockOutForm.value.city, [Validators.required]],
				date: [this.stockOutForm.value.date, [Validators.required]],
				deliveryStatus: [
					this.stockOutForm.value.deliveryStatus,
					[Validators.required],
				],
				isAcceptByBP: [false],
				deliveredBy: [this.stockOutForm.value.deliveredBy],
				challanNo: [this.stockOutForm.value.challanNo],
				dName: [this.stockOutForm.value.dName],
				vNo: [this.stockOutForm.value.vNo],
			});
		}
	}

	editStockOut(stockoutInfo, iscopy: boolean) {
		this.accordion.open();
		this.stockId = stockoutInfo.id;
		// for copy stock
		if (iscopy) {
			this.stockId = 0;
			stockoutInfo.isAcceptByBP = false;
			stockoutInfo.deliverdBy = '';
			stockoutInfo.status = 0;
		}
		this.stockOutForm.controls.date.setValue(
			new Date(stockoutInfo.statusUpdateDate)
		);
		this.stockOutForm.controls.challanNo.setValue(stockoutInfo.billNumber);
		this.stockOutForm.controls.deliveryStatus.setValue(stockoutInfo.status);
		this.stockOutForm.controls.deliveredBy.setValue(stockoutInfo.deliverdBy);
		this.stockOutForm.controls.isAcceptByBP.setValue(stockoutInfo.isAcceptByBP);
		this.stockOutForm.controls.dName.setValue(stockoutInfo.drivername);
		this.stockOutForm.controls.vNo.setValue(stockoutInfo.vehicleNo);
		this.stockOutForm.controls.city.setValue(stockoutInfo.cityId);
		this.getWarehouseList(stockoutInfo.cityId);
		// this.getAreaListByCity(stockoutInfo.cityId);
		// this.stockOutForm.controls.area.setValue(stockoutInfo.areaId);
		// this.getBPListByAreaId(stockoutInfo.cityId);
		this.getGrainListByAreaId(stockoutInfo.cityId);
		this.stockOutForm.controls.whName.setValue(stockoutInfo.warehouseId);
		// for disable save btn
		if (stockoutInfo.status === 1 || stockoutInfo.status === 2) {
			this.isSaveBtnStatus = true;
		} else {
			this.isSaveBtnStatus = false;
		}
		if (stockoutInfo.isAcceptByWH === true) {
			this.receivedStock = true;
		} else {
			this.receivedStock = false;
		}

		while (this.stockOutFormArray.length !== 0) {
			this.stockOutFormArray.removeAt(0);
		}
		(stockoutInfo.stockInProductList as Array<any>).forEach((element) => {
			const itemForm = this.fb.group({
				GrainId: [element.grainId, [Validators.required]],
				CategoryId: [element.grainCategoryId, [Validators.required]],
				Qty: [element.quantity, [Validators.required]],
				VendorId: [element.vendorId, [Validators.required]],
				StockinId: [element.tblStockinid, [Validators.required]],
			});
			this.getSubGrainListByAreaGrainId(element.grainId, itemForm, element.grainCategoryId);
			this.getvendorListByAreaGrainCategoryId(element.grainCategoryId, itemForm, element.vendorId, element.tblStockinid);
			this.getSubGrainstock(element.grainCategoryId, itemForm, false);
			// this.getBillbyVendorList(element.vendorId, itemForm, element.tblStockinid);
			this.stockOutFormArray.push(itemForm);
		});
	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status,
		});
	}

	downloadVoucher(stock) {
		const url = `${environment.API_URL}/api/Report/GPartnerVoucher?StockoutId=${stock.id}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					window.open(response.data, '_blank');
				}
			});
	}
	// get city list
	getCityList() {
		const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.cityList = response.data;
					this.areaCodeWiseGPList = [];
				} else if (response.status === HttpStatusCode.NO_CONTENT) {
					this.cityList = [];
					this.areaCodeWiseGPList = [];

				}
			});
	}	// Get Warehouse list 
	getWarehouseList(cityID) {
		const url = `${environment.API_URL}/api/Warehouse/GetWarehouseByCityIdList?cityId=${cityID}`;
		this.warehouseService.getWarehouseList(url).subscribe(
		  (response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
			  this.warehouseList = response.data.warehouses;
			} else {
			  this.warehouseList = [];
			}
		  }
		);
	  }
	// getWarehouseList() {
	// 	// const url = `${environment.API_URL}//api/Warehouse/GetWarehouseByCityIdList?cityId=`;
	// 	const url = `${environment.API_URL}//api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
	// 	this.warehouseService.getWarehouseList(url).subscribe(
	// 	  (response: ResponseModel<any>) => {
	// 		if (response.status === HttpStatusCode.OK) {
	// 		  this.warehouseList = response.data.warehouses;
	// 		} else {
	// 		  this.warehouseList = [];
	// 		}
	// 	  }
	// 	);
	//   }

	// get area from city list
	getAreaListByCity(event) {
		const url = `${environment.API_URL
			}/api/Area/GetAreaListByCityId?CityId=${+event}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.areaListFormCity = response.data;
				} else if (response.status === HttpStatusCode.NO_CONTENT) {
					this.areaListFormCity = [];
				}
			});
	}
	// get areaCodeWise GP List
	getBPListByAreaId(event) {
		const url = `${environment.API_URL}/api/GPartner/GetGpartnerbyAreaId?AreaId=${+event}`;
		this.stockService.getStockList(url).subscribe((response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
				this.areaCodeWiseGPList = response.data;
			} else if (response.status === HttpStatusCode.NO_CONTENT) {
				this.areaCodeWiseGPList = [];
			}
		});
	}

	// get grain list area wise
	getGrainListByAreaId(event) {
		// const url = `${environment.API_URL}/api/Product/GrainListAreawise?AreaId=${+event}`;
		const url = `${environment.API_URL}/api/Product/GrainListAreawise?AreaId=0`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<IGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.grainList = response.data;
				}
			});
	}
	// get sub grain list
	onChangeAreaId(event) {
		this.areaId = +event;
	}
	getSubGrainListByAreaGrainId(GrainId, itemForm, subGrainId?: number) {
		this.subGrainList = [];
		 const url = `${environment.API_URL}/api/GrainCategory/GetGrainCategoryByGrainIdandAreaId?grainId=${GrainId}&AreaId=0`;

		// const url = `${environment.API_URL}/api/GrainCategory/GetGrainCategoryByGrainIdandAreaId?grainId=${GrainId}&AreaId=${this.areaId}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<ISubGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					itemForm['subGrainList'] = response.data;
					itemForm.controls.CategoryId.setValue(subGrainId);
				}
			});

	}


	getvendorListByAreaGrainCategoryId(GrainCategoryId, itemForm, VendorId?: number, stockinId?: number) {
		const url = `${environment.API_URL}/api/Vendor/GetGrainCategoryWiseVendorAsync?GrainCategoryId=${+GrainCategoryId}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<ISubGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.venstock = response.data;
					this.stockbill = this.venstock.stockbill;
					this.vendor = this.venstock.vendor;
					itemForm['vendor'] = this.vendor;
					itemForm.controls.VendorId.setValue(VendorId);
					this.getBillbyVendorList(VendorId, itemForm, stockinId);
				} else {
					this.showToast('top-right', 'info', 'Not get from any vendor.');
				}
			});
	}
	// get  sub grain List from grain list
	getBillbyVendorList(vendorId, itemForm, stockinId?: number) {
		if (this.stockbill.length > 0) {
			this.subBill = this.stockbill.filter(m => m.vendorId === +vendorId);
			itemForm['subBill'] = this.subBill;
			itemForm.controls.StockinId.setValue(stockinId);
		}
	}

	saveStock() {
		this.submitted = true;
		if (this.stockOutForm.valid) {
			this.isSaveBtnStatus = true;
			let url = '';
			if (this.stockOutFormArray.length > 0) {
				const data = {
					id: this.stockId,
					assignedById: 0,
					warehouseId: +this.stockOutForm.value.whName,
					billNumber: this.stockOutForm.value.challanNo,
					warehouseName:'',
					// gPartnerCode: '',
					status: +this.stockOutForm.value.deliveryStatus,
					statusUpdateDate: this.getDate(this.stockOutForm.value.date),
					documentPath: '',
					deliverdBy: this.stockOutForm.value.deliveredBy,
					totalPrice: 0,
					drivername: this.stockOutForm.value.dName,
					vehicleNo: this.stockOutForm.value.vNo,
					tax: 0,
					cityId:+this.stockOutForm.value.city,
					warehouseStockInDetail: this.getStockItemList(this.stockOutFormArray),
					// isAcceptByBP: this.stockOutForm.value.isAcceptByBP,
					// areaId: this.areaId 
				};

				if (this.stockId === 0) {
					// url = `${environment.API_URL}/api/StockOut/AddGPartnerStock`;
					url = `${environment.API_URL}/api/WarehouseStockIn/AddWarehouseStockIn`;

				} else {
					url = `${environment.API_URL}/api/WarehouseStockIn/UpdateWarehouseStockIn`;
					//url = `${environment.API_URL}/api/StockOut/UpdateGPartnerStock`;
				}
				this.stockService
					.modifyStock(url, data)
					.subscribe((response: ResponseModel<any>) => {
						if (response.status === HttpStatusCode.OK) {
							if (this.stockId === 0) {
								this.showToast('top-right', 'success', 'Added Successfully');
							} else {
								this.showToast('top-right', 'success', 'Updated Successfully');
							}
							this.stockId = 0;
							this.stockOutFormArray = this.fb.array([]);
							this.submitted = false;
							this.stockOutForm.reset();
							this.addstockOutForm();
							this.getStockList();
							this.isSaveBtnStatus = false;
							this.accordion.close();
							this.clearForm();
						}
					});
			} else {
				this.showToast('top-right', 'info', 'Select material to send');
			}
		}
	}
	getDriverNameAndVehicleNoList() {
		const url = `${environment.API_URL}/api/StockOut/GetOutStockList?PageSize=0&PageNo=0`;
		this.stockService.getStockList(url).subscribe((response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
				this.stockListForDriveName = response.data;
				if (this.stockListForDriveName.length > 0) {
					this.stockListForDriveName = this.stockListForDriveName.reduce((subStockList, sub) => {
						if (sub.drivername) {
							const index = subStockList.driverList.findIndex((data) => data.name.trim() === sub.drivername.trim());
							if (index === -1) {
								subStockList.driverList.push({ id: sub.id, name: sub.drivername });
							}
						}
						if (sub.vehicleNo) {
							const index = subStockList.vehicleNoList.findIndex((data) => data.name.trim() === sub.vehicleNo.trim());
							if (index === -1) {
								subStockList.vehicleNoList.push({ id: sub.id, name: sub.vehicleNo });
							}
						}
						return subStockList;
					}, {
						driverList: [],
						vehicleNoList: []
					});
				}
			}
		});
	}

}






