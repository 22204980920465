import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { AreaService } from '../../../Shared/Services/area.service';
import { GpartnerService } from '../../../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-city-list-dialog',
  templateUrl: './city-list-dialog.component.html',
  styleUrls: ['./city-list-dialog.component.scss']
})
export class CityListDialogComponent implements OnInit {
  title: string;
  empId: any;
  
  cityAreaFilterForm: FormGroup;
  areaArrey = [];
  asssingAreaStatus;
  disableAfterSave = false;
  submitted = false;
  Count = 0;
  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;
  zoneList = [];
  areaListFormCity: any[] = [];
  areaListFormGpId: any[] = [];
  areaList = [];
  constructor(
    private dialogRef: NbDialogRef<any>,
    private areaService: AreaService,
    private toastrService: NbToastrService,
    private fb: FormBuilder,
    private gpService: GpartnerService

  ) {
    this.cityAreaFilterForm = this.fb.group({
      city: ['', [Validators.required]],
      area: ['', [Validators.required]],

    });
  }

  ngOnInit() {
    this.getAreaListFormEMPId();
    this.getCityList();

    // this.getAreaList();
    this.getZoneList();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    // this.pageRole = role.page;
    this.pageRole = role.page.find(m => m.page === 'Delivery boy');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }
  }

  get f() {
    return this.cityAreaFilterForm.controls;
  }

  validation() {
    this.submitted = true;
  }
  close() {
    this.dialogRef.close();
  }

  getAreaList() {
    const url = `${environment.API_URL}/api/GPartner/GetGpartnerArea?Id=${this.empId}`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaList = response.data.assiggedArea;
          this.areaArrey = [];
          this.areaList.forEach((item) => {
            if (item.isAvailable === true) {
              this.areaArrey.push(+item.id);
            }
          });
        }
      }
      );
  }
  toggle(checked: boolean, city) {
    city.isAvailable = checked;
  }
  saveArea() {
    this.disableAfterSave = true;
    const tempArray = [];
    this.areaListFormCity.forEach((item) => {
      if (item.isAvailable) {
        const obj = {
          cityId: item.id,
          zoneId: item.zoneId,
          cityName: item.cityNameEng,
          assigned: true
        };
        tempArray.push(obj);
      }
    });
    const data = {
      assiggedCities: tempArray,
      adminId: this.empId
    };
    const url = `${environment.API_URL}/api/AdminUser/UpdateAdminCities`;
    this.areaService.modifyArea(url, data).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Area Assining Successfully');
          this.disableAfterSave = false;
        } else {
          this.showToast('top-right', 'danger', response.statusMessage);
          this.disableAfterSave = false;

        }
      }
      );
  }

  // get zone list
  getZoneList() {
    const url = `${environment.API_URL}/GetActiveZoneList`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.zoneList = response.data;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.zoneList = [];
        }
      });
  }
  // get city  list
  // getAreaListByCity() {
    getCityList() {
    // const url = `${environment.API_URL
    //   }/api/City/GetCityByZoneId?zoneId=${event}`;
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;

    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaListFormCity = response.data;
          this.areaMapping();
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.areaListFormCity = [];
        }
      });
  }
  // get area list from GPartner Id
  getAreaListFormEMPId() {
    const url = `${environment.API_URL}/api/AdminUser/GetAdminCitiesByAdminId?adminId=${this.empId}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaListFormGpId = response.data.assiggedCities;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.areaListFormGpId = [];
        }
      });
  }
  // Area mapping from GPId and City id
  areaMapping() {
    this.areaListFormCity.forEach((item) => {
      this.areaListFormGpId.forEach((element) => {
        if ((item.id === element.cityId)) {
          item.isAvailable = element.assigned;
        }
      });
    });
  }
  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
  // clearAllArea() {
  //   for (let i = 0; i < this.areaListFormCity.length; i++) {
  //     this.areaListFormCity[i].isAvailable = false;
  //   }
  // }
  // getCheckedCount() {
  //   this.Count = 0;
  //   for (let i = 0; i < this.areaListFormCity.length; i++) {
  //     if (this.areaListFormCity[i].isAvailable === true) {
  //       this.Count = this.Count + 1;
  //     }
  //   }
  // }
}
