import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { IWarehouse } from '../../../models';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { ReadymadeService } from '../../../Shared/Services/readymade.service';

@Component({
  selector: 'ngx-warehouse-stock',
  templateUrl: './warehouse-stock.component.html',
  styleUrls: ['./warehouse-stock.component.scss']
})
export class WarehouseStockComponent implements OnInit {
  warwhouseStockForm: FormGroup;
  cityList = [];
  warehouseList = [];
  stockList = [];
  stockListGP: IWarehouse[];
  copyStockListGP = []
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private readyMadeStockService: ReadymadeService
  ) { }

  ngOnInit() {
    this.getCityList();
  }
	getclass(ele) {
		const min = ele.minStock;
		const max = ele.capacityInQty;
		return ele.stock <= min ? 'min-limit' : ele.stock > max ? 'max-limit' : '';
	}

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.stockListGP = this.copyStockListGP.filter(item => {
        const name = String(item.product).toLocaleLowerCase();
        // const grain = String(item.grain).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr));
      });
    } else {
      this.stockListGP = this.copyStockListGP;
    }
    this.config.totalItems = this.stockListGP.length;
    this.config.currentPage = 1;
  }
  // Get city list
  getCityList() {
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
    this.readyMadeStockService
      .getStockList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.cityList = response.data;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.cityList = [];
        }
      });
      this.warehouseList = [];
  }
    // Get Warehouse list 
  getWarehouseList(cityID) {
      //   const url = `${environment.API_URL}/api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
    const url = `${environment.API_URL}/api/Warehouse/GetWarehouseByCityIdList?cityId=${cityID}`;
    this.readyMadeStockService.getStockList(url).subscribe(
      (response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.warehouseList = response.data.warehouses;
      } else {
        this.warehouseList = [];
      }
      }
    );
    this.stockListGP = [];
    this.config.totalItems = 0;

    }

  getStockList(event) {
    const url = `${environment.API_URL}/api/ReadyMateStock/ReadymateStock?warehouseId=${+event.target.value}`;
    this.readyMadeStockService
      .getStockList(url)
      .subscribe((response: ResponseModel<IWarehouse[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.stockListGP = response.data;
          this.copyStockListGP = response.data;
          this.config.totalItems = this.copyStockListGP.length;
          this.config.currentPage = 1;

        } if (response.status === 204) {
          this.stockListGP = null;

        }
      });
  }
	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

}
