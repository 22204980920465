import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { OrderService } from '../../Shared/Services/order.service';
import { IOrders, IOrderDetails } from '../../models/order.model';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { NbDialogService } from '@nebular/theme';


@Component({
  selector: 'ngx-cancelorder',
  templateUrl: './cancelorder.component.html',
  styleUrls: ['./cancelorder.component.scss']
})
export class CancelorderComponent implements OnInit {

  public orderList = [];
  copyOrderList = [];

  pageSize = 10;
  pageNumber = 1;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private dialogService: NbDialogService,
    private orderService: OrderService
  ) {

  }

  ngOnInit() {
    this.getCancleOrderList();
    this.getAllCountList();
  }
  open(order: IOrderDetails) {
    this.dialogService.open(OrderDialogComponent, {
      context: {
        title: '',
        data: {
          order: order,
          status: 10,
        }
      },
    });
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
    this.getCancleOrderList();
  }
  getAllCountList() {
    const url = `${environment.API_URL}/api/Order/StatusWiseCount`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.config.totalItems = response.data.cancel;
        }
      });
  }

  filter(str: string) {
    str = str.trim();
    if (str !== ' ')  {
      const url = `${environment.API_URL}/api/Order/GetOrderSerach?orderStatus=8&Serchstr=${str}&isdelivery=false&PageSize=0&PageNumber=0`;
      this.orderService.getOrderList(url).subscribe
        ((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.orderList = response.data;
            this.copyOrderList = response.data;
            // this.config.totalItems = response.data.count;
          } else {
            this.getCancleOrderList();          }
        });

    }
  }





  // filter(str: string) {
  //   if (str.trim() !== '') {
  //     this.orderList = this.copyOrderList.filter(item => {
  //       const order = String(item.orderDetails.orderId).toLocaleLowerCase();
  //       const fName = String(item.userInfo.firstName).toLocaleLowerCase();
  //       const lName = String(item.userInfo.lastName).toLocaleLowerCase();
  //       const area = String(item.userInfo.area).toLocaleLowerCase();


  //       const searchStr = String(str).toLowerCase();
  //       return (order.includes(searchStr)) ||
  //         (fName.includes(searchStr)) ||
  //         (lName.includes(searchStr)) ||
  //         (area.includes(searchStr));
  //     });

  //   } else {
  //     this.orderList = this.copyOrderList;
  //   }
  // }

  // get order list
  getCancleOrderList() {
    const url = `${environment.API_URL}/api/Order/GetCancelOrderAdmin?PageSize=${this.pageSize}&PageNumber=${this.config.currentPage}`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderList = response.data;
          this.copyOrderList = response.data;
        //  this.config.totalItems = this.orderList.length;
        }
      });
  }

}
