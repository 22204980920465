import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { StockInComponent } from './stock-in/stock-in.component';
import { StockoutComponent } from './stockout/stockout.component';
import { CompanyStockComponent } from './company-stock/company-stock.component';
import { GpartnerStockComponent } from './gpartner-stock/gpartner-stock.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { ReadymadeStockinComponent } from './readymade-stockin/readymade-stockin.component';
import { WarehouseStockComponent } from './warehouse/warehouse-stock/warehouse-stock.component';
import { StockComponent } from './stock/stock.component';
import { CodComponent } from './cod/cod.component';
import { ReturnStockComponent } from './return-stock/return-stock.component';
import { WarehouseStockInComponent } from './warehouse-stock-in/warehouse-stock-in.component';
import { WarehouseStockOutComponent } from './warehouse-stock-out/warehouse-stock-out.component';
import { WarehouseStockDetailComponent } from './warehouse-stock-detail/warehouse-stock-detail.component';
import { WhStockReturnComponent } from './wh-stock-return/wh-stock-return.component';
import { WhStockCapacityComponent } from './wh-stock-capacity/wh-stock-capacity.component';
import { AreastoreStockCapacityComponent } from './areastore-stock-capacity/areastore-stock-capacity.component';
import { AreastoreStockComponent } from './areastore-stock/areastore-stock.component';

const routes: Routes = [
  {
    path: '',
    component: StockComponent,
    children: [
      {
        path: 'stockin',
        component: StockInComponent
      },
      {
        path: 'stockout',
        component: StockoutComponent
      },
      {
        path: 'companyStock',
        component: CompanyStockComponent
      },
      {
        path: 'gpartnerStock',
        component: GpartnerStockComponent
      },
      {
        path: 'warehouse',
        component: WarehouseComponent
      },
      {
        path: 'rstockin',
        component: ReadymadeStockinComponent
      },
      {
        path: 'warehouseStock',
        component: WarehouseStockComponent
      },
      {
        path: 'areastoreStock',
        component: AreastoreStockComponent
      },
      {
        path: 'cod',
        component: CodComponent
      },
      {
        path: 'return_stock',
        component: ReturnStockComponent
      },
      {
        path:'wh_stock_in',
        component:WarehouseStockInComponent
      },
      {
        path:'wh_stock_out',
        component:WarehouseStockOutComponent
      },
      {
        path:'wh_stock_detail',
        component:WarehouseStockDetailComponent
      },
      {
        path:'wh_stock_return',
        component:WhStockReturnComponent
      },
      {
        path:'wh_stock_capacity/:WhId',
        component:WhStockCapacityComponent
      },
      {
        path:'as_stock_capacity/:asId',
        component:AreastoreStockCapacityComponent
      }

    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StockRoutingModule {
}
