import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { BlogService } from '../../../Shared/Services/blog.service';

@Component({
  selector: 'ngx-delete-blog-dilog',
  templateUrl: './delete-blog-dilog.component.html',
  styleUrls: ['./delete-blog-dilog.component.scss']
})
export class DeleteBlogDilogComponent implements OnInit {
  title: string;
  id: number;
  constructor(
    private dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService,
    private blogService: BlogService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
  deleteBlog() {
    const url = `${environment.API_URL}/api/Blog/DeleteBlogs?Id=${this.id}`;
    this.blogService.delete(url, this.id).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success');
          this.close();
        }
      }
    );
  }


  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }
}
