import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // noContentType = { headers: new HttpHeaders({ noContentType: 'True' }) };
  noContentType = { headers: new HttpHeaders({ noContentType: 'True' }) };
  constructor(private http: HttpClient) { }

  // get(url: string): Observable<any> {
  //   // this.checkTokenExpiration();
  //   const headers = AppGlobal.getHeaders();
  //   return this.http
  //     .get(url, { headers: headers })
  //     .pipe(map(res => <any>res));
  // }


  // post(url: string, model: any) {
  //   const body = JSON.stringify(model);
  //   const headers = AppGlobal.getHeaders();
  //   return this.http
  //     .post(url, body)
  //     .pipe(map((response: Response) => <any>response));
  // }
  private _refreshpage$ = new Subject<void>();

  get refreshpage$() {
    return this._refreshpage$;
  }

  get(url: string): Observable<any> {
    return this.http.get(url);
  }

  post(url: string, model: any): Observable<any> {
    const body = JSON.stringify(model);
    return this.http.post(url, body);

  }


  delete(url: string, model: any): Observable<any> {
    // const body = JSON.stringify(model);
    return this.http.delete(url);

  }

  put(url: string, model: any) {
    const body = JSON.stringify(model);
    //  const headers = AppGlobal.getHeaders();
    return this.http.put(url, body)
      .pipe(map((response: Response) => <any>response));
  }



  // postEmail(url: string, model: any) {
  //   return this.http
  //     .post(url, model, this.noContentType)
  //     .pipe(map((response: Response) => <any>response));
  // }
  postEmail(url, model): Observable<any> {
    return this.http.post(url, model, this.noContentType).pipe(map((response: Response) => response as any));
  }

  // public async toBase64(file: File): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = error => reject(error);
  //   });
  // }
  public async toBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  postData(url: string, model: any) {
    return this.http
      .post(url, model, this.noContentType)
      .pipe(map((response: Response) => <any>response));
  }



  UploadExcel(url, fromdata): Observable<any> {
    return this.http.post(url, fromdata, this.noContentType).pipe(map((response: Response) => response as any));
  }




  // checkTokenExpiration() {
  //   if (localStorage.getItem('userinfo')) {
  //     const token = this.encrService.decrypt(localStorage.getItem('userinfo'));
  //     const expTime = new Date(token.expiration);

  //     const currentTime = new Date(Date.now());

  //     if (currentTime > expTime) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }
  private IdSource = new BehaviorSubject(0);
  currentId = this.IdSource.asObservable();

  changeId(Id: number) {

    this.IdSource.next(Id);
  }
}
