import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BlogListComponent } from './blog-list/blog-list.component';
import { CommentsComponent } from './comments/comments.component';
import { BlogsComponent } from './blogs.component';

const routes: Routes = [
    {
        path: '',
        component: BlogsComponent,
        children: [
            // {
            //     path: '',
            //     redirectTo: 'blogs',
            //     pathMatch: 'full'
            // },
            {
                path: 'blog',
                component: BlogListComponent
            },
            {
                path: 'comments',
                component: CommentsComponent
            },
        ]
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BlogsRoutingModule {
}
