import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlogService {


  constructor(private http: HttpClient) { }

  getBlogList(url): Observable<any> {
    return this.http.get(url).pipe(map((response: Response) => response as any));
  }

  modifyBlog(url, blogInfo): Observable<any> {
    return this.http.post(url, blogInfo).pipe(map((response: Response) => response as any));
  }

  delete(url, data): Observable<any> {
    return this.http.post(url , data ).pipe(map((response: Response) => response as any));
  }

}
