import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetSetService {

  constructor() { }

  private isSaveSucessFully = new BehaviorSubject(false);
  selectedFlagValue = this.isSaveSucessFully.asObservable();

  callToFormChildComponent(isSaveSucessFully: any) {
    this.isSaveSucessFully.next(isSaveSucessFully);
  }
}

