import { NbMenuItem } from '@nebular/theme';
// import { OnInit } from '@angular/core';
// import { MenuService } from './Shared/Services/menu.service';

export const MENU_ITEMS: NbMenuItem[] = [];

export const MENU_ITEMS_CustomeMenu: NbMenuItem[] =
	[
		{
			title: 'Dashboard',
			icon: 'layout-outline',
			link: '/dashboard/fpdashboard',
			hidden: false
		},
		{
			title: 'FEATURES',
			group: true,
		},
		{
			title: 'Masters',
			icon: 'layout-outline',
			children: [
				{
					title: 'City',
					link: '/masters/city',
				},
				{
					title: 'Area',
					link: '/masters/area/0',
				},
				{
					title: 'Area rate board',
					link: '/masters/area-rate-board/0/0/0',
				},
				{
					title: 'Grain',
					link: '/masters/grain',
				},
				{
					title: 'Sub grain',
					link: '/masters/subgrain',
				},
				{
					title: 'Product',
					link: '/product/productform',
				},
				{
					title: 'Delivery slot',
					link: '/masters/delivery-slot',
				},

				{
					title: 'Vendor',
					link: '/masters/vendor-form',
				},
				{
					title: 'Salary grade',
					link: '/masters/salary-grad',
				},
				{
					title: 'Business value factor',
					link: '/masters/setting',
				},
				{
					title: 'Promo Code',
					link: '/masters/promocode',
				},
				{
					title: ' Banner',
					link: '/product/banner',
				},
				{
					title: 'Roles',
					link: '/masters/role',
				},
				{
					title: 'Zone',
					link: '/masters/zone',
				},
				{
					title: 'Rights',
					link: '/masters/rights',
				  }
			],
		},
		{
			title: 'User',
			icon: 'layout-outline',
			children: [
				{
					title: 'Flourpicker user ',
					link: '/product/fk-user',
				},
				{
					title: 'COD',
					link: '/stock/cod',
				},
				{
					title: 'Applied business partner ',
					link: '/product/applied',
				},
				{
					title: 'Business partner',
					link: '/product/businessPartner/0',
				},
				{
					title: 'Business partner rate board',
					link: '/masters/bp-rate-board/0/0',
				},
				{
					title: 'Business partner stock capacity',
					link: '/masters/gp-stock-capacity/0',
				},
				{
					title: 'Employee',
					link: '/masters/employee',
				},
				{
					title: 'Delivery boy',
					link: '/product/deliveryboy',
				},
			],
		},
		{
			title: 'Inventory',
			icon: 'layout-outline',
			children: [
				{
					title: 'Stock in',
					link: '/stock/stockin',
				},
				{
					title: 'Stock out',
					link: '/stock/stockout',
				},
				{
					title: 'Return stock',
					link: '/stock/return_stock',
				},
				{
					title: 'Company stock',
					link: '/stock/companyStock',
				},
				{
					title:'Area-Store Stock',
					link:'/stock/areastoreStock'
				},
				{
					title: 'Business partner stock',
					link: '/stock/gpartnerStock',
				},
				{
					title: 'Warehouse',
					link: '/stock/warehouse',
				},
				{
					title: 'Readymade stock in',
					link: '/stock/rstockin',
				},

			],
		},
		{
			title: 'Customer care',
			icon: 'layout-outline',
			children: [
				{
					title: 'Tickets',
					link: '/care/tickets',
				},
				{
					title: 'Add tickets',
					link: '/care/add-tickets',
				},
			],
		},
		{
			title: 'Release payment',
			icon: 'layout-outline',
			children: [

				{
					title: 'Business rartner',
					link: '/payment/rel-bp-payment',
				},
				{
					title: 'Delivery  boy',
					link: '/payment/rel-db-payment',
				},
			],
		},

		{
			title: 'Other list',
			icon: 'layout-outline',
			children: [
				{
					title: 'Recipe',
					link: '/masters/recipe',
				},
				{
					title: 'Rating',
					link: '/masters/rating',
				},
				{
					title: 'Review',
					link: '/masters/review',
				},
				{
					title: 'Help & Support',
					link: '/masters/help',
				},
			],
		},

		{
			title: 'Reports',
			icon: 'layout-outline',
			children: [
				{
					title: 'Reports',
					link: '/masters/report',
				},

			],
		}
	];

