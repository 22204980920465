import { Component, OnInit } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { ComplainService } from '../../Shared/Services/complain.service';
import { IComplain } from '../../models/complain.model';

import { RefundComponent } from '../refund/refund.component';
import { TicketDialogComponent } from './dialog/ticket-dialog.component';

@Component({
  selector: 'ngx-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  statusZero: any[];
  statusOne: any[];
  statusZeroCopy: any[];
  statusOneCopy: any[];
  statusTwo: any[];
  statusTwoCopy: any[];
  statusThree: any[];
  statusThreeCopy: any[];
  openList: any[];
  copyopenList: any[];
  deActiveopenList: any[];
  notOpenList: any[];
  copyNotOpenList: any[];
  productDetails: any[];
  user = 0;
  userId = 0;
  activeDeactiveBtn = false;
  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };
  config1 = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };
  config2 = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };
  config3 = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };


  constructor(
    private toastrService: NbToastrService,
    private complainService: ComplainService,
    private dialogService: NbDialogService

  ) { }

  ngOnInit() {
    this.newComplain();
    this.inProesss();
    this.reSolve();
    this.close();

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Add tickets');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }

  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }
  pageChange1(pageNo) {
    this.config1.currentPage = pageNo;
  }
   pageChange2(pageNo) {
    this.config2.currentPage = pageNo;
  } 
  pageChange3(pageNo) {
    this.config3.currentPage = pageNo;
  }

  openAssingDialog(comp: IComplain) {
    this.dialogService.open(TicketDialogComponent, {
      context: {
        title: '',
        data: comp,
      }
    })
      .onClose.subscribe(() => {
        this.newComplain();
        this.inProesss();
        this.reSolve();
        this.close();
      });
  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.statusThree = this.statusThreeCopy.filter(item => {
        const name = String(item.firstName + ' ' + item.lastName).toLocaleLowerCase();
        const complaintCode = String(item.complaintCode).toLocaleLowerCase();
        const orderNo = String(item.orderNo).toLocaleLowerCase();


        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr)) || (complaintCode.includes(searchStr)) || (orderNo.includes(searchStr));
      });
    } else {
      this.statusThree = this.statusThreeCopy;
    }
  }
  filter0(str: string) {
    str = str.trim();
    if (str !== '') {
      this.statusZero = this.statusZeroCopy.filter(item => {
        const name = String(item.firstName + ' ' + item.lastName).toLocaleLowerCase();
        const complaintCode = String(item.complaintCode).toLocaleLowerCase();
        const orderNo = String(item.orderNo).toLocaleLowerCase();


        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr)) || (complaintCode.includes(searchStr)) || (orderNo.includes(searchStr));
      });
    } else {
      this.statusTwo = this.statusZeroCopy;
    }
  }
  filter1(str: string) {
    str = str.trim();
    if (str !== '') {
      this.statusTwo = this.statusTwoCopy.filter(item => {
        const name = String(item.firstName + ' ' + item.lastName).toLocaleLowerCase();
        const complaintCode = String(item.complaintCode).toLocaleLowerCase();
        const orderNo = String(item.orderNo).toLocaleLowerCase();


        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr)) || (complaintCode.includes(searchStr)) || (orderNo.includes(searchStr));
      });
    } else {
      this.statusTwo = this.statusTwoCopy;
    }
  }
  filter2(str: string) {
    str = str.trim();
    if (str !== '') {
      this.statusOne = this.statusOneCopy.filter(item => {
        const name = String(item.firstName + ' ' + item.lastName).toLocaleLowerCase();
        const complaintCode = String(item.complaintCode).toLocaleLowerCase();
        const orderNo = String(item.orderNo).toLocaleLowerCase();


        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr)) || (complaintCode.includes(searchStr)) || (orderNo.includes(searchStr));
      });
    } else {
      this.statusOne = this.statusOneCopy;
    }
  }

  // status wise order complaint list
  newComplain() {
    const url = `${environment.API_URL}/api/Complaint/GetComplaintsByStatus?status=0&PageSize=0&PageNo=0`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.statusZero = response.data;
        this.statusZeroCopy = response.data;
        this.config.totalItems = this.statusZeroCopy.length;
      } else {
        this.statusZero = [];
      }
    });
  }

  inProesss() {
    const url = `${environment.API_URL}/api/Complaint/GetComplaintsByStatus?status=1&PageSize=0&PageNo=0`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.statusOne = response.data;
        this.statusOneCopy = response.data;
          this.config1.totalItems = this.statusOneCopy.length;
      } else {
        this.statusOne = [];
      }
    });
  }

  reSolve() {
    const url = `${environment.API_URL}/api/Complaint/GetComplaintsByStatus?status=2&PageSize=0&PageNo=0`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.statusTwo = response.data;
        this.statusTwoCopy = response.data;
        this.config2.totalItems = this.statusTwoCopy.length;

      } else {
        this.statusTwo = [];
      }
    });
  }

  close() {
    const url = `${environment.API_URL}/api/Complaint/GetComplaintsByStatus?status=3&PageSize=0&PageNo=0`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.statusThree = response.data;
        this.statusThreeCopy = response.data;
        this.config3.totalItems = this.statusThreeCopy.length;

      } else {
        this.statusThree = [];
      }
    });
  }

  refundPopUp(statusThree: IComplain) {
    this.dialogService.open(RefundComponent, {
      context: {
        title: '',
        data: statusThree,
      },

    })
      .onClose.subscribe(() => {
        this.newComplain();
        this.inProesss();
        this.reSolve();
        this.close();
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}
