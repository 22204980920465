import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StockService } from '../../Shared/Services/stock.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IGrain } from '../../models/grain.model';
import { ISubGrain } from '../../models/subGrain.model';
import { IStock, IStockDetailModel } from '../../models/index';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { StockinDeleteDialogComponent } from './stockin-delete-dialog/stockin-delete-dialog.component';

@Component({
	selector: 'ngx-stock-in',
	templateUrl: './stock-in.component.html',
	styleUrls: ['./stock-in.component.scss']
})
export class StockInComponent implements OnInit {
	stockInFormArray: FormArray = this.fb.array([]);
	stockInForm: FormGroup;
	submitted = false;
	empList = [];
	vendorList = [];
	grainList: IGrain[] = [];
	subGrainList: any[] = [];
	stockId = 0;
	file: any;
	imgPreview = '';

	isSaveDisabled = false;

	stockList = [];
	copyStockList = [];


	@ViewChild('stockinitem', { static: false }) accordion;
	fileInfo = '';
	pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

	config = {
		itemsPerPage: 10,
		currentPage: 1,
		totalItems: 0
	};

	constructor(
		private fb: FormBuilder,
		private stockService: StockService,
		private dialogService: NbDialogService,
		private toastrService: NbToastrService
	) {
		this.stockInForm = this.fb.group({
			vendorName: ['', [Validators.required]],
			billNo: ['', [Validators.required]],
			date: ['', [Validators.required]],
			amount: ['', [Validators.required]],
			tax: ['', [Validators.required]],
			percentage: ['', [Validators.required]],
			totalAmount: ['', [Validators.required]],
			description: ['']
		});
	}

	ngOnInit() {
		const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		// this.pageRole = role.page;
		this.pageRole = role.page.find(m => m.page === 'Stock in');

		if (this.pageRole.isWrite === false) {
		  this.saveBtnHideForRole = false;
		}
		this.addStockInForm();
		this.getVendorList();
		this.getGrainList();
		this.getStockList();
	}

	get f() {
		return this.stockInForm.controls;
	}
	selectVendorName() {
		this.submitted = true;
	}
	clearForm() {
		this.stockInForm.reset();
		this.stockId = 0;
		this.stockInFormArray = this.fb.array([]);
		this.addStockInForm();
		this.fileInfo = '';
		this.imgPreview = '';
		this.submitted = false;
	}


	onFileSelect(input: HTMLInputElement): void {
		function formatBytes(bytes: number): string {
			const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const factor = 1024;
			let index = 0;
			while (bytes >= factor) {
				bytes /= factor;
				index++;
			}
			return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
		}
		this.file = input.files[0];
		this.uploadFile(this.file);
		this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
	}

	uploadFile(file) {
		const files = file;
		const fileReader = new FileReader();
		(fileReader.onload = () => {
			this.imgPreview = fileReader.result.toString();
		}),
			fileReader.readAsDataURL(files);
	}

	getVendorList() {
		const url = `${environment.API_URL}/api/Vendor/GetVendorList`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.vendorList = response.data;
				}
			});
	}

	getStockItemList(itemFormArray: FormArray) {
		const temItemArray = [];
		itemFormArray.controls.forEach((form: FormGroup) => {
			if (form.valid) {
				const obj: IStockDetailModel = {
					GrainCategoryId: +form.value.CategoryId,
					Quantity: +form.value.Qty,
					PricePerUnit: +form.value.Perprice,
					Id: 0,
					StockId: 0,
				};
				temItemArray.push(obj);
			}
		});
		return temItemArray;
	}

	getGrainList() {
		const url = `${environment.API_URL}/api/Grain/GetGrainList`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<IGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.grainList = response.data;
				}
			});
	}

	getSubGrainList(GrainId, itemForm, subGrainId?: number) {
		this.subGrainList = [];
		const url = `${
			environment.API_URL
			}/api/GrainCategory/GetGrainCategoryByGrainId?grainId=${+GrainId}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<ISubGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					itemForm['subGrainList'] = response.data;
					itemForm.controls.CategoryId.setValue(subGrainId);
				}
			});
	}

	getSubGrainValue(subGrain, subGrainList: Array<any>, itemForm: FormGroup) {
		subGrainList.forEach(item => {

		});
	}

	addStockInForm() {
		this.stockInFormArray.push(
			this.fb.group({
				GrainId: [],
				CategoryId: [],
				Qty: [],
				Perprice: [],
				total: []
			})
		);
	}

	removeItem(index) {
		this.stockInFormArray.controls.splice(index, 1);

		let amount = 0;
		this.stockInFormArray.controls.forEach(form => {
			amount += form.value.total;
		});

		this.stockInForm.controls.amount.setValue(amount);
	}

	getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr = year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}

	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

	filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.stockList = this.copyStockList.filter(item => {
				// return String(item.vendor)
				// 	.toLowerCase()
				// 	.startsWith(str.toLowerCase().trim());
				const vendor = String(item.vendor).toLocaleLowerCase();
				const stockInChallanNo = String(item.stockInChallanNo).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (vendor.includes(searchStr) || (stockInChallanNo.includes(searchStr)));	
			});
		} else {
			this.stockList = this.copyStockList;
		}
		this.config.totalItems = this.stockList.length;
	}

	getStockList() {
		const url = `${environment.API_URL}/api/Stock/GetStockList`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.stockList = response.data;
					this.copyStockList = response.data;
					this.config.totalItems = this.stockList.length;
				}
			});
	}

	openDeleteDialog(stock: IStock) {
		this.dialogService
			.open(StockinDeleteDialogComponent, {
				context: {
					title: '',
					data: stock
				}
			})
			.onClose.subscribe(() => {
				this.getStockList();
			});
	}

	saveStock() {
		this.submitted = true;
		const data: IStock = {
			id: this.stockId,
			vendorId: +this.stockInForm.value.vendorName,
			date: this.getDate(this.stockInForm.value.date),
			amount: +this.stockInForm.value.amount,
			taxAmount: +this.stockInForm.value.tax,
			percentage: +this.stockInForm.value.percentage,
			billNo: this.stockInForm.value.billNo,
			documentPath: '',
			documentBase64: this.imgPreview !== '' ? this.imgPreview.split(',')[1] : '',
			stockDetail: this.getStockItemList(this.stockInFormArray),
			totalPrice: this.stockInForm.value.totalAmount,
			discription: this.stockInForm.value.description
		};

		if (this.stockInForm.valid) {
			this.isSaveDisabled = true;
			let apiurl = '';
			if (this.stockId === 0) {
				apiurl = `${environment.API_URL}/api/Stock/AddStock`;
			} else {
				apiurl = `${environment.API_URL}/api/Stock/UpdateStock`;
			}
			this.stockService
				.modifyStock(apiurl, data)
				.subscribe((response: ResponseModel<any>) => {
					if (response.status === HttpStatusCode.OK) {
						if (this.stockId === 0) {
							this.showToast('top-right', 'success', 'Added Successfully');
						} else {
							this.showToast('top-right', 'success', 'Updated Successfully');
						}

						this.stockId = 0;
						this.stockInFormArray = this.fb.array([]);
						this.submitted = false;
						this.stockInForm.reset();
						this.addStockInForm();
						this.getStockList();
						this.accordion.close();
						this.isSaveDisabled = false;
					}
				});
		}
	}

	editStockIn(stock: IStock) {
		this.accordion.open();
		this.stockId = stock.id;
		this.stockInForm.controls.vendorName.setValue(stock.vendorId);
		this.stockInForm.controls.date.setValue(new Date(stock.date));
		this.stockInForm.controls.billNo.setValue(stock.billNo);
		this.stockInForm.controls.amount.setValue(stock.amount);
		this.stockInForm.controls.tax.setValue(stock.taxAmount);
		this.stockInForm.controls.percentage.setValue(stock.percentage);
		this.stockInForm.controls.totalAmount.setValue(stock.totalPrice);
		this.stockInForm.controls.description.setValue(stock.discription);

		while (this.stockInFormArray.length !== 0) {
			this.stockInFormArray.removeAt(0);
		}

		(stock.stockDetail as Array<any>).forEach(element => {
			const itemForm = this.fb.group({
				GrainId: [element.grainId, [Validators.required]],
				CategoryId: [element.grainCategoryId, [Validators.required]],
				Qty: [element.quantity, [Validators.required]],
				Perprice: [element.pricePerUnit, [Validators.required]],
				total: [element.quantity * element.pricePerUnit, [Validators.required]]
			});
			this.getSubGrainList(element.grainId, itemForm, element.grainCategoryId);
			this.stockInFormArray.push(itemForm);
		});
	}

	getTotal(itemForm: FormGroup) {

		const price = (itemForm.value.Perprice) ? (itemForm.value.Perprice) : 0;
		const total = price * ((itemForm.value.Qty) ? (itemForm.value.Qty) : 0);
		itemForm.controls.total.setValue(total);

		let amount = 0;
		this.stockInFormArray.controls.forEach(form => {
			amount += form.value.total;
		});

		const taxprice = (this.stockInForm.value.tax) ? (this.stockInForm.value.tax) : 0;
		this.stockInForm.controls.percentage.setValue(taxprice.toFixed(2));
		this.stockInForm.controls.amount.setValue(amount);
		this.stockInForm.controls.totalAmount.setValue(amount + taxprice);

		const totalamount = this.stockInForm.value.amount;
		const percentage = (+taxprice / totalamount) * 100;

		this.stockInForm.controls.percentage.setValue(percentage.toFixed(2));
	}


	calculatePercentage(taxAmount) {
		const amount = this.stockInForm.value.amount;
		const percentage = (+taxAmount / amount) * 100;
		this.stockInForm.controls.percentage.setValue(percentage.toFixed(2));
		this.stockInForm.controls.totalAmount.setValue(amount + +taxAmount);
	}

	calculatetaxAmount(percentage) {
		const amount = this.stockInForm.value.amount;
		const taxAmount = (percentage / 100) * amount;
		this.stockInForm.controls.tax.setValue(taxAmount);
		this.stockInForm.controls.totalAmount.setValue(amount + taxAmount);
	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status
		});
	}
}
