import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-gp-weekly-holiday',
  templateUrl: './gp-weekly-holiday.component.html',
  styleUrls: ['./gp-weekly-holiday.component.scss']
})
export class GpWeeklyHolidayComponent implements OnInit , AfterViewInit {
  holidayform: FormGroup;
  HolidayList = [];
  submitted = false;
  GPartnerHolidayList = [];
  CityList = [];
  Id = 0;
  active = false;
  checked = false;
  pageRole: any;
  isSaveDisabled = false;
  saveBtnHide = false;
  rolesaveBtnHide = true;
	@ViewChild('banneritem', { static: false }) accordion;

  constructor(private gpartnerService: GpartnerService,
     private fb: FormBuilder, private toastrService: NbToastrService) {
    this.holidayform = this.fb.group({
      cityId:['',[Validators.required]],
      date: ['', [Validators.required]],
      name: ['', [Validators.required]],
      isactive: [false],
      isallcity:[0]
    });
  }
  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.getHolidayList();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
     this.pageRole = role.page.find(m => m.page === 'holiday');
    if (this.pageRole.isWrite === false || this.pageRole.isRead === false) {
      this.rolesaveBtnHide = false;
    }
    this.getCityList();
  }

  get f() {
    return this.holidayform.controls;
  }
  allCity(e){
    this.checked = e; 
    if(e){
      this.holidayform.get('cityId').disable();
    }else{
      this.holidayform.get('cityId').enable();
    }
  }
  // get City List 
  getCityList() {
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
    this.gpartnerService.getGPartnerList(url).subscribe
      ((response: ResponseModel<any[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.CityList = response.data;
        }
      });
  }
  getHolidayList() {
    const url = `${environment.API_URL}/api/Holiday/GetHoliday?pageSize=0&pageNumber=0`;
    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.HolidayList = response.data.holidays;
        }
      });
  }
  getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr =
			year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}
  clearForm() {
    this.Id = 0;
    this.holidayform.get('cityId').enable();
    this.holidayform.reset();
  }
  saveHoliday() {
    this.submitted = true;
    const data = {
      id: this.Id,
      cityId: this.checked ? -999: +this.holidayform.value.cityId,
      date: this.getDate(this.holidayform.value.date),
      name: this.holidayform.value.name,
      isActive : this.holidayform.value.isactive
      // isActive: this.holidayform.value.isactive === 0 ? false : true,
    };
    if (this.holidayform.valid) {
      this.isSaveDisabled = true;

		let url = '';
		if (this.Id === 0) {
			url = `${environment.API_URL}/api/Holiday/AddHoliday`;
		} else {
		url = `${environment.API_URL}/api/Holiday/UpdateHoliday`;
		}
    this.gpartnerService.modifyGPartner(url, data).subscribe(
      (response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.Id === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
              this.getHolidayList();
              this.accordion.close();
              this.submitted = false;
              this.isSaveDisabled = false;
              this.Id = 0;
              this.clearForm();
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
              this.getHolidayList();
              this.accordion.close();
              this.submitted = false;
              this.isSaveDisabled = false;
              this.Id = 0;
              this.clearForm();
            }

          } else {
            this.showToast('top-right', 'danger', response.statusMessage);
            this.isSaveDisabled = false;
            this.saveBtnHide = false;
          }
        });
      }
  }
	editHoliday(holiday) {
		this.accordion.open();
		this.Id = holiday.id;
		this.holidayform.controls.name.setValue(holiday.name);
    this.holidayform.controls.date.setValue(new Date(holiday.date));
		this.holidayform.controls.isactive.setValue(holiday.isActive);
    if(holiday.cityId == -999){
      this.checked = true;
      this.holidayform.get('cityId').disable();
      this.holidayform.controls.cityId.setValue(0);
    }else{
      this.holidayform.get('cityId').enable();
      this.checked = false;
      this.holidayform.controls.cityId.setValue(holiday.cityId);
    }

// this.active = holiday.isActive ? true : false;

	}

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
