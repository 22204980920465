import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IOrders } from '../../models/order.model';
import { OrderService } from '../../Shared/Services/order.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ngx-fpdashboard',
  templateUrl: './fpdashboard.component.html',
  styleUrls: ['./fpdashboard.component.scss']
})
export class FpdashboardComponent implements OnInit, OnDestroy {
  countAll: any;
  loader = false;
  userInfo: any;
  pageRole: any;
  roleId: number;

  public data: any;

  intervalId: any;
  tokenRefresh: any;

  NewOrder: boolean = true;
  AccpetedOrder: boolean = true;
  OrderInProgess: boolean = true;
  PassedOrderbyBusinessPartner: boolean = true;
  OrderCompletedbyBusinessPartner: boolean = true;
  PassedOrderbyDeliveryBoy: boolean = true;
  PickUpOrderList: boolean = true;
  OrderComplitedbyDeliveryBoy: boolean = true;
  CancleOrder: boolean = true;

  constructor(
    private orderService: OrderService
  ) { }

  ngOnInit() {

    const infostr = (localStorage.getItem('info'));
    const info = JSON.parse(infostr);
    this.userInfo = info;

    this.getAllCountList(true);
    this.intervalId = setInterval(() => {
      this.getAllCountList(false);

    }, 12000);

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'NewOrder');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.NewOrder = false;
    }

    this.pageRole = role.page.find(m => m.page === 'AccpetedOrder');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.AccpetedOrder = false;
    }

    this.pageRole = role.page.find(m => m.page === 'OrderInProgess');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.OrderInProgess = false;
    }

    this.pageRole = role.page.find(m => m.page === 'PassedOrderbyBusinessPartner');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.PassedOrderbyBusinessPartner = false;
    }

    this.pageRole = role.page.find(m => m.page === 'PassedOrderbyDeliveryBoy');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.PassedOrderbyDeliveryBoy = false;
    }

    this.pageRole = role.page.find(m => m.page === 'PickUpOrderList');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.PickUpOrderList = false;
    }
    this.pageRole = role.page.find(m => m.page === 'OrderComplitedbyDeliveryBoy');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.OrderComplitedbyDeliveryBoy = false;
    }

    this.pageRole = role.page.find(m => m.page === 'CancleOrder');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.CancleOrder = false;
    }
    this.pageRole = role.page.find(m => m.page === 'OrderCompletedbyBusinessPartner');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.OrderCompletedbyBusinessPartner = false;
    }
  }

  //  get refresh  token
  refreshToken(userInfo) {
    const url = `${environment.API_URL}/api/Auth/RefreshToken?token=${userInfo.refreshToken}`;
    this.orderService.getOrderList(url).pipe(take(1)).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.data = response.data;
          localStorage.setItem('info', JSON.stringify(response.data));
        }
      });
  }

  // get order count
  getAllCountList(showLoader: boolean) {
    this.loader = showLoader;
    const url = `${environment.API_URL}/api/Order/StatusWiseCount`;
    this.orderService.getOrderList(url).pipe(take(1)).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.countAll = response.data;
          this.loader = false;
        }
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
