import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DonationsComponent } from './donations.component';
import { DonorOrderComponent } from './donor-order/donor-order.component';
import { DonorComponent } from './donor/donor.component';
import { CanteenComponent } from './canteen/canteen.component';

const routes: Routes = [
    {
        path: '',
        component: DonationsComponent,
        children: [
            {
                path: 'canteen',
                component: CanteenComponent
            },
            {
                path: 'donor',
                component: DonorComponent
            },
            {
                path: 'donor_order',
                component: DonorOrderComponent
            }
        ]
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BlogsRoutingModule {
}
