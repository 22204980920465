import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbMenuModule, NbInputModule, NbCardModule,
  NbButtonModule, NbActionsModule, NbUserModule, NbCheckboxModule,
  NbRadioModule, NbDatepickerModule, NbSelectModule, NbIconModule,
  NbLayoutModule, NbAccordionModule, NbTooltipModule, NbCalendarModule, NbSpinnerModule, NbTabsetModule,
} from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';

import { BlogListComponent } from './blog-list/blog-list.component';
import { CommentsComponent } from './comments/comments.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlogsRoutingModule } from './blogs-routing.module';
import { BlogsComponent } from './blogs.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DeleteBlogDilogComponent } from './blog-list/delete-blog-dilog/delete-blog-dilog.component';
import { DeleteCommentsComponent } from './comments/delete-comments/delete-comments.component';

@NgModule({
  declarations: [
    BlogsComponent,
    BlogListComponent,
    CommentsComponent,
    DeleteBlogDilogComponent,
    DeleteCommentsComponent],
  imports: [
    AngularEditorModule,
    NbTabsetModule,
    NbSpinnerModule,
    CommonModule,
    BlogsRoutingModule,
    FormsModule,
    NbAccordionModule,
    ReactiveFormsModule,
    NbMenuModule,
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbCalendarModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    Ng2SmartTableModule,
    NbMenuModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NgxPaginationModule,
    NbTooltipModule,
    NbLayoutModule,
    NbSpinnerModule,


  ],
  providers: [],
  entryComponents: [
    DeleteBlogDilogComponent,
    DeleteCommentsComponent
  ]

})
export class BlogsModule { }
