import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { RoleService } from '../../Shared/Services/role.service';

@Component({
  selector: 'ngx-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
  @ViewChild('roleitem', { static: false }) accordion;

  roleForm: FormGroup;
  roleList = [];
  submitted = false;
  Id = 0;
  pageRole: any;
  isSaveDisabled = false;
  saveBtnHide = false;
  rolesaveBtnHide = true;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private fb: FormBuilder,
    private roleService:RoleService,
    private toastrService: NbToastrService) {
    this.roleForm = this.fb.group({
      name: ['', [Validators.required]],
      isActive: [false]
    })
  }

  ngOnInit() {
     this.getRoleList();
  }
  getRoleList() {
    const url = `${environment.API_URL}/api/Role/GetRoleList`;
    this.roleService.getRoleList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.roleList = response.data;
          this.config.totalItems = this.roleList.length;

        }
      });
  }
  pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

  clearForm() {
    this.Id = 0;
    this.roleForm.reset();
  }

  saveRole() {
    this.submitted = true;
    const data = {
      id: this.Id,
      name: this.roleForm.value.name,
      isActive : this.roleForm.value.isActive
    };
    if (this.roleForm.valid) {
      this.isSaveDisabled = true;

		let url = '';
		if (this.Id === 0) {
			url = `${environment.API_URL}/api/Role/AddRole`;
		} else {
		url = `${environment.API_URL}/api/Role/UpdateRole`;
		}
    this.roleService.modifyRole(url, data).subscribe(
      (response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.Id === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
              this.getRoleList();
              this.accordion.close();
              this.submitted = false;
              this.isSaveDisabled = false;
              this.Id = 0;
              this.clearForm();
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
              this.getRoleList();
              this.accordion.close();
              this.submitted = false;
              this.isSaveDisabled = false;
              this.Id = 0;
              this.clearForm();
            }

          } else {
            this.showToast('top-right', 'danger', response.statusMessage);
            this.isSaveDisabled = false;
            this.saveBtnHide = false;
          }
        });
      }
  }
	editRole(role) {
		this.accordion.open();
		this.Id = role.id;
		this.roleForm.controls.name.setValue(role.name);
		this. roleForm.controls.isActive.setValue(role.isActive);
	}

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }


}
