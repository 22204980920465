import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { OrderService } from '../../Shared/Services/order.service';
import { IOrders, IOrderDetails } from '../../models/order.model';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { NbDialogService } from '@nebular/theme';
import { AssingGpComponent } from '../pass-order-gp/assing-gp/assing-gp.component';

@Component({
  selector: 'ngx-current-running-order',
  templateUrl: './current-running-order.component.html',
  styleUrls: ['./current-running-order.component.scss']
})
export class CurrentRunningOrderComponent implements OnInit {



  @ViewChild('orderItem', { static: false }) accordion;

  public orderList = [];
  copyOrderList = [];
  pageRole: any;
  isReadyMadeTrueFalseFlag = false;

  NewOrder = false;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private dialogService: NbDialogService,
    private orderService: OrderService
  ) {

  }

  ngOnInit() {
    this.getOrderList();
    this.isReadyMadeTrueFalse(this.orderList);

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'NewOrder');
    if (this.pageRole.isWrite) {
      this.NewOrder = true;
    }

  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  downloadInvoice(order: any) {
    const apiurl = environment.API_URL​​;
    const url = `${apiurl}/api/Report/invoice?OrderId=${order.orderDetails.id}`;
    this.orderService.getOrderList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank'); }
      });
  }



  open(order: IOrderDetails) {
    this.dialogService.open(OrderDialogComponent, {
      context: {
        title: '',
        data: {
          order: order,
          status: 11,
        }
      },
    });
  }

  // open pop for order assing to GB
  openPopUpForAssing(order: IOrderDetails) {
    this.dialogService.open(AssingGpComponent, {
      context: {
        title: '',
        data: order,
      },

    }).onClose.subscribe(() => {
      this.getOrderList();
    });

  }

  filter(str: string) {
    if (str.trim() !== '') {
      this.orderList = this.copyOrderList.filter(item => {
        const order = String(item.orderDetails.orderId).toLocaleLowerCase();
        const fName = String(item.userInfo.firstName).toLocaleLowerCase();
        const lName = String(item.userInfo.lastName).toLocaleLowerCase();
        const area = String(item.userInfo.area).toLocaleLowerCase();


        const searchStr = String(str).toLowerCase();
        return (order.includes(searchStr)) ||
          (fName.includes(searchStr)) ||
          (lName.includes(searchStr)) || (area.includes(searchStr));
      });

    } else {
      this.orderList = this.copyOrderList;
    }
  }
  // get order list
  getOrderList() {
    const url = `${environment.API_URL}/api/Order/GetNewOrderAdmin?PageSize=0&PageNumber=0`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderList = response.data;
          this.copyOrderList = response.data;
          this.config.totalItems = this.orderList.length;

        }
      });
  }
  isReadyMadeTrueFalse(order) {
     const ord = order.orderDetails.product.find(ob => ob.typeId > 3);
     if (ord) {
      return true;
      } else {
        return  false;
      }
  }
}
