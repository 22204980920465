import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { VendorService } from '../../Shared/Services/vendor.service';
import { IVendor } from '../../models/vendor.model';
import { VendorDeleteDialogComponent } from './vendor-delete-dialog/vendor-delete-dialog.component';
import { NbToastrService, NbDialogService } from '@nebular/theme';

@Component({
	selector: 'ngx-vendor-form',
	templateUrl: './vendor-form.component.html',
	styleUrls: ['./vendor-form.component.scss']
})
export class VendorFormComponent implements OnInit {
	vendorForm: FormGroup;
	id = 0;
	@ViewChild('vendorItem', { static: false }) accordion;

	submitted = false;
	mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
	// codeFormat = '[A-Z0-9]{6}';
	allVendorList: IVendor[] = [];
	copyVendorList: IVendor[] = [];
	isUpdate = false;
	isDisable = false;

	vendorCodeAutoGen: any;
	pageRole: any;
    roleId: number;
    saveBtnHideForRole = true ;

	config = {
		itemsPerPage: 10,
		currentPage: 1,
		totalItems: 0
	};

	clearForm() {
		this.id = 0;
		this.updateVendorCode();
		this.vendorForm.reset();
		this.submitted = false;
	}


	constructor(
		private fb: FormBuilder,
		private vednorService: VendorService,
		private toastrService: NbToastrService,
		private dialogService: NbDialogService
	) {
		this.vendorForm = this.fb.group({
			vendorName: ['', [Validators.required]],
			vendorCode: [''],
			ownerContactNo: ['', [Validators.required]],
			address: ['', [Validators.required]],
			contactPersonMobNo: ['', [Validators.required]],
			contactPersonName: ['', [Validators.required]],
			ownerName: ['', [Validators.required]],
			active: [''],
		});
	}

	selectVendor() {
		this.submitted = true;
	}
	ngOnInit() {
		this.getAllVendorList();
		const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Vendor');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }

	}

	get f() {
		return this.vendorForm.controls;
	}

	validation() {
		this.submitted = true;
	}

	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

	filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.allVendorList = this.copyVendorList.filter(item => {
				const name = String(item.vendorName).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (name.includes(searchStr));
			});
		} else {
			this.allVendorList = this.copyVendorList;
		}
	}

	getAllVendorList() {
		const url = `${environment.API_URL}/api/Vendor/GetAllVendorList`;
		this.vednorService
			.getVendorList(url)
			.subscribe((response: ResponseModel<IVendor[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.allVendorList = response.data;
					this.copyVendorList = response.data;
					this.config.totalItems = this.allVendorList.length;
					this.updateVendorCode();
				}
			});
	}
	// for set value to w.r.s to feild
	setValueToForm(vendor) {
		this.accordion.open();
		this.isUpdate = true;
		this.id = vendor.id;
		this.vendorForm.controls.vendorName.setValue(vendor.vendorName);
		this.vendorForm.controls.address.setValue(vendor.address);
		this.vendorForm.controls.contactPersonMobNo.setValue(vendor.contactPersonMobNo);
		this.vendorForm.controls.ownerContactNo.setValue(vendor.ownerContactNo);
		this.vendorForm.controls.contactPersonName.setValue(vendor.contactPersonName);
		this.vendorForm.controls.ownerName.setValue(vendor.ownerName);
		this.vendorForm.controls.active.setValue(vendor.active);
		this.vendorCodeAutoGen = (vendor.vendorCode);

	}

	// add and update vendor
	addVendor() {
		const data: IVendor = {
			id: this.id,
			vendorName: this.vendorForm.value.vendorName,
			vendorCode: this.vendorCodeAutoGen,
			address: this.vendorForm.value.address,
			contactPersonName: this.vendorForm.value.contactPersonName,
			ownerContactNo: this.vendorForm.value.ownerContactNo,
			contactPersonMobNo: this.vendorForm.value.contactPersonMobNo,
			ownerName: this.vendorForm.value.ownerName,
			// active:this.vendorForm.value.active,
			active:
				this.vendorForm.value.active && this.vendorForm.value.active !== null
					? this.vendorForm.value.active
					: false,
		};
		let url = null;
		if (this.vendorForm.valid) {
			this.isDisable = true;
			if (this.id === 0) {
				url = `${environment.API_URL}/api/Vendor/AddVendor`;
			} else {
				url = `${environment.API_URL}/api/Vendor/UpdateVendor`;
			}
			this.vednorService.modifyVendor(url, data).subscribe
				((response: ResponseModel<IVendor>) => {
					if (response.status === HttpStatusCode.OK) {
						if (this.id === 0) {
							this.showToast('top-right', 'success', 'Added Sucessfully');
						} else {
							this.showToast('top-right', 'success', 'Updated Sucessfully');
						}
						this.isDisable = false;

					} else {
						this.showToast('top-right', 'danger', response.statusMessage);
						this.isUpdate = false;
						this.isDisable = false;
					}
					this.vendorForm.reset();
					this.submitted = false;
					this.accordion.close();
					this.getAllVendorList();
					this.id = 0;
				});
		}
	}
	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status
		});
	}
	openDeleteDialog(vendor: IVendor) {
		this.dialogService
			.open(VendorDeleteDialogComponent, {
				context: {
					title: '',
					data: vendor
				}
			})
			.onClose.subscribe(() => {
				this.getAllVendorList();
			});
	}

	updateVendorCode() {
		if (this.id === 0) {
			if (this.allVendorList) {
				if (this.allVendorList.length > 0) {
					this.vendorCodeAutoGen = 'FPV-0' + (this.allVendorList.length + 1).toString();
				} else if (this.allVendorList == null || this.allVendorList.length === 0) {
					this.vendorCodeAutoGen = 'FPV-01';
				}
			}
		}
	}
}
