import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) { }

  getStockList(url): Observable<any> {
    return this.http.get(url).pipe(map((response: Response) => response as any));
  }

  delete(url, data): Observable<any> {
    return this.http.post(url , data ).pipe(map((response: Response) => response as any));
  }

  modifyStock(url, stockInfo): Observable<any> {
      return this.http.post(url, stockInfo).pipe(map((response: Response) => response as any));
  }
}
