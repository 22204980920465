import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { IGrain, IGPStockCapacity, ICity, IRateBoard, IWHStockCapacity } from '../../models';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { CapacityForGpService } from '../../Shared/Services/capacityForGp.service';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { GrainService } from '../../Shared/Services/grain.service';
import { WarehouseService } from '../../Shared/Services/warehouse.service';

@Component({
  selector: 'ngx-wh-stock-capacity',
  templateUrl: './wh-stock-capacity.component.html',
  styleUrls: ['./wh-stock-capacity.component.scss']
})
export class WhStockCapacityComponent implements OnInit {
  @ViewChild('whStockCapacity', { static: false }) accordion;
  warehouseList = [];
  GranList: IGrain[];
  StockCapacityList = [];
  selectedWhId = 0;
  selectedGrainId = 0;
  whGrainList: IWHStockCapacity[] = [];
  disabledBtn = false;
  pageRole: any;
  roleId: number;
  saveBtnHide = true;

  constructor(private gpartnerService: GpartnerService,
    private toastrService: NbToastrService,
    private capacityForGpService: CapacityForGpService,
    private grainService: GrainService,
    private whService: WarehouseService,

    private activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    // this.pageRole = role.page;
    this.pageRole = role.page.find(m => m.page === 'Business partner stock capacity');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHide = false;
    }

    this.selectedWhId = +this.activatedroute.snapshot.paramMap.get('WhId');

    if (this.selectedWhId > 0) {
      this.selectedGrainId = 0;
    }

    this.getWarehouseList();
    this.getGrainList();
    this.getWHStockCapacityList();
  }

  //  Get Warehouse list 
  getWarehouseList() {
    const url = `${environment.API_URL}/api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
    this.whService.getWarehouseList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.warehouseList = response.data.warehouses;
        } else {
          this.warehouseList = [];
        }
      }
    );
  }
  // get Grain List
  getGrainList() {
    const url = `${environment.API_URL}/api/Grain/GetGrainList?pageSize=0&pageNumber=0`;
    this.grainService.getGrainList(url).subscribe
      ((response: ResponseModel<IGrain[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.GranList = response.data;
        }
      });
  }

  whOnChange(event) {
    this.getWHStockCapacityList();
    this.getWHStockCapacity(this.selectedGrainId);
  }

  grainOnChange(event) {
    this.selectedGrainId = event.target.value;
    this.getWHStockCapacity(this.selectedGrainId);
  }

  editCapacityGrain(item) {
    this.StockCapacityList = item.grainCategories;
    this.selectedGrainId = item.grainId;
  }

  // get WH Stock Capacity  list
  getWHStockCapacity(selectedGrainId) {
    const url = `${environment.API_URL}/api/WhStockCapacity/GetWhStockCapacity?GrainId=${selectedGrainId}&WHId=${this.selectedWhId}`;

    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<IRateBoard[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.StockCapacityList = response.data['grainCategories'];
        }
      });
  }

  getWHStockCapacityList() {
    const url = `${environment.API_URL}/api/WhStockCapacity/GetWhStockCapacityList?whId=${this.selectedWhId}`;
    this.whGrainList = [];
    this.capacityForGpService.getCapacityList(url).subscribe(
      (response: ResponseModel<IWHStockCapacity[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.whGrainList = response.data;
        }
      });
  }

  // update and add
  saveStockCapacity() {
    this.disabledBtn = true;
    const formData: IWHStockCapacity = {
      whId: +this.selectedWhId,
      grainId: +this.selectedGrainId,
      grain: '',
      grainCategories: this.StockCapacityList,
    };
    // const url = `${environment.API_URL}/api/GpstockCapacity/SaveGpstockCapacity`;
    const url = `${environment.API_URL}/api/WhStockCapacity/SaveWhStockCapacity`;
    this.capacityForGpService.modifyCapacityForGp(url, formData).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Save Successfully');
          this.getWHStockCapacityList();
          this.disabledBtn = false;

        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}
