import { Component, OnInit } from '@angular/core';
import { MENU_ITEMS } from '../fp-menu-item';
import { MenuService } from '../Shared/Services/menu.service';

@Component({
  selector: 'ngx-blogs',
  templateUrl: './blogs.component.html',
  // styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {
  menu = MENU_ITEMS;

  constructor(
    private menuService: MenuService
  ) { }
  ngOnInit() {
    this.menu = this.menuService.setMenu();
  }
}
