import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { IReturn, IWHReturn } from '../../models/returnStock.model';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { StockService } from '../../Shared/Services/stock.service';
import { SubGrainService } from '../../Shared/Services/sub-grain.service';
import { WarehouseService } from '../../Shared/Services/warehouse.service';

@Component({
  selector: 'ngx-wh-stock-return',
  templateUrl: './wh-stock-return.component.html',
  styleUrls: ['./wh-stock-return.component.scss']
})
export class WhStockReturnComponent implements OnInit {
	returnStockFormArray: FormArray = this.fb.array([]);
	returnStockForm: FormGroup;
	submitted = false;
	warehouseList = [];
	CopywhList: any[] = [];
	GrainList: any[] = [];
	allSubGrainList: any;
	returnStockId = 0;
	empList = [];
	vendorList = [];
	grainList: any[] = [];
	BillList: any[] = [];
	filterBillList: any[] = [];
	CopyBillList: any[] = [];
	subGrainList: any[] = [];
	stockListForDriveName: any[] = [];
	returnStockList = [];
	copyreturnStockList = [];
	areaId: number;
	isSaveBtnStatus = false;
	receivedStock = false;
	isCanceled = false;
	pageRole: any;
	roleId: number;
	saveBtnHideForRole = true;
	hideShowReleaseDropDown = false;

	showComDiv = false;
	returnchno: string;
	pageSize = 10;
	pageNumber = 1;
	config = {
		itemsPerPage: 10,
		currentPage: 1,
		totalItems: 0,
	};
	returnTypeList = [
		{
			id: 1,
			name: 'Return'
		},
		{
			id: 2,
			name: 'Loss '
		},
		{
			id: 3,
			name: 'Sample'
		}
	];

	@ViewChild('whstockreturnitem', { static: false }) accordion;
	constructor(
		private fb: FormBuilder,
		private stockService: StockService,
		private toastrService: NbToastrService,
		private subGrainService: SubGrainService,
		private whService:WarehouseService

	) {

		this.returnStockForm = this.fb.group({
			whName: ['', [Validators.required]],
			isCompany: [false],
			date: ['', [Validators.required]],
			discription: ['', [Validators.required]],
			pickupStatus: [1, [Validators.required]],
			pickedBy: [''],
			executedby: [''],
			approvedBy: [''],
			refReturnChallanNo: [''],
			dName: ['', [Validators.required]],
			vehicleNo: ['', [Validators.required]]

		});
	}
	keydowns() {
		return false;
	}

	ngOnInit() {
		this.getWarehouseList();

		this.getReturnStockList();
		this.getAdminempList();
		this.addreturnStockForm();

		this.returnStockForm.controls.bpName.setValidators([Validators.required]);
		this.returnStockForm.controls.pickupStatus.setValidators([Validators.required]);
		this.returnStockForm.controls.pickedBy.setValidators([Validators.required]);
		this.returnStockForm.controls.dName.setValidators([Validators.required]);
		this.returnStockForm.controls.vehicleNo.setValidators([Validators.required]);

	}

	get f() {
		return this.returnStockForm.controls;
	}

	//  Get Warehouse list 
	getWarehouseList() {
		const url = `${environment.API_URL}/api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
		this.whService.getWarehouseList(url).subscribe(
		  (response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
			  this.warehouseList = response.data.warehouses;
			} else {
			  this.warehouseList = [];
			}
		  }
		);
	  }
	
	// // get Grain Partner list
	// getGPList() {
	// 	const url = `${environment.API_URL}/api/GPartner/GetGPartnerList?pageSize=0&pageNumber=0`;
	// 	this.stockService
	// 		.getStockList(url)
	// 		.subscribe((response: ResponseModel<any[]>) => {
	// 			if (response.status === HttpStatusCode.OK) {
	// 				this.gpList = response.data;
	// 				this.CopygpList = response.data;
	// 			}
	// 		});
	// }
	// get all grian list
	getGrainList(WHId) {
		const url = `${environment.API_URL}/api/ReturnWHStock/GetWarehouseStockGrain?warehouseId=${+WHId}`;
		//const url = `${environment.API_URL}/api/ReturnStock/GetGPartnerStockGrain?GpartnerId=${+GpartnerId}`;
		this.subGrainService.getSubGrainList(url).subscribe
			((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.allSubGrainList = response.data;
					this.GrainList = this.allSubGrainList.grains;
					this.CopyBillList = this.allSubGrainList.stockbills;
				} if (response.status === 204) {
					this.allSubGrainList = null;
					this.allSubGrainList = [];
					this.showToast('top-right', 'success', 'No Stock out for this Warehouse');

				}
			});
	}
filterbilllist() {
if (this.filterBillList.length > 5 ) {
	for ( let _i = 0; _i < 5; _i++) {

		this.BillList.push(this.filterBillList[_i]);
	  }
	} else {
		this.BillList = this.filterBillList;
	}

}

	// get  sub grain List from grain list
	getSubGrainList(GrainId, itemForm, subGrainId?: number) {
		this.subGrainList = [];
		if (this.GrainList.length > 0) {
			const subGrain = this.GrainList.find(m => m.grainId === +GrainId);
			// this.subGrainList = subGrain.stockoutGrainCates;
			this.subGrainList = subGrain.stockGrainCates
			itemForm['subGrainList'] = this.subGrainList;
			itemForm.controls.CategoryId.setValue(subGrainId);
		}

	}
// get bill list
getbill(subGrainId, itemForm, StockOutid?: number) {
		if (this.CopyBillList.length > 0) {
			this.filterBillList = this.CopyBillList.filter(m => m.grainCategotyId === +subGrainId);
			this.filterbilllist() ;
			itemForm['BillList'] = this.BillList;
			itemForm.controls.challanno.setValue(StockOutid);
				}

	}
	// get return stock list
	getReturnStockList() {
		const url = `${environment.API_URL}/api/ReturnWHStock/ReturnWHStockList?PageSize=0&PageNo=0`;
		//const url = `${environment.API_URL}/api/ReturnStock/ReturnStockList?PageSize=0&PageNo=0`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.returnStockList = response.data.whStocks;
					this.copyreturnStockList = response.data.whStocks;
					this.getReturnChallan();
					this.getDriverNameAndVehicleNoList();
				}
			});
	}
	// get Admin emp list
	getAdminempList() {
		const url = `${environment.API_URL
			}/api/AdminUser/AdminUserList`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.empList = response.data;
				}
			});
	}

	getReturnChallan() {
		this.returnchno = `RETCHA-FP-${this.padLeadingZeros(this.copyreturnStockList.length + 1, 3)}`;
	}
	padLeadingZeros(num, size) {
		let s = num + '';
		while (s.length < size) s = '0' + s;
		return s;
	}
	addreturnStockForm() {
		if (this.showComDiv) {
			this.returnStockFormArray.push(
				this.fb.group({
					GrainId: ['', [Validators.required]],
					CategoryId: ['', [Validators.required]],
					Qty: ['', [Validators.required]],
					returnType: ['', [Validators.required]],
					challanno: [''],
				})
			);
		} else {
			this.returnStockFormArray.push(
				this.fb.group({
					GrainId: ['', [Validators.required]],
					CategoryId: ['', [Validators.required]],
					Qty: ['', [Validators.required]],
					returnType: ['', [Validators.required]],
					challanno: ['', [Validators.required]],
				})
			);
		}
	}

	removeItem(index) {
		this.returnStockFormArray.controls.splice(index, 1);
	}

	getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr =
			year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}
	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

	filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.returnStockList = this.copyreturnStockList.filter((item) => {
				const returnChallanNo = String(item.returnChallanNo).toLocaleLowerCase();
				const warehouse = String(item.warehouseName).toLocaleLowerCase();
				// const gpCode = String(item.gpCode).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (
					returnChallanNo.includes(searchStr) ||
					warehouse.includes(searchStr)
				);
			});
		} else {
			this.returnStockList = this.copyreturnStockList;
		}
	}
	clearForm() {
		this.returnStockId = 0;
		this.returnStockForm.reset();
		this.submitted = false;
		this.returnStockFormArray = this.fb.array([]);
		this.addreturnStockForm();
		this.isSaveBtnStatus = false;
		this.getReturnChallan();
	}



	editStockOut(stockoutInfo) {
		this.accordion.open();
		this.returnchno = stockoutInfo.returnChallanNo;
		this.returnStockId = stockoutInfo.id;

		this.returnStockForm.controls.date.setValue(
			new Date(stockoutInfo.date)
		);
		if (stockoutInfo.warehouseId !== 0) {
			this.showComDiv = false;
			this.warehouseList = this.CopywhList;
			this.getGrainList(stockoutInfo.warehouseId);
			this.returnStockForm.controls.whName.setValue(stockoutInfo.warehouseId);
			this.returnStockForm.controls.pickedBy.setValue(stockoutInfo.pickedby);
			this.returnStockForm.controls.dName.setValue(stockoutInfo.drivername);
			this.returnStockForm.controls.vehicleNo.setValue(stockoutInfo.vehicleNo);
			this.returnStockForm.controls.pickupStatus.setValue(stockoutInfo.status);
			this.returnStockForm.controls.isCompany.setValue(false);
		} else {
			this.showComDiv = true;
			this.warehouseList = [];
			this.getGrainList(0);
			this.returnStockForm.controls.isCompany.setValue(true);
			this.returnStockForm.controls.executedby.setValue(stockoutInfo.executedby);
			this.returnStockForm.controls.refReturnChallanNo.setValue(stockoutInfo.refRetChallanNo);
			this.returnStockForm.controls.approvedBy.setValue(stockoutInfo.approvedBy);

		}
		// this.returnStockForm.controls.date.setValue(stockoutInfo.date);
		this.returnStockForm.controls.discription.setValue(stockoutInfo.discription);
		while (this.returnStockFormArray.length !== 0) {
			this.returnStockFormArray.removeAt(0);
		}
		(stockoutInfo.returnWHStockDetail as Array<any>).forEach((element) => {
			const itemForm = this.fb.group({
				GrainId: [element.grainId, [Validators.required]],
				CategoryId: [element.grainCategoryId, [Validators.required]],
				Qty: [element.quantity, [Validators.required]],
				returnType: [element.returnType, [Validators.required]],
				challanno: [element.stockOutId],
			});
			this.getSubGrainList(element.grainId, itemForm, element.grainCategoryId);
			this.getbill(element.grainCategoryId, itemForm, element.stockOutId);
			this.returnStockFormArray.push(itemForm);
		});
	}
	isNumber(evt) {
		evt = (evt) ? evt : window.event;
		const charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	formValidate(isCompany: boolean) {
		if (isCompany) {
			this.returnStockForm = this.fb.group({
				whName: [this.returnStockForm.value.whName],
				isCompany: [isCompany],
				date: [this.returnStockForm.value.date, [Validators.required]],
				discription: [this.returnStockForm.value.discription, [Validators.required]],
				pickupStatus: [this.returnStockForm.value.pickupStatus],
				pickedBy: [this.returnStockForm.value.pickedBy],
				executedby: [this.returnStockForm.value.executedby, [Validators.required]],
				approvedBy: [this.returnStockForm.value.approvedBy, [Validators.required]],
				refReturnChallanNo: [this.returnStockForm.value.refReturnChallanNo],
				dName: [this.returnStockForm.value.dName],
				vehicleNo: [this.returnStockForm.value.vehicleNo]
			});

		} else {
			this.returnStockForm = this.fb.group({
				whName: [this.returnStockForm.value.whName, [Validators.required]],
				isCompany: [isCompany],
				date: [this.returnStockForm.value.date, [Validators.required]],
				discription: [this.returnStockForm.value.discription, [Validators.required]],
				pickupStatus: ['1', [Validators.required]],
				pickedBy: [this.returnStockForm.value.pickedBy, [Validators.required]],
				executedby: [this.returnStockForm.value.executedby],
				approvedBy: [this.returnStockForm.value.approvedBy],
				refReturnChallanNo: [this.returnStockForm.value.refReturnChallanNo],
				dName: [this.returnStockForm.value.dName, [Validators.required]],
				vehicleNo: [this.returnStockForm.value.vehicleNo, [Validators.required]]
			});
		}

	}
	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status,
		});
	}

	toggleCompany(checked: boolean) {

		this.submitted = false;

		this.showComDiv = checked;

		this.formValidate(this.showComDiv);

		if (this.showComDiv) {
			this.warehouseList = [];
			this.getGrainList(0);
		} else {

			this.warehouseList = this.CopywhList;
		}

		// if (this.showComDiv) {
			if (this.returnStockFormArray.controls.length > 0) {
				this.returnStockFormArray.controls.forEach((form: FormGroup) => {
					form.controls.challanno.setValue(this.showComDiv ? '0' : null);
				});
			}
	}

	saveStock() {
		this.submitted = true;
		if (this.returnStockForm.valid) {
			let url = '';
			let WHId = 0;
			this.showComDiv = this.returnStockForm.value.isCompany;
			if (!this.showComDiv) {
				WHId = +this.returnStockForm.value.whName;
			}
			if (this.returnStockFormArray.length > 0) {
				const data: IWHReturn = {
					id: this.returnStockId,
					warehouseId: WHId,
					status: WHId === 0 ? 2 : +this.returnStockForm.value.pickupStatus,
					discription: this.returnStockForm.value.discription,
					refRetChallanNo: this.returnStockForm.value.refReturnChallanNo,
					executedBy: +this.returnStockForm.value.executedby,
					approvedBy: +this.returnStockForm.value.approvedBy,
					pickedBy: this.returnStockForm.value.pickedBy,
					driverName: this.returnStockForm.value.dName,
					vehicleNo: this.returnStockForm.value.vehicleNo,
					date: this.getDate(this.returnStockForm.value.date),
					whStockDetail: this.getStockItemList(this.returnStockFormArray),
				};
				this.isSaveBtnStatus = true;
				if (this.returnStockId === 0) {
					url = `${environment.API_URL}/api/ReturnWHStock/AddReturnWHStock`;
				} else {
					url = `${environment.API_URL}/api/ReturnWHStock/UpdateReturnWHStock`;
				}
				this.stockService
					.modifyStock(url, data)
					.subscribe((response: ResponseModel<any>) => {
						if (response.status === HttpStatusCode.OK) {
							if (this.returnStockId === 0) {
								this.showToast('top-right', 'success', 'Added Successfully');
							} else {
								this.showToast('top-right', 'success', 'Updated Successfully');
							}
							this.returnStockId = 0;
							this.returnStockFormArray = this.fb.array([]);
							this.submitted = false;
							this.returnStockForm.reset();
							this.addreturnStockForm();
							this.isSaveBtnStatus = false;
							this.accordion.close();
							this.clearForm();
							this.getReturnStockList();
							this.warehouseList = this.CopywhList;
						}
					});
			} else {
				this.showToast('top-right', 'info', 'Select material to send');
				this.isSaveBtnStatus = false;
			}
			this.isSaveBtnStatus = false;
		}
	}

	getStockItemList(itemFormArray: FormArray) {
		const temItemArray = [];
		itemFormArray.controls.forEach((form: FormGroup) => {
			if (form.valid) {
				const obj = {
					grainCategoryId: +form.value.CategoryId,
					quantity: +form.value.Qty,
					returnType: +form.value.returnType,
					whStockOutId: + form.value.challanno
				};
				temItemArray.push(obj);
			}
		});
		return temItemArray;
	}

	getDriverNameAndVehicleNoList() {
		if (this.copyreturnStockList.length > 0) {
			this.stockListForDriveName = this.copyreturnStockList.reduce((subStockList, sub) => {
				if (sub.drivername) {
					const index = subStockList.driverList.findIndex((data) => data.name.trim() === sub.drivername.trim());
					if (index === -1) {
						subStockList.driverList.push({ id: sub.id, name: sub.drivername });
					}
				}
				if (sub.vehicleNo) {
					const index = subStockList.vehicleNoList.findIndex((data) => data.name.trim() === sub.vehicleNo.trim());
					if (index === -1) {
						subStockList.vehicleNoList.push({ id: sub.id, name: sub.vehicleNo });
					}
				}
				return subStockList;
			}, {
				driverList: [],
				vehicleNoList: []
			});
		}
	}
}