import { Component, OnInit } from '@angular/core';
import { IStock } from '../../../models';
// import { StockService } from '../../../Shared/Services/stock.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { StockService } from '../../../Shared/Services/stock.service';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
// import { environment } from '../../../../environments/environment';
// import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

@Component({
  selector: 'ngx-stockin-delete-dialog',
  templateUrl: './stockin-delete-dialog.component.html',
  styleUrls: ['./stockin-delete-dialog.component.scss']
})
export class StockinDeleteDialogComponent implements OnInit {

  title: string;
  data: IStock;
  constructor(private dialogRef: NbDialogRef<any>,
     private stockService: StockService,
    private toastrService: NbToastrService) { }

  ngOnInit() {

  }
  
  deleteStockIn() {
		const url = `${environment.API_URL}/api/Stock/DeleteStock?stockid=${this.data.id}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success','Deleted Successfully');
				this.close();
				}else{
          this.close();
          this.showToast('top-right', 'success','something went wrong!');
        }
			});
	}

  close() {
    this.dialogRef.close();
  }



  showToast(position, status,message) {
    this.toastrService.show(
      status || 'Success',
      message,
      { position, status });
  }

}
