import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GrainService } from '../../Shared/Services/grain.service';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { environment } from '../../../environments/environment';
import { IGrain } from '../../models/grain.model';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { GrainDeleteDialogComponent } from './grain-delete-dialog/grain-delete-dialog.component';
declare var $: any;
@Component({
  selector: 'ngx-grain',
  templateUrl: './grain.component.html',
  styleUrls: ['./grain.component.scss'],
})
export class GrainComponent implements OnInit, AfterViewInit {
  @ViewChild('grainitem', { static: false }) accordion;
  grainForm: FormGroup;
  submitted = false;
  isSaveDisabled = false;
  grainList: IGrain[] = [];
  copyGrainList: IGrain[] = [];
  file: File;
  grainId = 0;
  imagePreview: string;
  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;
  isImageValid = false;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private fb: FormBuilder,
    private grainService: GrainService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private changeDetectorRef: ChangeDetectorRef

  ) {
    this.grainForm = this.fb.group({
      english: ['', [Validators.required]],
      hindi: ['', [Validators.required]],
      marathi: ['', [Validators.required]],
      minglish: ['', [Validators.required]],
      hinglish: ['', [Validators.required]],
      includeRecipe: [false]
    });
  }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Grain');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }

    this.getGrainList();
  }
  clearForm() {
    this.grainForm.reset();
    this.fileInfo = '';
    this.grainId = 0;
    this.isImageValid=false;
  }
  ngAfterViewInit() {

    this.changeDetectorRef.detectChanges();
  }

  get f() {
    return this.grainForm.controls;
  }

  openfile() {
    document.getElementById('myfile').click();
  }
  fileInfo: string;
  onFileSelect(input: HTMLInputElement): void {
     this.isImageValid = false
    const reader = new FileReader();
    reader.readAsDataURL(input.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        console.log('Width and Height size ', width, height, input.files[0].size);
      //check file is less than 100 kb & height & width
        if (height < 350 && width < 510 && input.files[0].size < 200000 ){
          this.file = input.files[0];
          this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
          this.isImageValid = false
        }
        else{
          this.isImageValid = true;
          //alert('The file is to large. Allowed maximum size of 100 KB');
        }
      };
    };
  
    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;
      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }
      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
  }

  async getGrainList(): Promise<void> {
    const url = `${environment.API_URL}/api/Grain/GetGrainList`;
    this.grainService.getGrainList(url).subscribe(
      (response: ResponseModel<IGrain[]>) => {
        if (response.status === HttpStatusCode.OK) {

          if (this.grainList.length > 0) {
            this.grainList.forEach(ele => {
              ele.imagePath = ele.imagePath + '_1';
            });

            setTimeout(() => {
            }, 2000);
          }

          this.grainList = response.data;
          this.copyGrainList = response.data;
          this.config.totalItems = this.grainList.length;
        }
      });
  }

  uploadFile(event) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.uploadFile(this.file);

      this.imagePreview = (fileReader.result).toString();
    },
      fileReader.readAsDataURL(this.file);

      if (event.target.files && event.target.files.length) {
        for (const file of event.target.files) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
              const height = img.naturalHeight;
              const width = img.naturalWidth;
              console.log('Width and Height', width, height);
            };
          };
        }
      }
  }

  openGrainDeleteDialog(grain: IGrain) {
    this.accordion.close();
    this.dialogService.open(GrainDeleteDialogComponent, {
      context: {
        title: '',
        data: grain,
      },
    }).onClose.subscribe(() => {
      this.getGrainList();
    });
  }


  addGrain() {
    this.submitted = true;
    const formData = new FormData();
    formData.set('Id', this.grainId.toString());
    formData.set('EnglishName', this.grainForm.value.english);
    formData.set('MarathiName', this.grainForm.value.marathi);
    formData.set('HindiName', this.grainForm.value.hindi);
    formData.set('name_Manglish', this.grainForm.value.minglish);
    formData.set('name_Hinglish', this.grainForm.value.hinglish);
    formData.set('File', this.file);
    formData.set('ImagePath', this.imagePreview);
    formData.set('IsActive', 'true');
    formData.set('IsShowforRecipe', this.grainForm.value.includeRecipe);

    if (this.grainForm.valid) {
      this.isSaveDisabled = true;
      let apiurl = '';
      if (this.grainId === 0) {
        apiurl = `${environment.API_URL}/api/Grain/AddGrain`;
      } else {
        apiurl = `${environment.API_URL}/api/Grain/UpdateGrain`;
      }

      this.grainService.modifyGrain(apiurl, formData).subscribe(
        (response: ResponseModel<IGrain>) => {
          if (response.status === HttpStatusCode.OK) {
            this.submitted = false;
            if (this.grainId === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');

            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');

            }
            this.isSaveDisabled = false;
            this.grainId = 0;
            this.fileInfo = '';
            this.file = null;
            this.accordion.close();
            this.grainForm.reset();
            this.getGrainList();



          } else {
            if (response.status === 400) {
              this.showToast('top-right', 'danger', response.statusMessage);
            }

          }
        });
    }
  }
  editGrain(grain: IGrain) {
    this.accordion.open();
    // tslint:disable-next-line:ban
    const win = $(window);
    win.scrollTop(0);
    this.grainId = grain.id;
    this.grainForm.controls.english.setValue(grain.englishName);
    this.grainForm.controls.marathi.setValue(grain.marathiName);
    this.grainForm.controls.hindi.setValue(grain.hindiName);
    this.grainForm.controls.minglish.setValue(grain.name_Manglish);
    this.grainForm.controls.hinglish.setValue(grain.name_Hinglish);
    this.grainForm.controls.includeRecipe.setValue(grain.isShowforRecipe);
    this.imagePreview = grain.imagePath;
    // this.fileInfo = '';
    // this.file = null;

    if (grain.imagePath != null || grain.imagePath !== undefined || grain.imagePath !== '') {

      const imagePathSTR = grain.imagePath;

      const arraystr = imagePathSTR.split('/');

      this.fileInfo = arraystr[arraystr.length - 1];
    }


  }

  toggle() {
    this.accordion.toggle();
    this.submitted = false;
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }


  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.grainList = this.copyGrainList.filter(item => {
        const name = String(item.englishName).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr));
      });
    } else {
      this.grainList = this.copyGrainList;
    }
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}
