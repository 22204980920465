import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { EmployeeService } from '../../../Shared/Services/employee.service';

@Component({
  selector: 'ngx-rateing-dialog',
  templateUrl: './rateing-dialog.component.html',
  styleUrls: ['./rateing-dialog.component.scss']
})
export class RateingDialogComponent implements OnInit {
  title: string;
  data: any;
  resolutionRemark = '';
  id = 0;
  adminReply = '';
  isShowErrorMessage = false;
  constructor( 
    private dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService,
    private employeeService: EmployeeService,  
   ) { }

  ngOnInit() {
    
  }
  SaveReply() {
    if (this.adminReply !== '') {
      const data = {
        id: this.data.id,
        adminReply: this.adminReply,
      };
      let apiurl = '';
      apiurl = `${environment.API_URL}/api/RatingReview/AddOrderRatingReply`;
      this.employeeService.modifyEmployee(apiurl, data).subscribe
        ((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.close();
            this.showToast('top-right', 'success', ' Reply Successfully');
            this.isShowErrorMessage = false;
          } else {
            this.close();
            this.showToast('top-right', 'success', 'Reply Failed! ');

            this.isShowErrorMessage = false;
          }
        });
      // this.disableSaveBtnForActive = false;
    } else {
      this.isShowErrorMessage = true;
    }
  }
  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

  close() {
    this.dialogRef.close();
  }
}
