import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { SeniercitienComponent } from './seniercitien/seniercitien.component';
import { ViewDetilasComponent } from './view-detilas/view-detilas.component';

@Component({
  selector: 'ngx-fk-user',
  templateUrl: './fk-user.component.html',
  styleUrls: ['./fk-user.component.scss']
})
export class FkUserComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  userList: any[];

  searchText = '';
  isActive = true;
  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;
  showEmptyDiv = false;

  tabIndex = 1;


  user = 0;
  userId = 0;
  activeDeactiveBtn = false;

  pageSize = 10;
  pageNumber = 1;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };
  configForDeactive = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };


  constructor(
    private toastrService: NbToastrService,
    private gpService: GpartnerService,
    private dialogService: NbDialogService

  ) { }

  ngOnInit() {
    this.getFkUser(false);
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'FlourPicker User');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
    this.getFkUser(false);
  }
  pageChangeForDeactive(pageNo) {
    this.configForDeactive.currentPage = pageNo;
    this.getFkUser(false);
  }


  changeTag(event) {
    if (event.tabId === 'activeuser') {
      this.isActive = true;
      this.tabIndex = 1;
      this.userList = [];
      this.configForDeactive.currentPage = 1;


    } else if (event.tabId === 'deactiveuser') {
      this.tabIndex = 2;
      this.isActive = false;
      this.userList = [];
      this.config.currentPage = 1;

    }

    this.getFkUser(false);
  }


  openDeleteDialog(user: any) {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: '',
        data: user,
      },
    }).onClose.subscribe(() => {
      this.getFkUser(false);
    });
  }
  openViewDetila(user: any) {
    this.dialogService.open(ViewDetilasComponent, {
      context: {
        title: '',
        data: user,
      },
    });
  }

  openSenerCitison(user: any) {
    this.dialogService.open(SeniercitienComponent, {
      context: {
        title: '',
        data: user,
      },
    }).onClose.subscribe(() => {
      // alert('close');
      this.getFkUser(false);
    });
  }


  // To active user
  activeUser(userId) {
    this.activeDeactiveBtn = true;
    const url = `${environment.API_URL}/api/User/ActiveUser?UserId=${userId}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.getFkUser(false);
          this.activeDeactiveBtn = false;
          this.showToast('top-right', 'success', 'User Activeted Successfully');
        }
      });
  }


  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }

  // for active user
  getFkUser(isSearch: boolean) {
    if (isSearch === true) {
      this.config.currentPage = 1;
    }
    let str = this.searchText.trim();
    const splitTxt = str.split(' ');
    let newTxt = '';
    if (splitTxt.length > 1) {
      splitTxt.forEach(txt => {
        if (txt) {
          newTxt = newTxt + txt + ' ';
        }
      });
      str = newTxt;
    }
    str = str.trim();
    const url = `${environment.API_URL}/api/User/GetUserListSearch?SearchStr=${str}&isActive=${this.isActive}&Pageno=${this.config.currentPage}&PageSize=${this.pageSize}`;
    this.gpService.getGPartnerList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.userList = response.data.users;
          this.config.totalItems = response.data.count;
        } else {
          this.userList = [];
        }
      });

  }

}
