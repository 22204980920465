import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { event } from 'jquery';
import { environment } from '../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { ReadymadeService } from '../../Shared/Services/readymade.service';
import { ReadymadeStockDeleteDialogComponent } from './readymade-stock-delete-dialog/readymade-stock-delete-dialog.component';

@Component({
  selector: 'ngx-readymade-stockin',
  templateUrl: './readymade-stockin.component.html',
  styleUrls: ['./readymade-stockin.component.scss']
})
export class ReadymadeStockinComponent implements OnInit {
  readyMadeStockInForm: FormGroup;
  stockInFormArray: FormArray = this.fb.array([]);
  submitted = false;
  stockId = 0;
  warehouseList = [];
  areastoreList = [];
  vendorList = [];
  readyMadeProductList = [];
  stockList = [];
  copyStockList = [];
  file: any;
  imgPreview = '';
  fileInfo = '';
  isSaveDisabled = false;
  selectedWHId = 0;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  pageRole: any;
  saveBtnHideForRole = true;


  @ViewChild('rstockinitem', { static: false }) accordion;

  constructor(
    private fb: FormBuilder,
    private readyMadeStockService: ReadymadeService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService) {
    this.readyMadeStockInForm = this.fb.group({
      warehouse: ['', [Validators.required]],
      areastore: ['', [Validators.required]],
      vendor: ['', [Validators.required]],
      date: ['', [Validators.required]],
      amount: [0, [Validators.required]],
      bill: ['', [Validators.required]],
      discription: [''],

    });
  }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'readymatestockin');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }
    this.addStockInForm();
    this.getWarehouseList();
    this.getVendorList();
    this.getReadymadeProductList();
    this.getReadymadeStockList();
  }

  get f() {
    return this.readyMadeStockInForm.controls;
  }

  onWHChange(event) {
    this.getAreaStoreList(event.target.value)
  }
  getWarehouseList() {
    const url = `${environment.API_URL}/api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
    this.readyMadeStockService.getStockList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.warehouseList = response.data.warehouses;
        } else {
          this.warehouseList = [];
        }
      }
    );
  }

  // Get Area-Store list
  getAreaStoreList(WhId) {
    const url = `${environment.API_URL}/api/ReadymadeCupboard/GetCupboardByWarehouseIdList?warehoseId=${WhId}`;
    this.readyMadeStockService.getStockList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areastoreList = response.data.cupboard;
        } else {
          this.areastoreList = [];
        }
      }
    );
  }
  getReadymadeProductList() {
    const url = `${environment.API_URL}/api/Product/readymadeproduct?PageSize=0&PageNumber=0`;
    this.readyMadeStockService.getStockList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.readyMadeProductList = response.data;
        } else {
          this.readyMadeProductList = [];
        }
      }
    );
  }

  getVendorList() {
    const url = `${environment.API_URL}/api/Vendor/GetVendorList`;
    this.readyMadeStockService
      .getStockList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.vendorList = response.data;
        } else {
          this.vendorList = [];
        }
      });
  }

  getReadymadeStockList() {
    const url = `${environment.API_URL}/api/ReadyMateStock/GetReadyMateStock`;
    this.readyMadeStockService.getStockList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.stockList = response.data;
          this.copyStockList = response.data;
          this.config.totalItems = this.stockList.length;
        } else {
          this.stockList = [];
        }
      }
    );
  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.stockList = this.copyStockList.filter(item => {
        // return String(item.wareHouseName)
        //   .toLowerCase()
        //   .startsWith(str.toLowerCase().trim());
        const wName = String(item.wareHouseName).toLocaleLowerCase();
				const rstockInChallanNo = String(item.rStockInChallanNo).toLocaleLowerCase();
        const vendor = String(item.vendor).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
				return (wName.includes(searchStr) || (rstockInChallanNo.includes(searchStr) || (vendor.includes(searchStr) )));	
      });
    } else {
      this.stockList = this.copyStockList;
    }
     this.config.totalItems = this.stockList.length;
  }

  addStockInForm() {
    this.stockInFormArray.push(
      this.fb.group({
        productId: ['', [Validators.required]],
        size: [1],
        qty: ['', [Validators.required]],
        perPrice: ['', [Validators.required]],
        total: ['', [Validators.required]]
      })
    );
  }

  removeItem(index) {
    this.stockInFormArray.controls.splice(index, 1);

    let amount = 0;
    this.stockInFormArray.controls.forEach(form => {
      amount += form.value.total;
    });

    this.readyMadeStockInForm.controls.amount.setValue(amount);
  }

  getDate(dateinfo) {
    const day = dateinfo.getDate().toString();
    const year = dateinfo.getFullYear().toString();
    const month = (dateinfo.getMonth() + 1).toString();
    const dateStr = year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
    return dateStr;
  }

  onFileSelect(input: HTMLInputElement): void {
    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;
      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }
      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
    this.file = input.files[0];
    this.uploadFile(this.file);
    this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
  }

  uploadFile(file) {
    const files = file;
    const fileReader = new FileReader();
    (fileReader.onload = () => {
      this.imgPreview = fileReader.result.toString();
    }),
      fileReader.readAsDataURL(files);
  }

  saveStock() {
    this.submitted = true;
    if (this.readyMadeStockInForm.valid) {
      this.isSaveDisabled = true;
      const data = {
        id: this.stockId,
        date: this.getDate(this.readyMadeStockInForm.value.date),
        warehouseId: +this.readyMadeStockInForm.value.warehouse,
        cupboardId: +this.readyMadeStockInForm.value.areastore,
        vendorId: +this.readyMadeStockInForm.value.vendor,
        totalAmount: +this.readyMadeStockInForm.value.amount,
        bill: this.readyMadeStockInForm.value.bill,
        discription: this.readyMadeStockInForm.value.discription,
        billpath: this.imgPreview !== '' ? this.imgPreview.split(',')[1] : '',
        readymaterstockdetails: this.getStockItemList(this.stockInFormArray)
      };
      let apiurl = '';
      if (this.stockId === 0) {
        apiurl = `${environment.API_URL}/api/ReadyMateStock/AddReadyMateStock`;
      } else {
        apiurl = `${environment.API_URL}/api/ReadyMateStock/UpdateReadyMateStock`;
      }
      this.readyMadeStockService
        .modifyStock(apiurl, data)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.stockId === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
              this.isSaveDisabled = false;

            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
              this.isSaveDisabled = false;

            }

            this.stockId = 0;
            this.stockInFormArray = this.fb.array([]);
            this.submitted = false;
            this.readyMadeStockInForm.reset();
            this.addStockInForm();
            this.getReadymadeStockList();
            this.accordion.close();
          } else if (response.status === 400) {
            this.isSaveDisabled = false;
            this.showToast('top-right', 'danger', 'please check mandatory feild ');

          }
        });

    }
  }


  editStockIn(stock) {
    this.accordion.open();
    this.isSaveDisabled = false;
    this.stockId = stock.id;
    this.readyMadeStockInForm.controls.warehouse.setValue(stock.wareHouseId);
    this.getAreaStoreList(stock.wareHouseId);
    this.readyMadeStockInForm.controls.vendor.setValue(stock.vendorId);
    this.readyMadeStockInForm.controls.date.setValue(new Date(stock.date));
    this.readyMadeStockInForm.controls.amount.setValue(stock.totalAmount);
    this.readyMadeStockInForm.controls.bill.setValue(stock.bill);
    this.readyMadeStockInForm.controls.discription.setValue(stock.discription);
    this.readyMadeStockInForm.controls.areastore.setValue(stock.cupboardId);
    while (this.stockInFormArray.length !== 0) {
      this.stockInFormArray.removeAt(0);
    }

    (stock.readymaterstockdetails as Array<any>).forEach(element => {
      const itemForm = this.fb.group({
        productId: [element.productId, [Validators.required]],
        size: [element.size, [Validators.required]],
        qty: [element.quantity, [Validators.required]],
        perPrice: [element.amount, [Validators.required]],
        total: [element.size * element.quantity * element.amount, [Validators.required]]
      });
      this.stockInFormArray.push(itemForm);
    });
  }

  openDeleteDialog(stock) {
    this.dialogService
      .open(ReadymadeStockDeleteDialogComponent, {
        context: {
          title: '',
          data: stock
        }
      })
      .onClose.subscribe(() => {
        this.getReadymadeProductList();
      });
  }

  getStockItemList(itemFormArray: FormArray) {
    const temItemArray = [];
    itemFormArray.controls.forEach((form: FormGroup) => {
      if (form.valid) {
        const obj = {
          productId: +form.value.productId,
          productName: '',
          size: 1,
          amount: +form.value.perPrice,
          quantity: +form.value.qty
        };
        temItemArray.push(obj);
      }
    });
    return temItemArray;
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }


  getTotal(itemForm: FormGroup) {

    const price = (itemForm.value.perPrice) ? (itemForm.value.perPrice) : 0;
    const total = +price * ((itemForm.value.qty) ? (itemForm.value.qty) : 0) * itemForm.value.size;
    itemForm.controls.total.setValue(total);

    let amount = 0;
    this.stockInFormArray.controls.forEach(form => {
      amount += form.value.total;
    });
    this.readyMadeStockInForm.controls.amount.setValue(amount);
  }

  clearForm() {
    this.readyMadeStockInForm.reset();
    this.submitted = false;
    this.isSaveDisabled = false;
    this.stockId = 0;
    this.fileInfo = '';
    this.imgPreview = '';
    this.stockInFormArray = this.fb.array([]);
    this.addStockInForm();
  }


  showToast(position, status, message) {
    this.toastrService.show(status || 'Success', `${message}`, {
      position,
      status
    });
  }

}
